import { FC, useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useUser } from "../../../store/useUser";
import { useSetState } from "@mantine/hooks";
import moment from "moment";
import { apiUrl } from "../../../constant/routs";
import axios from "axios";
import { Alert, Box, Button, Center, Flex, Group, Input, Select, Stack, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

interface Props {
    setCalcId: (val: number) => void
}

interface ValuesRight {
    n: number,
    rMin: number,
    gamma: number | string
}

const SampleSizeCalculationMin: FC<Props> = ({
    setCalcId
}) => {
    const [user, token] = useUser(state => [state.user, state.token])
    const [stateResp, setStateResp] = useState<number | null>(null)
    const [stateValRight, setStateValRight] = useSetState<ValuesRight>({
        n: 1000000,
        gamma: "95.0",
        rMin: 3
    });
    const nav = useNavigate()


    const checkingValues = useCallback(() => {
        if (stateValRight.rMin < 0.1) return false;
        if (stateValRight.n <= 0 || stateValRight.n > 1000000000) return false;
        return true;
    }, [stateValRight])

    const createOptions = () => {
        const options = [];
        for (let i = 99.5; i > 0; i -= 0.5) {
            options.push({ value: Number.isInteger(i) ? `${i}.0` : `${i}`, label: Number.isInteger(i) ? `${i},0%` : `${i}%`.replace('.', ',') })
        }
        return options
    }

    useEffect(() => {
        onSubmitData();
    }, [stateValRight.rMin, stateValRight.gamma, stateValRight.n, user])

    if (user?.tariff?.reaserchCalc === 0) {
        return (
            <Center mt={20} mb={isMobile? 120: 20} w="100%" py={40} px={40} bg="#dfe2eb" sx={{
                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                flexDirection: "column",
                borderRadius: 10,
                "span": {
                    fontStyle: "normal"
                }
            }}>
                <Text fw="bold" fz={18} mb={20}>
                   Исследовательские калькуляторы не доступны в вашем тарифе
                </Text>
                <Button color="teal" size="md" radius="xs" onClick={() => nav("/tariff")}>
                    Сменить тариф
                </Button>
            </Center>
        )
    } 

    if (!isMobile) {
        return (
            <Box mt={0} mb={20} w="100%" py={30} px={45} bg="#dfe2eb" sx={{
                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
            }}>
                <Flex mb={20} justify="space-between">
                    <Box fz={18} lh={1.17} fw="bold">
                        Объем выборки <i>n</i> в зависимости от <span>R<sub>min</sub>,</span> <span>γ</span> и <span>N</span>
                    </Box>
                    <Button color="teal" onClick={() => setCalcId(7)} compact styles={{
                        label: {
                            fontStyle: "normal !important"
                        }
                    }}>
                        Помощь
                    </Button>
                </Flex>
                <Box mb={25} sx={{
                    "i": {
                        fontStyle: "normal",
                        fontWeight: "bold"
                    }
                }}>
                    <Text c="teal" fw={600} fz={17} mb={15}>
                        Введите параметры:
                    </Text>
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Минимально значимая доля признака <i>R<sub>min</sub>, %</i>
                        </Box>
                        <Input type="number" rightSection={"%"} w={110} defaultValue={stateValRight.rMin} onChange={e => setStateValRight({ rMin: Number(e.target.value) })} />
                    </Flex>
                    {(stateValRight.rMin <= 0 || stateValRight.rMin > 100) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            R<sub>min</sub> не модет быть больше 100% или меньше чем 0,1%
                        </Alert>
                    )}
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Уровень надежности измерений <strong>γ, </strong> <i>%</i>
                        </Box>
                        <Select w={110} defaultValue="95.0" searchable data={createOptions()} onChange={e => setStateValRight({ gamma: String(e) })} min={0.1} max={100} />
                    </Flex>
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Объем генеральной совокупности <i>N</i>
                        </Box>
                        <Input type="number" w={110} defaultValue={stateValRight.n} onChange={e => setStateValRight({ n: Number(e.target.value) })} />
                    </Flex>
                    {(stateValRight.n <= 0 || stateValRight.n > 1000000000) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Объем генеральной совокупности <i>N</i> не может быть меньше 0 и больше 1000000000
                        </Alert>
                    )}
                </Box>
                {stateResp && (
                    <Group spacing="xl">
                        <Text fw="bold" size={26}>Результат: </Text>
                        <Text size={26} fw="normal">
                            <i>n</i> = {String(stateResp.toFixed(0))}
                        </Text>
                    </Group>
                )}
            </Box>
        )
    } else {
        return (
            <Box mt={0} w="100%" pt={15} pb={120} px={15} bg="#dfe2eb" sx={{
                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                borderRadius: 10
            }}>
                <Flex mb={20}>
                    <Box fz={18} lh={1.17} fw="bold">
                        Объем выборки <i>n</i> в зависимости от <span>R<sub>min</sub>,</span> <span>γ</span> и <span>N</span>
                    </Box>
                </Flex>
                <Box mb={20} sx={{
                    "i": {
                        fontStyle: "normal",
                        fontWeight: "bold"
                    }
                }}>
                    <Text c="teal" fw={600} fz={17} mb={15}>
                        Введите параметры:
                    </Text>
                    <Flex direction="column" justify="space-between" bg="#f5f6fa" py={7} px={15} mb={5}>
                        <Box fz={16}>
                            Минимально значимая доля признака <i>R<sub>min</sub>, %</i>
                        </Box>
                        <Input type="number" rightSection={"%"} w="100%" defaultValue={stateValRight.rMin} onChange={e => setStateValRight({ rMin: Number(e.target.value) })} />
                    </Flex>
                    {(stateValRight.rMin <= 0 || stateValRight.rMin > 100) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            R<sub>min</sub> не модет быть больше 100% или меньше чем 0,1%
                        </Alert>
                    )}
                    <Flex direction="column" justify="space-between" bg="#f5f6fa" py={7} px={15} mb={5}>
                        <Box fz={16}>
                            Уровень надежности измерений <strong>γ, </strong> <i>%</i>
                        </Box>
                        <Select w="100%" defaultValue="95.0" searchable data={createOptions()} onChange={e => setStateValRight({ gamma: String(e) })} min={0.1} max={100} />
                    </Flex>
                    <Flex direction="column" justify="space-between" bg="#f5f6fa" py={7} px={15} mb={5}>
                        <Box fz={16}>
                            Объем генеральной совокупности <i>N</i>
                        </Box>
                        <Input type="number" w="100%" defaultValue={stateValRight.n} onChange={e => setStateValRight({ n: Number(e.target.value) })} />
                    </Flex>
                    {(stateValRight.n <= 0 || stateValRight.n > 1000000000) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Объем генеральной совокупности <i>N</i> не может быть меньше 0 и больше 1000000000
                        </Alert>
                    )}
                </Box>
                {stateResp && (
                    <Stack spacing={0} mb={20}>
                        <Text fw="bold" size={26}>Результат: </Text>
                        <Text size={26} fw="normal">
                            <i>n</i> = {String(stateResp.toFixed(0))}
                        </Text>
                    </Stack>
                )}
                <Center>
                    <Button color="teal" onClick={() => setCalcId(7)} styles={{
                        label: {
                            fontStyle: "normal !important"
                        }
                    }}>
                        Помощь
                    </Button>
                </Center>
            </Box>
        )
    }

    async function onSubmitData() {
        if (token && moment(user?.subscribe_to).unix() >= moment().unix() && checkingValues() && user?.tariff?.reaserchCalc === 1) {
            try {
                const { data } = await axios.post<number>(`${apiUrl}api/samplesizeCalculation`, {
                    n: stateValRight.n,
                    gamma: stateValRight.gamma,
                    rMin: stateValRight.rMin / 100,
                }, token ? {
                    headers: { "Authorization": `Bearer ${token}` }
                } : undefined)
                setStateResp(data)
            } catch (error) {
                setStateResp(null)
            }
        }
    }
}

export default SampleSizeCalculationMin