import { Link } from "@fluentui/react";
import axios from "axios";
import { FC, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ErrorAlert } from "../../@library/interaction";
import { useToken } from "../../@store/useAuth";
import { useUser } from "../../@store/useUser";
import { AuthModal } from "../base/authModar";
import { RegModal } from "../base/regModal";
import Footer from "./footer";
import Header from "./header";

const Wrapper = styled.section`
    background: #faf9f8;
    position: relative;
    z-index: 1;
    top: 55px;
    overflow-x: hidden;
`

const LayoutDefault: FC<{
    children: React.ReactNode
}> = ({ children }) => {
    const navigate = useNavigate()
    const [logo, setLogo] = useState<string>('')
    const [token, setToken] = useToken(state => [state.token, state.setToken])
    const [user, setUser] = useUser(state => [state.user, state.setUser])

    const onGetData = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: "/api/home/init",
            });

            setLogo(response.data.logo);

        } catch (error: any) {
            const { response } = error;
            try {
                console.log(response.data, 'error');

            } catch (error: any) {
                ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
            }
        }
    }

    const onGetUser = async () => {
        try {
            const response = await axios({
                method: "POST",
                headers: { 'Authorization': `Bearer ${token}` },
                url: "/api/personal/get",
            });

            setUser(response.data);            

        } catch (error: any) {
            const { response } = error;
            try {
                console.log(response.data, 'error');

            } catch (error: any) {
                ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
            }
        }
    }


    useLayoutEffect(() => {
        onGetUser()
        onGetData()
    }, [])


    return (
        <div>
            <Header logo={logo}>
                <Link
                    onClick={() => navigate('/')}>
                    Главная
                </Link>
                <Link
                    onClick={() => navigate('/pdf')}>
                    О методах точного мышления
                </Link>
                <Link
                    onClick={() => navigate('/calc')}>
                    Калькуляторы
                </Link>
                <Link
                    onClick={() => navigate('/articles-and-books')}>
                    Книги и статьи
                </Link>
                <Link
                    onClick={() => navigate('/news')}>
                    Новости
                </Link>
                {/* <Link
                    onClick={() => navigate('/partneer')}>
                    Партнеры
                </Link> */}
            </Header>
            <Wrapper>
                {children}
                <Footer />
            </Wrapper>
            <AuthModal/>
            <RegModal/>
        </div>
    )
}

export default LayoutDefault