import { FC, useState } from "react";
import { DataTariff } from ".";
import Layout from "../../components/layout";
import HeaderMobile from "../../components/layout/headerMobile";
import BunnerMobile from "../../components/mobile/bunnerMobile";
import { Button, Container, Stack, Text } from "@mantine/core";
import TariffItem from "./components/tariffItem";

const TariffMobile: FC<{
    data: DataTariff[]
}> = ({
    data
}) => {
        const [isIndividual, setIndividual] = useState<boolean>(true)

        return (
            <Layout>
                <HeaderMobile />
                <BunnerMobile banner="public/files//settings/banner_lk_1.png" />
                <Container pos="relative">
                    <Text component="h1" fz={20} fw="bold" mb={10} mt={30} tt="uppercase" ta="center">
                        Тарифы
                    </Text>
                    <Stack justify="center" w="100%" mb={20} sx={{
                        flexDirection: "row"
                    }}>
                        <Button variant={isIndividual ? "filled" : "outline"} radius={25} h={50} color="teal" styles={{
                            label: {
                                textTransform: "uppercase",
                                fontWeight: 500,
                                fontSize: 14
                            }
                        }} onClick={() => {
                            if (!isIndividual) setIndividual(!isIndividual)
                        }}>
                            Индивидуальные
                        </Button>
                        {/* <Button variant={!isIndividual ? "filled" : "outline"} color="teal" radius={25} h={50} disabled styles={{
                            label: {
                                textTransform: "uppercase",
                                fontWeight: 500,
                                fontSize: 14
                            }
                        }} onClick={() => {
                            setIndividual(!isIndividual)
                        }}>
                            Групповые
                        </Button> */}
                    </Stack>
                    <Stack mx={10} mb={50}>
                        {data.map((item, idx) => {
                            return <TariffItem key={idx} item={item} />
                        })}
                    </Stack>
                </Container>
            </Layout>
        )
    }

export default TariffMobile