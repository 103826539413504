import { Menu } from "./types"
import { create } from 'zustand'

interface MainStore {
    logo: string
    logo_green: string
    menu: Menu[],
    legal: string,
    contacts: string
    company_details: string
    loading: boolean
}

export const useMain = create<MainStore>(() => ({
    logo: "",
    logo_green: "",
    menu: [],
    legal: "",
    contacts: "",
    company_details: "",
    loading: false
}))

