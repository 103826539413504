import { Box } from "@mantine/core"
import { FC } from "react"
import { apiUrl } from "../../constant/routs"
import HeaderDesctop from "../layout/hearer"

interface Props {
    banner: string
}

const BunnerDeskt: FC<Props> = ({
    banner
}) => {
    return (
        <Box pos="relative" mb={215}>
            <Box h={300} w="100%" pos="absolute" sx={{
                backgroundColor: "#000",
                zIndex: 0
            }}>
                <Box h="100%" w="100%" display="block" pos="absolute" style={{
                    opacity: 0.8,
                    zIndex: 0,
                    top: 0,
                    right: 0,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundImage: `url(${apiUrl}${banner})`,
                }} />
            </Box>
            <Box pos="relative" style={{
                zIndex: 1
            }}>
                <HeaderDesctop />
            </Box>
        </Box>
    )
}

export default BunnerDeskt