import axios from "axios";
import { FC, useEffect, useState } from "react";
import { apiUrl } from "../../constant/routs";
import { useUser } from "../../store/useUser";
import { ITask } from "../../store/types";
import { isMobile } from "react-device-detect";
import CalcHomeDesk from "./desktop";
import CalcHomeMobile from "./mobile";

export interface CalcData {
    banner: string,
    subscribe: boolean,
    description: string,
    calcs: ITask[]
}

const Calc: FC = () => {
    const [data, setData] = useState<CalcData>({
        banner: "",
        subscribe: false,
        description: "",
        calcs: []
    })
    const token = useUser(state => state.token)
    useEffect(() => {
        getData()
    }, [])
    if (!isMobile) {
        return <CalcHomeDesk banner={data.banner} parent={{
            name: "О калькуляторах",
            url: "/calc"
        }} calcs={data.calcs} url={""} description={data.description} />
    }
    return <CalcHomeMobile banner={data.banner} calcs={data.calcs} url={""} description={data.description} name={""} />

    async function getData() {
        try {
            const responseAll = await axios.get(`${apiUrl}api/description/summary?v=${Math.random()}`, token ? {
                headers: { "Authorization": `Bearer ${token}` }
            } : undefined)
            const { data } = await axios.get(`${apiUrl}api/home/tasks/calc?v=${Math.random()}`)
            setData({
                banner: data.banner,
                description: responseAll.data.text.description,
                calcs: data.tasks,
                subscribe: responseAll.data.subscribe
            })
        } catch (error) {

        }
    }
}

export default Calc