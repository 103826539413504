import { useSetState } from "@mantine/hooks";
import moment from "moment";
import { FC, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { apiUrl } from "../../../constant/routs";
import { useUser } from "../../../store/useUser";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { Alert, Box, Button, Center, Flex, Group, Input, Stack, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

interface Props {
    setCalcId: (val: number) => void
}

interface Values {
    coverageOfMediaItems: number[],
    mediaGroupReach: number
}

const MultipleMediaFudience: FC<Props> = ({ setCalcId }) => {
    const [countOfMedia, setCountOfMedia] = useState<number>(1)
    const [stateResp, setStateResp] = useState<number | null>(null)
    const [user, token] = useUser(state => [state.user, state.token])
    const [coverageOfMedia, setCoverageOfMedia] = useState<number[]>([])
    const [stateVal, setStateVal] = useSetState<Values>({
        coverageOfMediaItems: [10],
        mediaGroupReach: 90
    });
    const nav = useNavigate()

    const checkingValues = useCallback(() => {
        if (stateVal.mediaGroupReach <= 0 || stateVal.mediaGroupReach > 100) return false;
        if (countOfMedia <= 0 || countOfMedia > 20) return false;
        for (const item of stateVal.coverageOfMediaItems) {
            if (item > stateVal.mediaGroupReach) return false;
        }
        return true;
    }, [stateVal])

    useEffect(() => {
        onSubmitData();
    }, [stateVal.coverageOfMediaItems, stateVal.mediaGroupReach, countOfMedia, user])

    useLayoutEffect(() => {
        let column = [];
        let items = stateVal.coverageOfMediaItems;
        for (let i = 1; i <= countOfMedia; i++) {
            column.push(i);
            items.push(0)
        }

        setCoverageOfMedia(column);
        setStateVal({ coverageOfMediaItems: items });

    }, [countOfMedia])

    if (user?.tariff?.mediaClac === 0) {
        return (
            <Center mt={20} mb={isMobile ? 120 : 20} w="100%" py={40} px={40} bg="#dfe2eb" sx={{
                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                flexDirection: "column",
                borderRadius: 10,
                "span": {
                    fontStyle: "normal"
                }
            }}>
                <Text fw="bold" fz={18} mb={20}>
                    Медиа калькуляторы не доступны в вашем тарифе
                </Text>
                <Button color="teal" size="md" radius="xs" onClick={() => nav("/tariff")}>
                    Сменить тариф
                </Button>
            </Center>
        )
    }

    if (!isMobile) {
        return (
            <Box mt={0} mb={20} w="100%" py={30} px={45} bg="#dfe2eb" sx={{
                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
            }}>
                <Flex mb={20}>
                    <Box fz={18} lh={1.17} fw="bold" >
                        Вычисление охвата аудитории группы медиа <span>G</span> {" "}в зависимости от охватов медианосителей <span>G<sub>j</sub>,</span> <span>L,</span> <span>G<sup>∞</sup></span>
                    </Box>
                    <Button color="teal" onClick={() => setCalcId(2)} compact styles={{
                        label: {
                            fontStyle: "normal !important"
                        }
                    }}>
                        Помощь
                    </Button>
                </Flex>
                <Box mb={25} sx={{
                    "i": {
                        fontStyle: "normal",
                        fontWeight: "bold"
                    }
                }}>
                    <Text c="teal" fw={600} fz={17} mb={15}>
                        Введите параметры:
                    </Text>
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Предельный охват группы медиа <i>G<sup>∞</sup>, %</i>
                        </Box>
                        <Input type="number" rightSection={"%"} w={110} defaultValue={stateVal.mediaGroupReach} onChange={e => setStateVal({ mediaGroupReach: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.mediaGroupReach <= 0 || stateVal.mediaGroupReach > 100) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Предельный охват группы медиа не может быть больше 100% или меньше чем 0,1%
                        </Alert>
                    )}
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Количество медианосителей <i>L</i>
                        </Box>
                        <Input type="number" min={1} max={20} step={1} w={110} defaultValue={countOfMedia}
                            onChange={e => {
                                const value = +e.target.value;
                                let items = Array.from(stateVal.coverageOfMediaItems);
                                if (countOfMedia >= items.length && items.length < 20) {
                                    items.push(0);
                                }
                                items.push(0);
                                setStateVal({ coverageOfMediaItems: items.slice(0, value) });
                                setCountOfMedia(+value.toFixed(0));
                            }} />
                    </Flex>
                    {(countOfMedia <= 0 || countOfMedia > 20) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Количество медианосителей <i>L</i> не может быть больше 20 и меньше 0
                        </Alert>
                    )}
                    {countOfMedia && countOfMedia <= 20 ? (
                        <Box>
                            <Text c="dark" fw={600} fz={17} my={15}>
                                Охват медианосителей <strong><i>G<sub>j</sub>, %</i></strong>
                            </Text>
                            <Group spacing={2}>
                                {coverageOfMedia.map((column, idx) => {
                                    return (
                                        <Box key={idx} bg="#f5f6fa" sx={{
                                            width: "calc(10% - 2px)"
                                        }}>
                                            <Box fz={16} ta="center" p={3}>
                                                {column}
                                            </Box>
                                            <Box p={2}>
                                                <Input bg="#fff" variant="unstyled" type="number" w="100%" min={0} step={0.1} rightSection={"%"} defaultValue={stateVal.coverageOfMediaItems[idx]} onChange={e => {
                                                    const value = +e.target.value;
                                                    let items = Array.from(stateVal.coverageOfMediaItems);
                                                    items[idx] = +value.toFixed(1);
                                                    setStateVal({ coverageOfMediaItems: items })
                                                }} />
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Group>
                        </Box>
                    ) : <></>}
                </Box>
                {stateResp && checkingValues() ? (
                    <Group spacing="xl">
                        <Text fw="bold" size={26}>Результат: </Text>
                        <Text size={26} fw="normal">
                            <i>G</i> = {String(stateResp.toFixed(1))} %
                        </Text>
                    </Group>
                ) : <></>}
            </Box>
        )
    }
    return (
        <Box mt={0} w="100%" pt={15} pb={120} px={15} bg="#dfe2eb" sx={{
            boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
            borderRadius: 10
        }}>
            <Flex mb={20}>
                <Box fz={18} lh={1.17} fw="bold" >
                    Вычисление охвата аудитории группы медиа <span>G</span> {" "}в зависимости от охватов медианосителей <span>G<sub>j</sub>,</span> <span>L,</span> <span>G<sup>∞</sup></span>
                </Box>
            </Flex>
            <Box mb={20} sx={{
                "i": {
                    fontStyle: "normal",
                    fontWeight: "bold"
                }
            }}>
                <Text c="teal" fw={600} fz={17} mb={15}>
                    Введите параметры:
                </Text>
                <Flex direction="column" justify="space-between" bg="#f5f6fa" py={7} px={15} mb={5}>
                    <Box fz={16}>
                        Предельный охват группы медиа <i>G<sup>∞</sup>, %</i>
                    </Box>
                    <Input type="number" rightSection={"%"} w="100%" defaultValue={stateVal.mediaGroupReach}
                        onChange={e => setStateVal({ mediaGroupReach: Number(e.target.value) })} />
                </Flex>
                {(stateVal.mediaGroupReach <= 0 || stateVal.mediaGroupReach > 100) && (
                    <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                        Предельный охват группы медиа не может быть больше 100% или меньше чем 0,1%
                    </Alert>
                )}
                <Flex direction="column" justify="space-between" bg="#f5f6fa" py={7} px={15} mb={5}>
                    <Box fz={16}>
                        Количество медианосителей <i>L</i>
                    </Box>
                    <Input type="number" w="100%" defaultValue={countOfMedia}
                        onChange={e => {
                            const value = +e.target.value;
                            let items = Array.from(stateVal.coverageOfMediaItems);
                            if (countOfMedia >= items.length && items.length < 20) {
                                items.push(0);
                            }
                            items.push(0);
                            setStateVal({ coverageOfMediaItems: items.slice(0, value) });
                            setCountOfMedia(+value.toFixed(0));
                        }} />
                </Flex>
                {(countOfMedia <= 0 || countOfMedia > 20) && (
                    <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                        Количество медианосителей <i>L</i> не может быть больше 20 и меньше 0
                    </Alert>
                )}
                {countOfMedia && countOfMedia <= 20 ? (
                    <Box>
                        <Text c="dark" fw={600} fz={17} my={15}>
                            Охват медианосителей <strong><i>G<sub>j</sub>, %</i></strong>
                        </Text>
                        <Group spacing={2}>
                            {coverageOfMedia.map((column, idx) => {
                                return (
                                    <Box key={idx} bg="#f5f6fa" sx={{
                                        width: "32%"
                                    }}>
                                        <Box fz={16} ta="center" p={3}>
                                            {column}
                                        </Box>
                                        <Box p={2}>
                                            <Input bg="#fff" variant="unstyled" type="number" w="100%" min={0} step={0.1} rightSection={"%"} defaultValue={stateVal.coverageOfMediaItems[idx]} onChange={e => {
                                                const value = +e.target.value;
                                                let items = Array.from(stateVal.coverageOfMediaItems);
                                                items[idx] = +value.toFixed(1);
                                                setStateVal({ coverageOfMediaItems: items })
                                            }} />
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Group>
                    </Box>
                ): <></>}
            </Box>
            {stateResp && (
                <Stack spacing={0} mb={20}>
                    <Text fw="bold" size={26}>Результат: </Text>
                    <Text size={26} fw="normal">
                        <i>G</i> = {String(stateResp.toFixed(1))} %
                    </Text>
                </Stack>
            )}
            <Center>
                <Button color="teal" onClick={() => setCalcId(1)} styles={{
                    label: {
                        fontStyle: "normal !important"
                    }
                }}>
                    Помощь
                </Button>
            </Center>
        </Box>
    )

    async function onSubmitData() {
        if (token && moment(user?.subscribe_to).unix() >= moment().unix() && checkingValues() && user?.tariff?.mediaClac === 1) {
            try {
                const { data } = await axios.post<number>(`${apiUrl}api/multipleMediaAudience`, stateVal, token ? {
                    headers: { "Authorization": `Bearer ${token}` }
                } : undefined)
                setStateResp(data)
            } catch (error) {
                setStateResp(null)
            }
        }
    }
}

export default MultipleMediaFudience