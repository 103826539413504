import { PrimaryButton, Stack, TextField } from "@fluentui/react";
import axios from "axios";
import { FormikHelpers, useFormik } from "formik";
import { FC } from "react";
import {  } from "react-bootstrap";
import { ErrorAlert } from "../../@library/interaction";
import { TUser } from "../../@store/useUser";
import Card from "../base/card";

const EditUser: FC<{
    initialValues: TUser;
    token: string | null;
}> = ({ initialValues, token }) => {

    const formik = useFormik({
        initialValues,
        onSubmit: async (values: TUser, formikHelpers: FormikHelpers<TUser>) => {
            formikHelpers.setSubmitting(true);
            try {
                const response = await axios({
                    method: "POST",
                    url: "/api/personal/changes/data",
                    headers: { 'Authorization': `Bearer ${token}` },
                    data: values
                });

                localStorage.setItem('token', response.data.details.token);
                window.location.href = window.location.href;

            } catch (error: any) {
                const { response } = error;
                try {
                    ErrorAlert(response.data);
                } catch (error: any) {
                    ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
                }
            }

            formikHelpers.setSubmitting(false);
        }
    });
    return (
        <>
        <Card>
            <TextField label="Email" required disabled={formik.isSubmitting} value={formik.values.email} onChange={event => formik.setFieldValue("email", event.currentTarget.value, false)} />
            <TextField label="Имя" required disabled={formik.isSubmitting} value={formik.values.name} onChange={event => formik.setFieldValue("name", event.currentTarget.value, false)} />
            <TextField label="Фимилия" required disabled={formik.isSubmitting} value={formik.values.surname} onChange={event => formik.setFieldValue("surname", event.currentTarget.value, false)} />
            <TextField label="Номер телефона" required disabled={formik.isSubmitting} value={formik.values.phone} onChange={event => formik.setFieldValue("phone", event.currentTarget.value, false)} />
            <Stack horizontalAlign="space-between" horizontal style={{ marginTop: 20 }}>
                <PrimaryButton text="Сохранить" allowDisabledFocus onClick={formik.submitForm} disabled={formik.isSubmitting} />
            </Stack>
        </Card>
        </>
    );
}

export default EditUser;