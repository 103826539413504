import { FC } from "react";
import { isMobile } from "react-device-detect";
import ResetDesctop from "./desktop";
import ResetMobile from "./mobile";
import { useParams } from "react-router-dom";

export interface DataLK {
    banner: string
}

const Reset: FC = () => {
    const { token } = useParams();
    if (!isMobile) {
        return (
            <>
                <ResetDesctop token={token} />
            </>
        )
    } else {
        return (
            <>
                <ResetMobile token={token} />
            </>
        )
    }
}

export default Reset