import { PrimaryButton, Stack, TextField } from "@fluentui/react";
import axios from "axios";
import { FormikHelpers, useFormik } from "formik";
import { FC } from "react";
import { } from "react-bootstrap";
import { ErrorAlert } from "../../@library/interaction";
import { TUser } from "../../@store/useUser";
import Card from "../base/card";
import styled from "styled-components";
import { TSub } from "../../pages/LK";

const SubssContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;

    .news-image {
        height: 350px;
        width: 40%;

        background-image: url(../imgs/home.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 20px;
    }

    .news-text {
        width: calc(60% - 40px);
        font-size: 20px;
        line-height: 1.5;

        .news-title {
            line-height: 1.5;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;

            display: flex;
            align-items: center;
        }

        .news-more {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }

        p {
            font-size: 12px !important;
        }

        .news-date {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }
    }
`

const MySubs: FC<{
    sub: TSub
}> = ({ sub }) => {
    return (
        <Card>
            <SubssContainer>
                <div className="news-text">
                    <div className="news-title">Ваш тариф: {sub.tarifName}</div>
                    <div className="news-about" dangerouslySetInnerHTML={sub.description ? { __html: sub.description } : undefined}/>
                </div>
                <div className="news-date">Срок действия до: {sub.available}</div>
            </SubssContainer>
        </Card>
    );
}

export default MySubs;