import { FC } from "react";
import { useMain } from "../../store/useMain";
import { useUser } from "../../store/useUser";
import { useAtom } from "jotai";
import { openAuth } from "../desktop/auth";
import { Box, Button, Flex, Group, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { apiUrl } from "../../constant/routs";
import { IconArrowBarRight, IconArrowBarToRight } from '@tabler/icons-react';

const HeaderDesctop: FC = () => {
    const [logo, menu] = useMain(state => [state.logo, state.menu])
    const user = useUser(state => state.user)
    const [_, setOpened] = useAtom(openAuth);

    return (
        <Flex pt={20} px={30} align="center" justify="space-between">
            <Link to="/">
                <Box sx={{
                    height: 65,
                    width: 255,
                    backgroundImage: `url(${apiUrl}${logo})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }} />
            </Link>
            <Group spacing="xl" h={50}>
                {menu.map((item, idx) => {
                    return (
                        <Link key={idx} to={item.url} style={{ textDecoration: "none" }}>
                            <Text tt="uppercase" c="#fff">
                                {item.name}
                            </Text>
                        </Link>
                    )
                })}
            </Group>
            <Box>
                {user ? (
                    <Flex align="center">
                        <Link to={"/lk"} style={{ textDecoration: "none" }}>
                            <Text c="#fff">
                                {user.name} {user.surname}
                            </Text>
                        </Link>
                        <Button variant="transparent" color="#fff"
                            onClick={async () => {
                                if (window.confirm("Вы действительно хотите выйти?")) {
                                    useUser.setState({ user: null, token: null })
                                    localStorage.removeItem("token")
                                }
                            }}>
                            <IconArrowBarRight color="#fff" />
                        </Button>
                    </Flex>
                ) : (
                    <Button variant="filled" color="teal" styles={{
                        label: {
                            textTransform: "uppercase",
                            fontWeight: 500,
                            fontSize: 14
                        }
                    }}
                        onClick={() => setOpened(true)}>
                        <Flex align="center" justify="space-between">
                            <IconArrowBarToRight />
                            <Text ml={10}>
                                Войти
                            </Text>
                        </Flex>
                    </Button>
                )}
            </Box>
        </Flex>
    )
}

export default HeaderDesctop