import {FC, useEffect, useState} from "react";
import {ITask, Menu} from "../../../store/types";
import Layout from "../../../components/layout";
import {Box, Button, Center, Container, Grid, Modal, Text} from "@mantine/core";
import BunnerDeskt from "../../../components/desktop/bunner";
import BreadcrumbsMod from "../../../components/desktop/breadcrumpsMod";
import CalcSideBar from "./components/calcSidebar";
import CalculationOfAudienceCoverageOfOneMediaRequest from "../calcs/calculationOfAudienceCoverageOfOneMediaRequest";
import moment from "moment";
import {useUser} from "../../../store/useUser";
import {useAtom} from "jotai";
import {openAuth} from "../../../components/desktop/auth";
import {useNavigate} from "react-router-dom";
import FooterDesktop from "../../../components/layout/footer";
import MultipleMediaFudience from "../calcs/multipleMediaFudience";
import axios from "axios";
import {apiUrl} from "../../../constant/routs";
import CalculationOfFrequencyCoverageDistribution from "../calcs/calculationOfFrequencyCoverageDistribution";
import CalculationOfPrivateEffectiveAudienceReachAndEffectiveNumberOfContacts from "../calcs/calculationOfPrivateEffectiveAudienceReachAndEffectiveNumberOfContacts";
import MeasurementErrorCalculation from "../calcs/measurementErrorCalculation";
import EffectiveNumberOfPlacementsValues from "../calcs/effectiveNumberOfPlacementsValues";
import DurationOfAdvertisingSilence from "../calcs/durationOfAdvertisingSilence";
import CalculationOfTheRatioOfPercentagePointsOfGrowthAndRating from "../calcs/calculationOfTheRatioOfPercentagePointsOfGrowthAndRating";
import SampleSizeCalculation from "../calcs/sampleSizeCalculation";
import SampleSizeCalculationMin from "../calcs/sampleSizeCalculationMin";

interface Props {
    banner: string
    parent: Menu,
    child?: Menu,
    calcs: ITask[]
    url: string
    description: string
}

const CalcPageDesk: FC<Props> = ({
    banner, parent, child, calcs, url, description
}) => {
    const [calcId, setCalcId] = useState<number>()
    const [user, token] = useUser(state => [state.user, state.token])
    const [_, setOpened] = useAtom(openAuth);
    const nav = useNavigate()
    const [dataModal, setDataModal] = useState<string>()

    useEffect(() => {
        getInfo()
    }, [calcId])



    return (
        <>
            <Layout>
                <Box pos="relative">
                    <BunnerDeskt banner={banner} />
                    <Container size="80rem" pt={30}>
                        <BreadcrumbsMod parent={parent} child={child} />
                    </Container>
                    <Container size="80rem" pt={40} pb={70}>
                        <Grid>
                            <Grid.Col span={3}>
                                <CalcSideBar calcs={calcs} url={url} />
                            </Grid.Col>
                            <Grid.Col span={9}>
                                {token && moment(user?.subscribe_to).unix() >= moment().unix() ? (
                                    <Box sx={{
                                        "span": {
                                            fontWeight: "normal",
                                            fontStyle: "italic"
                                        }
                                    }}>
                                        {url === "1" ? (
                                            <CalculationOfAudienceCoverageOfOneMediaRequest setCalcId={setCalcId} />
                                        ) : url === "2" ? (
                                            <MultipleMediaFudience setCalcId={setCalcId} />
                                        ) : url === "3" ? (
                                            <CalculationOfFrequencyCoverageDistribution setCalcId={setCalcId} />
                                        ) : url === "4" ? (
                                            <CalculationOfPrivateEffectiveAudienceReachAndEffectiveNumberOfContacts setCalcId={setCalcId} />
                                        ) : url === "9" ? (
                                            <EffectiveNumberOfPlacementsValues setCalcId={setCalcId} />
                                        ) : url === "10" ? (
                                            <DurationOfAdvertisingSilence setCalcId={setCalcId} />
                                        ) : url === "5" ? (
                                            <CalculationOfTheRatioOfPercentagePointsOfGrowthAndRating setCalcId={setCalcId} />
                                        ) : url === "6" ? (
                                            <SampleSizeCalculation setCalcId={setCalcId} />
                                        ) : url === "7" ? (
                                            <SampleSizeCalculationMin setCalcId={setCalcId} />
                                        ) : url === "8" ? (
                                            <MeasurementErrorCalculation setCalcId={setCalcId} />
                                        ) : url === "" && (
                                            <Box component="h1" fz={30} fw="bold" mb={30} mt={0}>
                                                О калькуляторах
                                            </Box>
                                        )}
                                    </Box>
                                ) : (
                                    <Center mt={0} mb={20} w="100%" py={40} px={40} bg="#dfe2eb" sx={{
                                        boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                                        flexDirection: "column",
                                        "span": {
                                            fontStyle: "normal"
                                        }
                                    }}>
                                        {token === null ? (
                                            <>
                                                <Text fw="bold" fz={18} mb={20}>
                                                    Для работы с калькулятором нужно авторизоваться
                                                </Text>
                                                <Button color="teal" size="md" radius="xs" onClick={() => setOpened(true)}>
                                                    Авторизоваться
                                                </Button>
                                            </>
                                        ): undefined}
                                        {user?.subscribe_to === null || moment(user?.subscribe_to).unix() <= moment().unix() ? (
                                            <>
                                                <Text fw="bold" fz={18} mb={20}>
                                                    Работа с калькулятором доступна по подписке
                                                </Text>
                                                <Button color="teal" size="md" radius="xs" onClick={() => nav("/lk")}>
                                                    Оформить подписку
                                                </Button>
                                            </>
                                        ) : undefined}
                                    </Center>
                                )}
                                {description && (
                                    <Box w="100%" py={30} px={45} bg="#fff" sx={{
                                        boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)"
                                    }}>
                                        <Box dangerouslySetInnerHTML={{
                                            __html: description
                                        }} />
                                    </Box>
                                )}
                            </Grid.Col>
                        </Grid>
                    </Container>
                    <FooterDesktop />
                </Box>
            </Layout>
            <Modal size="70%" opened={calcId !== undefined} onClose={() => setCalcId(undefined)} title="Справка">
                <Box dangerouslySetInnerHTML={dataModal ? {__html: dataModal} : undefined} />
            </Modal>
        </>
    )

    async function getInfo() {
        try {
            const {data} = await axios.get(`${apiUrl}api/description/${calcId}`, token ? {
                headers: {"Authorization": `Bearer ${token}`}
            } : undefined)
            setDataModal(data.text.help)
        } catch (error) {

        }
    }
}

export default CalcPageDesk