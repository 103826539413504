import axios from "axios";
import { FC, useEffect, useState } from "react";
import Card from "../base/card";
import styled from "styled-components";
import { ErrorAlert } from "../../@library/interaction";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "@fluentui/react";

export type TTarif = {
    id: number,
    name: string,
    description: string
    price: number
    termOfValidity: number
}

const SubssContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;

    .news-image {
        height: 350px;
        width: 40%;

        background-image: url(../imgs/home.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 20px;
    }

    .news-text {
        width: calc(60% - 40px);
        font-size: 20px;
        line-height: 1.5;

        .news-title {
            line-height: 1.5;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;

            display: flex;
            align-items: center;
        }

        .news-more {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }

        p {
            font-size: 12px !important;
        }

        .news-date {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }
    }
`

const EditUser: FC<{
    token: string | null;
}> = ({ token }) => {
    const [tarif, setTarif] = useState<TTarif[]>()
    const navigate = useNavigate()
    const onGetData = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: "/api/personal/getTarif",
                headers: { 'Authorization': `Bearer ${token}`, 'Cache-Control': `no-cache` },
            });
            setTarif(response.data)
        } catch (error: any) {
            const { response } = error;
            try {
                console.log(response.data);

            } catch (error: any) {
                ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
            }
        }
    }

    const createSub = async (id: number) => {
        try {
            const response = await axios({
                method: "post",
                url: "/api/personal/createSub",
                headers: { 'Authorization': `Bearer ${token}`, 'Cache-Control': `no-cache` },
                data: {
                    tarifId: id
                }
            });
            window.location.replace(response.data.redirect);
            // setTarif(response.data)
        } catch (error: any) {
            const { response } = error;
            try {
                console.log(response.data);

            } catch (error: any) {
                ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
            }
        }
    }

    useEffect(() => {
        if (token) onGetData();
        else navigate('/')
    }, [])

    return (
        <>
            {tarif?.map((item, idx) => {
                if (item.price === 0) {
                    return
                }
                return (
                    <Card>
                        <SubssContainer>
                            <div className="news-text">
                                <div className="news-title">{item.name} за {item.price} рублей</div>
                                <div className="news-about" dangerouslySetInnerHTML={item.description ? { __html: item.description } : undefined} />
                                <PrimaryButton text="Оформить" allowDisabledFocus onClick={() => createSub(item.id)} />
                            </div>
                            <div className="news-date">Срок действия: {item.termOfValidity} дней</div>
                        </SubssContainer>
                    </Card>
                )
            })}
        </>
    );
}

export default EditUser;