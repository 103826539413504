import { PrimaryButton } from "@fluentui/react";
import axios from "axios";
import { FC, useLayoutEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { NewsType } from "../@entities/type";
import { ErrorAlert } from "../@library/interaction";
import { useToken } from "../@store/useAuth";
import { useGlobal } from "../@store/useGlobal";
import { useUser } from "../@store/useUser";
import About from "../components/base/about";
import Banner from "../components/base/banner";
import Card from "../components/base/card";
import SideBar from "../components/base/sidebar";
import LayoutDefault from "../components/layouts/layout";
import ModalBook from "../components/pdf/modal";
import SideBarBooks from "../components/pdf/sidebarBooks";


const BooksContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;

    .news-image {
        height: 350px;
        width: 40%;

        background-image: url(../imgs/home.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 20px;
    }

    .news-text {
        width: calc(60% - 40px);
        font-size: 20px;
        line-height: 1.5;

        .news-title {
            line-height: 1.5;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;

            display: flex;
            align-items: center;
        }

        .news-more {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }

        p {
            font-size: 12px !important;
        }

        .news-date {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }
    }
`

const Books: FC<{

}> = ({

}) => {

        const [title, setTitle] = useState<string | null>();
        const [subTitle, setSubtitle] = useState<string | null>();
        const [bunner, setBunner] = useState<string>('');
        const [description, setDescription] = useState<string | null>();
        const [catList, setCatList] = useState<any[]>([]);
        const [bookList, setBookList] = useState<any[]>([]);
        const [currentCat, setCurrntCat] = useState<any>()

        const navigate = useNavigate()
        let location = useLocation();

        const { url } = useParams();

        const [token, setToken] = useToken(state => [state.token, state.setToken])
        const [user, setUser] = useUser(state => [state.user, state.setUser])
        const [viewBook, setViewBook] = useState<string | null>()
        const [showAuth, setShowAuth] = useGlobal(state => [state.showAuth, state.setShowAuth])
        const [catArr, setCarArr] = useState<any[]>([])

        const onGetData = async () => {
            try {
                const response = await axios({
                    method: "GET",
                    url: "/api/home/books",
                    headers: { 'Cache-Control': `no-cache` },
                });

                setTitle(response.data.title);
                setSubtitle(response.data.subtitle);
                setBunner(response.data.banner);
                setDescription(response.data.decription);
                setCatList(response.data.categoryes)
                setCarArr(response.data.catArr)

            } catch (error: any) {
                const { response } = error;
                try {
                    console.log(response.data, 'error');

                } catch (error: any) {
                    ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
                }
            }
        }

        const onGetBookList = async () => {
            if (url) {
                try {
                    const response = await axios({
                        method: "GET",
                        url: "/api/book_by_cat/" + url,
                        headers: { 'Cache-Control': `no-cache` },
                    });
                    setBookList(response.data);

                } catch (error: any) {
                    const { response } = error;
                    try {
                        console.log(response.data, 'error');

                    } catch (error: any) {
                        ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
                    }
                }
            }
        }

        const getCurrentCatList = (arr = catArr) => {
            let i = 0;
            arr.map((item, idx) => {
                if ('/articles-and-books/' + item.url == location.pathname) {
                    setCurrntCat(item)
                    ++i;
                }
                if (i <= 0) {
                    setCurrntCat(undefined)
                }
            })
        }

        const setFirstCat = () => {
            if ('/articles-and-books' === location.pathname) {
                catList.map(item => {
                    if(item.pid == 0 && item.id === 10) {
                        navigate('/articles-and-books/' + item.url)
                    }
                })
            }
        }

        useLayoutEffect(() => {
            onGetData()
            onGetBookList()
        }, [url])

        useLayoutEffect(() => {
            onGetBookList()
            getCurrentCatList()
            setFirstCat()
        }, [url, catArr])


        return (
            <LayoutDefault>
                <Banner title={title} subtitle={subTitle} backgound={bunner} children={undefined} />
                <Container>
                    <About title={'Книги и статьи'} subtitle={''} />
                    <div style={{ marginTop: 40 }}>
                        <Row>
                            <Col xs={4}>
                                <SideBarBooks section="articles-and-books" url={url} links={catList} />
                            </Col>
                            <Col xs={8}>
                                {currentCat?.description && (
                                    <Card>
                                        <div dangerouslySetInnerHTML={currentCat?.description ? { __html: currentCat?.description } : undefined} />
                                    </Card>
                                )}
                                {currentCat?.pid !== 0 && (
                                    bookList.map((item, idx) => {
                                        return (
                                            <Card key={idx}>
                                                <BooksContainer>
                                                    <div className="news-image" style={{ backgroundImage: `url(${item.image})` }}></div>
                                                    <div className="news-text">
                                                        <div className="news-title">{item.name}</div>
                                                        <div className="news-about" dangerouslySetInnerHTML={item.description ? { __html: item.description } : undefined} />
                                                        {token ? (
                                                            <PrimaryButton text="Читать" onClick={() => setViewBook(item.url)} allowDisabledFocus />
                                                        ) : (
                                                            <PrimaryButton text="Авторизоваться и читать" onClick={() => setShowAuth(true)} allowDisabledFocus />
                                                        )}
                                                    </div>
                                                </BooksContainer>
                                            </Card>
                                        )
                                    })
                                )}

                            </Col>
                        </Row>
                    </div>
                </Container>
                {viewBook && (
                    <ModalBook url={viewBook} setUrl={setViewBook} />
                )}
            </LayoutDefault>
        )
    }

export default Books