import { Anchor, Box, Button, Card, Flex, Text } from "@mantine/core";
import { FC } from "react";
import { DataTariff } from "..";
import { useUser } from "../../../store/useUser";
import { useAtom } from "jotai";
import { openAuth } from "../../../components/desktop/auth";
import axios from "axios";
import { apiUrl } from "../../../constant/routs";

const TariffItem: FC<{
    item: DataTariff
}> = ({ item }) => {
    const token = useUser(state => state.token)
    const [_, setOpened] = useAtom(openAuth);
    const user = useUser(state => state.user)
    
    return (
        <Card shadow="md" radius={10} p={0}>
            <Box pt={20} pb={10} px={30} sx={{
                borderBottom: "solid 1px #dfe2eb"
            }}>
                <Text fz={20} fw="normal" tt="uppercase" ta="center">
                    {item.name}
                </Text>
            </Box>
            <Box py={30} px={30} sx={{
                borderBottom: "solid 1px #dfe2eb"
            }}>
                <Text color="#f54a54" fz={40} fw={600} ta="center" lh={1}>
                    {item.price}
                </Text>
                <Text ta="center" fz={12} fw="normal">
                    рублей
                </Text>
            </Box>
            <Flex py={30} px={30} align="center" justify="center" sx={{
                borderBottom: "solid 1px #dfe2eb",
                minHeight: "30%"
            }}>
                <Text ta="center" fz={16} lh={1} dangerouslySetInnerHTML={{
                    __html: item.description
                }} />
            </Flex>
            <Box py={30} px={30}>
                <Button w="100%" h={50} disabled={item.id === 3 || user?.tariff !== null} color="teal" styles={{
                    label: {
                        textTransform: "uppercase",
                        fontWeight: 500,
                        fontSize: 14
                    }
                }} onClick={() => {
                    if (!token) setOpened(true)
                    else createOrder(item.id)
                }}>
                    {item.id === user?.tariff?.id? "Ваш тариф": "Активировать"}
                </Button>
            </Box>
        </Card>
    )

    async function createOrder(id: number) {
        try {
            const { data } = await axios.post(`${apiUrl}api/cart/createOrder`, {
                id
            }, token ? {
                headers: { "Authorization": `Bearer ${token}` }
            } : undefined)
            if(!data.error){
                window.location.href = data.url;
            }
        } catch {

        }
    }
}

export default TariffItem