import axios from "axios";
import { FC, useEffect, useState } from "react";
import { apiUrl } from "../../constant/routs";
import { CalcData } from ".";
import { useUser } from "../../store/useUser";
import { isMobile } from "react-device-detect";
import CalcHomeDesk from "./desktop";
import CalcHomeMobile from "./mobile";

interface Props {
    type: "media" | "research"
}

const CalcInfo: FC<Props> = ({ type }) => {
    const [data, setData] = useState<CalcData>({
        banner: "",
        subscribe: false,
        description: "",
        calcs: []
    })
    const token = useUser(state => state.token)
    useEffect(() => {
        getData()
    }, [type])
    if (!isMobile) {
        return <CalcHomeDesk banner={data.banner} parent={ type === "media"?{
            name: "МЕДИА КАЛЬКУЛЯТОРЫ",
            url: "/calc/media"
        }:{
            name: "КАЛЬКУЛЯТОРЫ для социологических, маркетинговых и рекламных исследований",
            url: "/calc/research"
        }} calcs={data.calcs} url={type} description={data.description} />
    }
    return <CalcHomeMobile banner={data.banner} calcs={data.calcs} url={type} description={data.description} name={""} />
    
    async function getData() {
        try {
            const responseAll = await axios.get(`${apiUrl}api/description/${type}?v=${Math.random()}`, token ? {
                headers: { "Authorization": `Bearer ${token}` }
            } : undefined)
            const { data } = await axios.get(`${apiUrl}api/home/tasks/calc?v=${Math.random()}`)
            setData({
                banner: data.banner,
                description: responseAll.data.text.description,
                calcs: data.tasks,
                subscribe: responseAll.data.subscribe
            })
        } catch (error) {
    
        }
    }
}

export default CalcInfo