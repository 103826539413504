import { FC } from "react";
import styled from "styled-components";

const FooterWrap = styled.div`
    background-color: rgba(0, 0, 0, .4);
    padding: 20px 100px;
    color: #fff;
    text-align: center;
`

const Footer: FC = () => {
    return (
        <FooterWrap >
            © c-mix.ru Все права защищены 2022 год
            <br />
            <a href="http://c-mix.ru/post/pravovyie-dokumentyi" target="_blank">Правовые документы и тарифы</a>
        </FooterWrap>
    )
}

export default Footer;