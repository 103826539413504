import { FC } from "react";
import { useMain } from "../../store/useMain";
import { useUser } from "../../store/useUser";
import { useDisclosure } from "@mantine/hooks";
import { useAtom } from "jotai";
import { ActionIcon, Anchor, Box, Button, Drawer, Flex, Stack, Text } from "@mantine/core";
import { IconArrowBarLeft, IconArrowBarToRight, IconMenu2, IconUser } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { apiUrl } from "../../constant/routs";
import Auth, { openAuthMobile } from "../mobile/auth";


const HeaderMobile: FC = () => {
    const [logo_green, menu, legal] = useMain(state => [state.logo_green, state.menu, state.legal])
    const user = useUser(state => state.user)
    const [opened, { open, close }] = useDisclosure(false);
    const [_, setOpened] = useAtom(openAuthMobile);

    return (
        <>
            <Flex h={60} p={10} align="center" justify="space-between">
                <ActionIcon h={38} w={38} color="teal" onClick={open}>
                    <IconMenu2 />
                </ActionIcon>
                <Link to="/">
                    <Box sx={{
                        height: 40,
                        width: 155,
                        backgroundImage: `url(${apiUrl}${logo_green})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }} />
                </Link>
                <Box>
                    {user ? (
                        <Link to={"/lk"} style={{textDecoration: "none"}}>
                            <Text c="#4ad295">
                                {user.name} {user.surname}
                            </Text>
                        </Link>
                    ) : (
                        <ActionIcon h={38} w={38} color="#4ad295" onClick={() => setOpened(true)}>
                            <IconArrowBarToRight color="#4ad295" />
                        </ActionIcon>
                    )}
                </Box>
            </Flex>
            <Drawer.Root opened={opened} onClose={close}>
                <Drawer.Overlay />
                <Drawer.Content bg={user ? "#f5f6fa" : "#00af80"}>
                    <Box px={30} pt={18} bg="#00af80">
                        <Drawer.CloseButton left={0} c="#fff" size="xl" />
                        <Box pb={14} style={{
                            borderBottom: "solid 1px #4ad295",
                        }} />
                    </Box>
                    <Drawer.Body h="calc(100% - 100px)" p={0} style={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <Box h="100%">
                            <Box py={25} px={30} bg="#00af80">
                                <Stack spacing="xl">
                                    {menu.map((item, idx) => {
                                        return (
                                            <Link key={idx} to={item.url} style={{ textDecoration: "none" }}>
                                                <Text tt="uppercase" c="#fff" fz={14}>
                                                    {item.name}
                                                </Text>
                                            </Link>
                                        )
                                    })}
                                </Stack>
                            </Box>
                            {user ? (
                                <Box px={30} pt={26}>
                                    <Stack spacing="xl">
                                        <Link to='/lk' style={{ textDecoration: "none" }}>
                                            <Flex align="center">
                                                <IconUser size={22} color="#444753" />
                                                <Text component="span" ml={10} fz={14} tt="uppercase" c="#444753">
                                                    личный кабинет
                                                </Text>
                                            </Flex>
                                        </Link>
                                        <Anchor onClick={async () => {
                                            if (window.confirm("Вы действительно хотите выйти?")) {
                                                useUser.setState({ user: null, token: null })
                                                localStorage.removeItem("token")
                                            }
                                        }}>
                                            <Flex align="center">
                                                <IconArrowBarLeft size={22} color="#444753" />
                                                <Text component="span" ml={10} fz={14} c="#444753" tt="uppercase">
                                                    Выйти
                                                </Text>
                                            </Flex>
                                        </Anchor>
                                    </Stack>
                                </Box>
                            ) : (
                                <Box px={30}>
                                    <Box pb={30} style={{
                                        borderTop: "solid 1px #4ad295",
                                    }} />
                                    <Box display="block" w="50%">
                                        <Button w="100%" h={40} variant="filled" color="teal" styles={{
                                            label: {
                                                textTransform: "uppercase",
                                                fontWeight: 500,
                                                fontSize: 14
                                            }
                                        }}
                                            onClick={() => setOpened(true)}>
                                            <Flex align="center">
                                                <Text mr={10}>
                                                    Войти
                                                </Text>
                                                <IconArrowBarToRight color="#fff" />
                                            </Flex>
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        <Box px={30} pb={20}>
                            <Text c={user ? "#0a2239" : "#fff"} fw="bold" fz={14} mb={10} tt="uppercase">Правовые документы</Text>
                            <Text fz={14} lh={2} tt="uppercase" dangerouslySetInnerHTML={{ __html: legal }} sx={{
                                "a": {
                                    textDecoration: user ? "#0a2239" : "#fff",
                                    color: user ? "#0a2239" : "#fff"
                                }
                            }} />
                        </Box>
                    </Drawer.Body>
                </Drawer.Content>
            </Drawer.Root>
            <Auth />
        </>
    )
}

export default HeaderMobile