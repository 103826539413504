import { Box, Center, LoadingOverlay, ScrollArea } from '@mantine/core';
import axios from 'axios';
import { FC, ReactNode, useLayoutEffect } from "react";
import { useLocation } from 'react-router-dom';
import { apiUrl } from '../../constant/routs';
import { useMain } from '../../store/useMain';
import { useUser } from '../../store/useUser';
import Auth from '../desktop/auth';
import { isMobile } from 'react-device-detect';

interface Props {
    children?: ReactNode,
}

const Layout: FC<Props> = ({
    children
}) => {
    const location = useLocation()
    const loading = useMain(state => state.loading)
    const token = useUser(state => state.token)

    useLayoutEffect(() => {
        getInit()
    }, [location.pathname, token])

    if (!isMobile) {
        <>
            {children}
        </>
    }

    return (
        <>
            <ScrollArea bg="#f5f6fa" sx={{
                "p > img": {
                    width: "100% !important"
                }
            }}>
                {children}
                <LoadingOverlay pos="fixed" overlayBlur={5} left={0} zIndex={90} visible={loading} color="#4ad295" loaderProps={{
                    color: "#4ad295"
                }} />
                {isMobile && (
                    <Center bg="#dfe2eb">
                        <Box p={50} ta="center">
                            © c-mix.ru Все права защищены <br /> 2023 год
                        </Box>
                    </Center>
                )}
            </ScrollArea>
            <Auth />
        </>
    )

    async function getInit() {
        try {
            const { data } = await axios.get(`${apiUrl}api/home/init?v=${Math.random()}`, token ? {
                headers: { "Authorization": `Bearer ${token}` },
                onDownloadProgress: (progressEvent) => {
                    console.log(777);
                },
            } : undefined,);

            useMain.setState({
                logo: data.logo,
                logo_green: data.logo_green,
                menu: data.menu,
                legal: data.legal,
                contacts: data.contacts,
                company_details: data.company_details
            })
            if (data.user) {
                useUser.setState({
                    user: {
                        ...data.user,
                        tariff: data.tarif
                    }
                })
            }
        } catch {

        }
    }
}

export default Layout