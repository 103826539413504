import { FC, useLayoutEffect } from "react";
import { DocData } from "..";
import { IDoc, Menu } from "../../../store/types";
import Layout from "../../../components/layout";
import { useDisclosure } from "@mantine/hooks";
import HeaderMobile from "../../../components/layout/headerMobile";
import BunnerMobile from "../../../components/mobile/bunnerMobile";
import { Box, Button, Center, Container, Modal, Stack, Text } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../../store/useUser";
import moment from "moment";
import PDF from "./pdf";

interface Props {
    data: DocData,
    child?: Menu,
    parent: Menu,
    pdf: string | null
}

const DocMobile: FC<Props> = ({ data, child, pdf }) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [token, user] = useUser(state => [state.token, state.user])
    const nav = useNavigate()

    useLayoutEffect(() => {
        close()
    }, [child])
    return (
        <Layout>
            <HeaderMobile />
            <BunnerMobile banner={data.banner} />
            {data.dataAll && (
                <>
                    <Container pos="relative" top={-25}>
                        <Center>
                            {token && moment(user?.subscribe_to).unix() >= moment().unix() && user?.tariff?.books === 1 ? (
                                <Button h={50} w="100%" color="teal" fw={500} tt="uppercase" onClick={() => open()}>
                                    РАЗДЕЛЫ КНИГИ
                                </Button>
                            ) : token && (user?.subscribe_to === null || moment(user?.subscribe_to).unix() <= moment().unix()) ? (
                                <Box>
                                    <Button h={50} w="100%" color="teal" fw={500} tt="uppercase" onClick={() => nav('/lk')}>
                                        оформить подписку
                                    </Button>
                                    <Text mt={20}>
                                        Для доступа к тексту книги необходимо оформить подписку
                                    </Text>
                                </Box>
                            ) : token && user?.tariff?.books === 0 ? (
                                <>
                                    <Box>
                                        <Button h={50} w="100%" color="teal" fw={500} tt="uppercase" onClick={() => nav('/tariff')}>
                                            Сменить тариф
                                        </Button>
                                        <Text mt={20}>
                                            Ваш тариф {user?.tariff.name}. В этом тарифе книга О методах точного мышления не доступна
                                        </Text>
                                    </Box>
                                </>
                            ) : (
                                <Box>
                                    <Button disabled h={50} w="100%" color="teal" fw={500} tt="uppercase" onClick={() => open()}>
                                        ЧИТАТЬ КНИГУ
                                    </Button>
                                    <Text mt={20}>
                                        Для доступа к тексту книги необходимо авторизоваться
                                    </Text>
                                </Box>
                            )}
                        </Center>
                        <Text component="h1" fz={20} fw="bold" mb={10} mt={30} tt="uppercase" ta="center">
                            {data.dataAll.name}
                        </Text>
                        {pdf ? (
                            <>
                                <PDF pdf={pdf} />
                            </>
                        ) : (
                            <Box w="100%" p={15} bg="#fff" sx={{
                                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                                borderRadius: 10
                            }}>
                                <Box sx={{
                                    "*": {
                                        fontSize: 14
                                    }
                                }} dangerouslySetInnerHTML={{
                                    __html: data.dataAll.description
                                }} />
                            </Box>
                        )}
                    </Container>
                    <Modal.Root
                        fullScreen
                        opened={opened}
                        size={555}
                        onClose={close}>
                        <Modal.Overlay bg="rgba(0, 0, 0, 0.5)" blur="10px" />
                        <Modal.Content bg="#00af80">
                            <Box px={30} pt={18}>
                                <Modal.CloseButton left={-10} c="#fff" size="xl" />
                                <Text fw="bold" mt={15} mb={15} fz={20} c="#fff">
                                    РАЗДЕЛЫ КНИГИ
                                </Text>
                                <Box pb={15} style={{
                                    borderTop: "solid 1px #4ad295",
                                }} />
                            </Box>
                            <Modal.Body mt={10} pos="relative">
                                <Sidebar items={data.dataAll?.books} url={child ? child.url : ""} />
                            </Modal.Body>
                        </Modal.Content>
                    </Modal.Root>
                </>
            )}
        </Layout>
    )
}

const Sidebar: FC<{
    items: IDoc[]
    url: string
}> = ({ items, url }) => {
    return (
        <Stack ml={15} spacing={0}>
            {items.map((item, idx) => {
                return (
                    <Box key={idx}>
                        <Link to={"/doc/book/" + item.url} style={{ textDecoration: "none" }}>
                            <Box p={10} fz={16} c="#fff" style={{
                                backgroundColor: url.replace("book/", "") === item.url ? "#4ad295" : "",
                                fontWeight: url.replace("book/", "") === item.url ? "700" : "normal",
                                borderRadius: 8
                            }}>
                                {item.name}
                            </Box>
                        </Link>
                    </Box>
                )
            })}
        </Stack>
    )
}

export default DocMobile