import { Box, Container, Grid, Text } from "@mantine/core"
import Layout from "../../../components/layout"
import { ITask, Menu } from "../../../store/types"
import { FC } from "react"
import BunnerDeskt from "../../../components/desktop/bunner"
import BreadcrumbsMod from "../../../components/desktop/breadcrumpsMod"
import CalcSideBar from "./components/calcSidebar"
import FooterDesktop from "../../../components/layout/footer"

interface Props {
    banner: string
    parent: Menu,
    child?: Menu
    calcs: ITask[]
    url: string
    description: string
}

const CalcHomeDesk: FC<Props> = ({
    banner, parent, child, calcs, url, description
}) => {
    return (
        <Layout>
            <Box pos="relative">
                <BunnerDeskt banner={banner} />
                <Container size="80rem" pt={30}>
                    <BreadcrumbsMod parent={parent} child={child} />
                </Container>
                <Container size="80rem" pt={40} pb={70}>
                    <Grid>
                        <Grid.Col span={3}>
                            <CalcSideBar calcs={calcs} url={url} />
                        </Grid.Col>
                        <Grid.Col span={9}>
                            {url === "research" ? (
                                <Text component="h1" fz={30} fw="bold" mb={30} mt={0}>
                                    О калькуляторах для социологических, маркетинговых и рекламных исследований
                                </Text>
                            ) : url === "media" ? (
                                <Text component="h1" fz={30} fw="bold" mb={30} mt={0}>
                                    О медиа калькуляторах
                                </Text>
                            ) : url === "" && (
                                <Text component="h1" fz={30} fw="bold" mb={30} mt={0}>
                                    О калькуляторах
                                </Text>
                            )}
                            {description && (
                                <Box w="100%" p={50} bg="#fff" sx={{
                                    boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)"
                                }}>
                                    <Box dangerouslySetInnerHTML={{
                                        __html: description
                                    }} />
                                </Box>
                            )}
                        </Grid.Col>
                    </Grid>
                </Container>
                <FooterDesktop />
            </Box>
        </Layout>
    )
}

export default CalcHomeDesk