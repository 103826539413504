import { FC } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Doc from "./pages/Doc";
import DocBook from "./pages/Doc/book";
import Calc from "./pages/Calc";
import Home from "./pages/Home/intex";
import CalcInfo from "./pages/Calc/CalcInfo";
import CalcPage from "./pages/Calc/CalcPage";
import ArticlesAndBooks from "./pages/ArticlesAndBooks";
import LK from "./pages/LK";
import Tariff from "./pages/Tariff";
import Page from "./pages/Page";
import Reset from "./pages/Reset";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/doc/book/:url",
        element: <DocBook />
    },
    {
        path: "/doc",
        element: <Doc />
    },
    {
        path: "/calc",
        element: <Calc />
    },
    {
        path: "/calc/media",
        element: <CalcInfo type={"media"} />
    },
    {
        path: "/calc/research",
        element: <CalcInfo type={"research"} />
    },
    {
        path: "/calc/media/1",
        element: <CalcPage type={"media"} calcId={1} />
    },
    {
        path: "/calc/media/2",
        element: <CalcPage type={"media"} calcId={2} />
    },
    {
        path: "/calc/media/3",
        element: <CalcPage type={"media"} calcId={3} />
    },
    {
        path: "/calc/media/4",
        element: <CalcPage type={"media"} calcId={4} />
    },
    {
        path: "/calc/media/9",
        element: <CalcPage type={"media"} calcId={9} />
    },
    {
        path: "/calc/media/10",
        element: <CalcPage type={"media"} calcId={10} />
    },
    {
        path: "/calc/media/5",
        element: <CalcPage type={"research"} calcId={5} />
    },
    {
        path: "/calc/research/6",
        element: <CalcPage type={"research"} calcId={6} />
    },
    {
        path: "/calc/research/7",
        element: <CalcPage type={"research"} calcId={7} />
    },
    {
        path: "/calc/research/8",
        element: <CalcPage type={"research"} calcId={8} />
    },
    {
        path: "/articles-and-books",
        element: <ArticlesAndBooks />
    },
    {
        path: "/articles-and-books/:url",
        element: <ArticlesAndBooks />
    },
    {
        path: "/lk",
        element: <LK />
    },
    {
        path: "/lk/reset",
        element: <Reset />
    },
    {
        path: "/lk/forgot-password/:token",
        element: <Reset />
    },
    {
        path: "/tariff",
        element: <Tariff />
    },
    {
        path: "/post/:url",
        element: <Page />
    },
])

const Routes: FC = () => {
    return <RouterProvider router={router} />
}

export default Routes