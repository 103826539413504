import { ComboBox, DefaultButton, IComboBox, IComboBoxOption, MessageBar, MessageBarType, PrimaryButton, TextField } from "@fluentui/react";
import axios from "axios";
import { isNumber } from "lodash";
import React from "react";
import { FC, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../@library/interaction";
import { useToken } from "../../@store/useAuth";
import { useUser } from "../../@store/useUser";
import { Card } from "react-bootstrap";

// 3

const CalculationOfFrequencyCoverageDistribution: FC<{
    setTaskID: (tastId: number | undefined) => void
}> = ({
    setTaskID
}) => {

        const navigate = useNavigate()
        type dataObjectType = {
            mediaGroupReach: number,
            mediaRating: number,
            numberOfMediaEvents: number,
            numberOfMediaContacts: number,
            g_unless_projection: number | null | undefined
        }

        const [serverMessage, setServerMessge] = useState<string>();
        const [subscribe, setSubscribe] = useState<boolean>(true)

        const [token, setToken] = useToken(state => [state.token, state.setToken])
        const [user, setUser] = useUser(state => [state.user, state.setUser])

        const [mediaGroupReach, setMediaGroupReach] = useState<number>(50)
        const [mediaRating, setMediaRating] = useState<number>(10)
        const [numberOfMediaEvents, setNumberOfMediaEvents] = useState<number>(6)
        const [numberOfMediaContacts, setNumberOfMediaContacts] = useState<number>(2)
        const [g_unless_server, setG_unless_server] = useState<number>(32)
        const [g_unless_projection, setG_unless_projection] = useState<number | null | undefined>(0)


        const [requstData, setRequstData] = useState<dataObjectType | null>(null);
        const [answer, setAnswer] = useState<number | null>(null)

        function onClickHelp(taskId: number) {
            setTaskID(taskId)
            console.log('Task', taskId);
        }

        //проверка всех значений
        function checkingValues() {
            if (mediaRating < 0.1 || mediaRating > mediaGroupReach) return false;
            if (numberOfMediaContacts < 1 || numberOfMediaContacts > numberOfMediaEvents) return false;
            if (mediaGroupReach <= 0 || mediaGroupReach > 100) return false;
            if (numberOfMediaEvents <= 0 || numberOfMediaEvents > 200) return false;
            return true;
        }

        const onChangeMediaRating = (e: any) => {
            const value = +e.target.value;
            setMediaRating(value);
        }

        const onChangeNumberOfMediaContacts = (e: any) => {
            const value = +e.target.value;
            setNumberOfMediaContacts(value);
        }

        const onChangeMediaGroupReach = (e: any) => {
            const value = +e.target.value;
            setMediaGroupReach(+value.toFixed(1))
        }

        const onChamgeNumberOfMediaEvents = (e: any) => {
            const value = +e.target.value;
            setNumberOfMediaEvents(value);
        }

        const options: IComboBoxOption[] = [
            { key: 0, text: "Введи G∞" },
            { key: 1, text: "Радио" },
            { key: 2, text: "ТВ" },
            { key: 3, text: "Пресса" },
            { key: 4, text: "Интернет" },
        ];

        const onChangeGMode = React.useCallback(
            (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
                const val = option?.key;
                if (val != undefined && val != null && isNumber(val)) setG_unless_projection(val)
            },
            [],
        );

        const onSubmitData = async () => {
            if (checkingValues() && user && token) {
                try {
                    const token = localStorage.getItem('token')
                    if (!token) {
                        navigate('/authorization')
                        return null
                    }

                    const response = await axios({
                        headers: { 'Authorization': `Bearer ${token}` },
                        method: "POST",
                        url: "/api/calculationOfFrequencyCoverageDistribution",
                        data: requstData
                    });

                    setAnswer(response.data.result);
                    setG_unless_server(response.data.g_unless_server.toFixed(1))
                } catch (error: any) {
                    const { response } = error;
                    try {
                        if (response.status == 422) {
                            console.log(response.data);
                            setServerMessge(response.data?.danger);
                            setSubscribe(false)
                        } else {
                            console.log(response, 'error2');
                            setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                        }

                    } catch (error: any) {
                        setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                    }
                }
            }
        }

        useLayoutEffect(() => {
            checkingValues();

            setRequstData({
                mediaGroupReach,
                mediaRating,
                numberOfMediaEvents,
                numberOfMediaContacts,
                g_unless_projection
            });

        }, [mediaGroupReach, mediaRating, numberOfMediaEvents, numberOfMediaContacts, g_unless_projection])

        useLayoutEffect(() => {
            if (requstData) onSubmitData();
        }, [requstData])

        if (serverMessage) {
            return (
                <div className="task">
                    <div className="block-wrapper" style={{padding: 30}}>
                        <Card>
                            <b>{serverMessage}</b> <br /> <br />
                            {!subscribe && (
                                <PrimaryButton text="Офромить подписку" onClick={() => navigate("/lk")} allowDisabledFocus />
                            )}
                        </Card>
                    </div>
                </div>
            )
        }

        return (
            <div className="task">
                <div className="block-wrapper">
                    <div className="title">
                        <div className="title-content">
                            Вычисление частотного распределения охвата <span>g(f)</span><br /> в зависимости от числа медиасобытий <span>m,</span> <span>R,</span> <span>G<sup>∞</sup></span> и <span>f</span>
                        </div>
                        <DefaultButton
                            title="Emoji"
                            ariaLabel="Emoji"
                            text={'Помощь'}
                            onClick={() => onClickHelp(3)}
                        />
                    </div>
                    <div className="input-body">
                        <div className="table">

                            <div className="table-row">
                                <div className="name">
                                    Рейтинг медиа <i>R, %</i>
                                </div>
                                <div className="value">
                                    <TextField suffix="%" type="number" min={0.1} max={100} step={0.1} value={String(mediaRating)} onChange={onChangeMediaRating} />
                                </div>
                            </div>
                            {(mediaRating < 0.1 || mediaRating > mediaGroupReach) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Рейтинг медиа <i>R, %</i> не может быть больше чем Предельный охват группы медиа <i>G<sup>∞</sup>, %</i> ({mediaGroupReach}%)
                                </MessageBar>
                            ) : null}

                            <div className="table-row">
                                <div className="name" style={{ width: 'calc(60% - 20px)' }}>
                                    Предельный охват медиа <i>G<sup>∞</sup>, %</i>
                                </div>

                                <div className="value" style={{ border: 'none' }}>
                                    <ComboBox
                                        allowFreeform={false}
                                        autoComplete={'off'}
                                        options={options}
                                        defaultSelectedKey={0}
                                        onChange={onChangeGMode}
                                    />
                                </div>
                                <div className="value">
                                    <TextField type="number" suffix="%" min={0.1} max={100} disabled={g_unless_projection != 0 ? true : false} step={1} value={g_unless_projection == 0 ? String(mediaGroupReach) : String(g_unless_server)} onChange={onChangeMediaGroupReach} />
                                </div>
                            </div>
                            {(mediaGroupReach <= 0 || mediaGroupReach > 100) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Предельный охват медиа не может быть больше 100% или меньше чем 0,1%
                                </MessageBar>
                            ) : null}

                            <div className="table-row">
                                <div className="name">
                                    Число медиасобытий <i>m</i>
                                </div>
                                <div className="value">
                                    <TextField type="number" min={1} max={200} step={1} onChange={onChamgeNumberOfMediaEvents} value={String(numberOfMediaEvents)} />
                                </div>
                            </div>
                            {(numberOfMediaEvents <= 0 || numberOfMediaEvents > 200) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Число медиасобытий <i>m</i> не может быть меньше 1 и больше 200
                                </MessageBar>
                            ) : null}

                            <div className="table-row">
                                <div className="name">
                                    Число контактов с медиасобытиями <i>f</i>
                                </div>
                                <div className="value">
                                    <TextField type="number" min={1} step={1} onChange={onChangeNumberOfMediaContacts} value={String(numberOfMediaContacts)} />
                                </div>
                            </div>
                            {(numberOfMediaContacts < 1 || numberOfMediaContacts > numberOfMediaEvents) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Число контактов с медиа <i>f</i> не может быть меньше 1 и больше чем Число медиасобытий <i>m</i> ({numberOfMediaEvents})
                                </MessageBar>
                            ) : null}


                        </div>
                    </div>

                    {answer != null && checkingValues() ? (
                        <div className="input-body answer">
                            <div style={{ marginRight: 0 }} className="subtitle">Результат: </div>
                            <div className="subtitle"><i>g(f)</i> = {String(answer.toFixed(1))} %</div>
                        </div>
                    ) : null}

                </div>
            </div>
        )
    }

export default CalculationOfFrequencyCoverageDistribution;