import axios from "axios";
import { FC, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { apiUrl } from "../../constant/routs";
import LKDesctop from "./desktop";
import { useUser } from "../../store/useUser";
import { useNavigate } from "react-router-dom";
import LkMobile from "./mobile";

export interface DataLK {
    banner: string
}

const LK: FC = () => {
    const [data, setData] = useState<DataLK>()
    const [user, token] = useUser(state => [state.user, state.token])
    const nav = useNavigate()

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        if (token === null) nav('/')
    }, [token])

    if (data && token) {
        if (!isMobile) {
            return (
                <>
                    <LKDesctop data={data} user={user} token={token} />
                </>
            )
        } else {
            return (
                <>
                    <LkMobile data={data} user={user} token={token} />
                </>
            )
        }
    }

    return (
        <>

        </>
    )

    async function getData() {
        try {
            const { data } = await axios.get(`${apiUrl}api/home/tasks/lk?v=${Math.random()}`)
            setData(data)
        } catch {

        }
    }
}

export default LK