import create from "zustand";

export type TUser = {
    email: string;
    name: string;
    surname: string;
    phone: string;
}

export const userDefaultValues: TUser = {
    email: '',
    name: '',
    surname: '',
    phone: ''
}

interface UserStore {
    user: TUser | undefined;
    setUser: (user: TUser | undefined) => void;
}

export const useUser = create<UserStore>((set, get) => ({
    user: undefined,
    setUser: user => set({user}),
}))