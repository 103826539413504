import { SectionType } from "../../store/types";
import { FC, useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { apiUrl } from "../../constant/routs";
import HomeDesktop from "./decktop";
import HomeMobile from "./mobile";

export interface HomeData {
    section: SectionType[]
    title: string,
    subtitle: string,
    banner: string
    decription: string
}

const Home: FC = () => {
    const [data, setData] = useState<HomeData>({
        section: [],
        title: "",
        subtitle: "",
        banner: "",
        decription: ""
    })

    useEffect(() => {
        getData()
    }, [])
    if(data) {
        if (!isMobile) {
            return (
                <>
                    <HomeDesktop data={data} />
                </>
            )
        } else {
            return (
                <>
                    <HomeMobile data={data}/>
                </>
            )
        }
    }

    return (
        <>
            что-то пошло не так....
        </>
    )

    async function getData() {
        try {
            const { data } = await axios.get(`${apiUrl}api/home/tasks?v=${Math.random()}`, {
                onDownloadProgress: (progressEvent) => {
                    console.log(progressEvent);
                },
            })
            setData(data)
        } catch {

        }
    }
}

export default Home