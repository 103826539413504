import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { TBooks, TCatalog } from "../../@entities/type";

const Block = styled.ul`
    margin: 0;
    list-style-type: none;
    padding: 0;
    li {
        padding-left: 20px;
        a{
            text-decoration: none;
        }
    }
`

const SideBarLink = styled(Link)`
    text-decoration: none;
    display: block;
    padding: 10px 10px 10px 20px;
    color: #000;

    &:hover{
        color: #0085eb;
    }

    &.active-link {
        color: #0085eb;
    }
`

const SidebarBook: FC<{
    items: TBooks[]
    url: string | undefined
}> = ({
    items, url
}) => {
        return (
            <Block>
                {items.map((i: TBooks, idx: number) => {
                    return (
                        <li key={idx}>
                            <SideBarLink to={`/pdf/book/${i.url}`} className={url === i.url? 'active-link':'undefind'}>
                                {i.name}
                            </SideBarLink>
                        </li>
                    )
                })}
            </Block>
        )
    }

export default SidebarBook