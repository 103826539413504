import { FontWeights, getTheme, IButtonStyles, IconButton, IIconProps, IStackProps, mergeStyleSets, Modal, PrimaryButton } from "@fluentui/react";
import axios from "axios";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { SectionType } from "../@entities/type";
import { ErrorAlert } from "../@library/interaction";
import About from "../components/base/about";
import Banner from "../components/base/banner";
import Card from "../components/base/card";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import LayoutDefault from "../components/layouts/layout";
import { useBoolean } from "@fluentui/react-hooks";


const NewsContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;

    .news-image {
        height: 350px;
        width: 40%;

        background-image: url(../imgs/home.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 20px;
    }

    .news-text {
        width: calc(60% - 40px);
        font-size: 20px;
        line-height: 1.5;

        .news-title {
            text-decoration: none;
            color: rgb(0, 120, 212);
            line-height: 1.5;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;

            display: flex;
            align-items: center;
        }

        .news-more {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }

        .news-date {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }
    }
`


const BottomBuuton = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;
`

const Home: FC<{

}> = ({

}) => {
        const navigate = useNavigate()
        const [section, setSection] = useState<SectionType[]>([]);
        const [title, setTitle] = useState<string | null>();
        const [subTitle, setSubtitle] = useState<string | null>();
        const [bunner, setBunner] = useState<string>('');
        const [description, setDescription] = useState<string | null>();
        const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(true)

        const { empty } = useParams();

        function hideModalFunktion() {
            hideModal()
        }

        const onGetData = async () => {
            try {
                const response = await axios({
                    method: "GET",
                    headers: { 'Cache-Control': `no-cache` },
                    url: "/api/home/tasks",
                });

                setSection(response.data.section);
                setTitle(response.data.title);
                setSubtitle(response.data.subtitle);
                setBunner(response.data.banner);
                setDescription(response.data.decription);
            } catch (error: any) {
                const { response } = error;
                try {
                    console.log(response.data, 'error');

                } catch (error: any) {
                    ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
                }
            }
        }

        useEffect(() => {
            navigate('/');
        }, [empty])

        useLayoutEffect(() => {
            onGetData()
        }, [])




        return (
            <LayoutDefault>
                <Banner title={title} subtitle={subTitle} backgound={bunner}>
                </Banner>
                <Container>
                    <About title={'О проекте'} subtitle={description ? description : ''} />
                    <div>
                        <Row direction="column" style={{ paddingTop: 30 }}>
                            {section.map((item, idx) => {
                                return (
                                    <Card >
                                        <NewsContainer>
                                            <div className="news-image" style={{ backgroundImage: `url(${item.image})` }}></div>
                                            <div className="news-text">
                                                <Link to={item?.link? item?.link: '/'} className="news-title">{item.name}</Link>
                                                <div className="news-about">{item.description}</div>
                                            </div>
                                        </NewsContainer>
                                    </Card>
                                )
                            })}
                        </Row>
                    </div>
                </Container>
                {/* <Modal
                    titleAriaId={'title'}
                    isOpen={isModalOpen}
                    onDismiss={hideModalFunktion}
                    isBlocking={false}
                    containerClassName={contentStyles.container}
                >
                    <div className={contentStyles.header}>
                        <span id={'title'}>Уведомление</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={hideModalFunktion}
                        />
                    </div>
                    <div className={contentStyles.body}>
                        Сайт находится в разработке, поэтому некоторый функционал может не работать или работать не корректно.
                    </div>
                </Modal> */}
            </LayoutDefault>
        )
    }

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'stretch',
        },
        header: {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
          },
        body: {
        //   flex: '4 4 auto',
          padding: '0 24px 24px 24px',
        //   overflowY: 'hidden',
          selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
          },
        },
      });
      const stackProps: Partial<IStackProps> = {
        horizontal: true,
        tokens: { childrenGap: 40 },
        styles: { root: { marginBottom: 20 } },
      };
      const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
          color: theme.palette.neutralPrimary,
          marginLeft: 'auto',
          marginTop: '4px',
          marginRight: '2px',
        },
        rootHovered: {
          color: theme.palette.neutralDark,
        },
      };

export default Home