import { FC, useEffect, useLayoutEffect, useState } from "react";
import { BookCat, Menu } from "../../store/types";
import Layout from "../../components/layout";
import HeaderMobile from "../../components/layout/headerMobile";
import BunnerMobile from "../../components/mobile/bunnerMobile";
import { Box, Button, Card, Center, Container, Modal, Stack, Text, Image } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../constant/routs";
import { useUser } from "../../store/useUser";
import PDF from "../Doc/mobile/pdf";
import moment from "moment";
import { useAtom } from "jotai";
import { openAuthMobile } from "../../components/mobile/auth";

interface Props {
    banner: string
    parent: Menu
    child?: Menu,
    catArr: BookCat[]
    categoryes: BookCat[]
    name: string,
    currentCat: BookCat | undefined
}

const ArticlesAndBooksMobile: FC<Props> = ({
    banner, parent, child, name, categoryes, currentCat
}) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [openedPDF, setOpenedPDF] = useState<boolean>(false)
    const [bookCat, setBookCat] = useState<BookCat[]>([])
    const [pdf, setPdf] = useState<string | null>(null)
    const [title, setTitle] = useState<string>("")
    const [user, token] = useUser(state => [state.user, state.token])
    const [_, setOpened] = useAtom(openAuthMobile);

    useLayoutEffect(() => {
        close()
    }, [child])

    const nav = useNavigate()
    useEffect(() => {
        getData()
    }, [currentCat?.url])
    return (
        <>
            <Layout>
                <HeaderMobile />
                <BunnerMobile banner={banner} />
                <Container pos="relative" top={-25}>
                    <Center>
                        <Button h={50} w="100%" color="teal" fw={500} tt="uppercase" onClick={() => open()}>
                            КНИГИ И СТАТЬИ
                        </Button>
                    </Center>
                    <Text component="h1" fz={20} fw="bold" mb={10} mt={30} tt="uppercase" ta="center">
                        {name}
                    </Text>
                    {currentCat?.description && (
                        <Box w="100%" p={15} bg="#fff" style={{
                            boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                            borderRadius: 10
                        }}>
                            <Box dangerouslySetInnerHTML={{
                                __html: currentCat.description
                            }} />
                        </Box>
                    )}
                    <Stack spacing={10} mt={25}>
                        {bookCat.map((book, idx) => {
                            return (
                                <Card shadow="sm" padding={18} radius={10} withBorder key={idx} bg="#dfe2eb">
                                    {book.image && (
                                        <Card.Section>
                                            <Image
                                                src={`${apiUrl}${book.image}`}
                                                height={140}
                                                alt="Norway"
                                            />
                                        </Card.Section>
                                    )}
                                    <Box pt={18}>
                                        <Text weight="bold" fz={21}>
                                            {book.name}
                                        </Text>
                                    </Box>
                                    <Box pt={18} sx={{
                                        "p": {
                                            margin: 0
                                        }
                                    }} dangerouslySetInnerHTML={{
                                        __html: book.description
                                    }} />
                                    <Box pt={18}>
                                        <Button h={50} w="100%" color="teal" fw={500} tt="uppercase" disabled={!book.pdf} onClick={() => {
                                            if (book.pdf) {
                                                setTitle(book.name)
                                                setPdf(book.pdf)
                                                setOpenedPDF(true)
                                            }
                                        }}>
                                            читать
                                        </Button>
                                    </Box>
                                </Card>
                            )
                        })}
                    </Stack>
                </Container>
            </Layout>
            <Modal.Root
                fullScreen
                opened={opened}
                size={555}
                onClose={close}>
                <Modal.Overlay bg="rgba(0, 0, 0, 0.5)" blur="10px" />
                <Modal.Content bg="#00af80">
                    <Box px={30} pt={18}>
                        <Modal.CloseButton left={-10} c="#fff" size="xl" />
                        <Text fw="bold" mt={15} mb={15} fz={20} c="#fff">
                            КНИГИ И СТАТЬИ
                        </Text>
                        <Box pb={15} style={{
                            borderTop: "solid 1px #4ad295",
                        }} />
                    </Box>
                    <Modal.Body mt={10} pos="relative">
                        <Sidebar items={categoryes} url={child ? child.url : ""} />
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
            <Modal.Root
                fullScreen
                opened={openedPDF}
                size={555}
                onClose={() => setOpenedPDF(false)}>
                <Modal.Overlay bg="rgba(0, 0, 0, 0.5)" blur="10px" />
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                        <Modal.CloseButton />
                    </Modal.Header>
                    <Modal.Body pos="relative">
                        {token && moment(user?.subscribe_to).unix() <= moment().unix() ? (
                            <Box w="100%" p={50}>
                                <Text mt={20}>
                                    Для доступа к тексту книги необходимо оформить подписку
                                </Text>
                                <Button w="100%" h={50} mt={20} color="teal" styles={{
                                    label: {
                                        textTransform: "uppercase",
                                        fontWeight: 500,
                                        fontSize: 14
                                    }
                                }} onClick={() => {
                                    setOpenedPDF(false)
                                    nav('/lk')
                                }}>
                                    оформить подписку
                                </Button>
                            </Box>
                        ) : !token ? (
                            <Box w="100%" p={50}>
                                <Text mt={20}>
                                    Для доступа к тексту книги необходимо авторизоваться
                                </Text>
                                <Button w="100%" h={50} mt={20} color="teal" styles={{
                                    label: {
                                        textTransform: "uppercase",
                                        fontWeight: 500,
                                        fontSize: 14
                                    }
                                }} onClick={() => {
                                    setOpenedPDF(false)
                                    setOpened(true)
                                }}>
                                    ЧИТАТЬ КНИГУ
                                </Button>
                            </Box>
                        ) : token && user?.tariff?.books === 0 ? (
                            <Box w="100%" p={50}>
                                <Text mt={20}>
                                    Для доступа к тексту книги необходимо оформить подписку
                                </Text>
                                <Button w="100%" h={50} mt={20} color="teal" styles={{
                                    label: {
                                        textTransform: "uppercase",
                                        fontWeight: 500,
                                        fontSize: 14
                                    }
                                }} onClick={() => {
                                    setOpenedPDF(false)
                                    nav('/tariff')
                                }}>
                                    Выбрать тариф
                                </Button>
                            </Box>
                        ) : pdf && opened && token && user?.tariff?.books === 1 && <PDF pdf={pdf} />}
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
        </>
    )
    async function getData() {
        if (currentCat?.url) {
            try {
                const { data } = await axios.get(`${apiUrl}api/book_by_cat/${currentCat.url}?v=${Math.random()}`)
                setBookCat(data)
            } catch {

            }
        }
    }
}

const Sidebar: FC<{
    items: BookCat[]
    url: string
}> = ({ items, url }) => {
    return (
        <Stack ml={15} spacing={0}>
            {items.map((item, idx) => {
                return (
                    <Box key={idx} sx={{
                        "a": {
                            textDecoration: "none",
                            color: "#fff",
                            fontWeight: url.replace("articles-and-books/", "") === item.url ? 700 : "normal",
                        }
                    }}>
                        <Link to={"/articles-and-books/" + item.url}>
                            <Box p={10} fz={14} sx={{
                                backgroundColor: url.replace("boarticles-and-booksok/", "") === item.url ? "#4ad295" : "",
                                borderRadius: 8,
                                ":hover": {
                                    backgroundColor: "#dfe2eb",
                                }
                            }}>
                                {item.name}
                            </Box>
                            {item.children.length > 0 && <Sidebar items={item.children} url={url} />}
                        </Link>
                    </Box>
                )
            })}
        </Stack>
    )
}

export default ArticlesAndBooksMobile