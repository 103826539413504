import { FC, useCallback, useEffect } from "react";
import Layout from "../../components/layout";
import { Box, Button, Card, Container, Flex, Grid, InputBase, PasswordInput, Text, TextInput } from "@mantine/core";
import BunnerDeskt from "../../components/desktop/bunner";
import { DataLK } from ".";
import BreadcrumbsMod from "../../components/desktop/breadcrumpsMod";
import { IMaskInput } from "react-imask";
import { useForm } from "@mantine/form";
import axios, { AxiosError } from "axios";
import { apiUrl } from "../../constant/routs";
import { User, useUser } from "../../store/useUser";
import FooterDesktop from "../../components/layout/footer";
import TariffItem from "./tariffItem";

const LKDesctop: FC<{
    data: DataLK
    user: User | null
    token: string | null
}> = ({
    data, user, token
}) => {
        const form = useForm({
            initialValues: {
                email: user?.email,
                name: user?.name,
                surname: user?.surname,
                phone: user?.phone,
            }
        })

        const formPassword = useForm({
            initialValues: {
                password: "",
                passwordConfirm: "",
                password_old: ""
            }
        })

        const handleEdit = useCallback(async () => {
            try {
                const { data } = await axios.post(`${apiUrl}api/personal/changes/data`, form.values, token ? {
                    headers: { "Authorization": `Bearer ${token}` }
                } : undefined)
                useUser.setState({
                    token: data.details.token,
                })
                window.location.href = window.location.href;
            } catch (error) {
                alert((error as AxiosError).response?.data)
                console.error(error)
            }
        }, [form.values])

        const handleChangePassword = useCallback(async () => {
            try {
                const { data } = await axios.post(`${apiUrl}api/personal/changes/password`, formPassword.values, token ? {
                    headers: { "Authorization": `Bearer ${token}` }
                } : undefined)
                formPassword.reset()
                alert(data.message)
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.data) {
                    console.error(error.response.data.message);
                    alert(error.response.data.message)
                }
            }
        }, [formPassword.values])

        useEffect(() => {
            if (user) form.setValues(user)
        }, [user])

        return (
            <Layout>
                <Box pos="relative">
                    <BunnerDeskt banner={data.banner} />
                    <Container size="80rem" pt={30}>
                        <BreadcrumbsMod parent={{
                            name: "Личный кабинет",
                            url: "/lk"
                        }} child={undefined} />
                    </Container>
                    <Container size="80rem" pt={40} pb={70}>
                        <Grid>
                            <Grid.Col span={9}>
                                <Text component="h1" fz={30} fw="bold" mb={30} mt={0}>
                                    Личный кабинет
                                </Text>
                                <Card radius={0} bg="#dfe2eb" pt={30} pb={50} px={45}>
                                    <Text fw={600} fz={24} mb={10}>
                                        Данные пользователя
                                    </Text>
                                    <form onSubmit={form.onSubmit(() => handleEdit())}>
                                        <Flex direction="column" align="center">
                                            <TextInput
                                                w="100%"
                                                label="Адрес электронной почты"
                                                withAsterisk
                                                defaultValue={form.values.email}
                                                name="email"
                                                h={40}
                                                mb={30}
                                                onChange={(e: { target: { value: string; }; }) => form.setFieldValue("email", e.target.value)}
                                                styles={{
                                                    input: {
                                                        height: 42
                                                    },
                                                    label: {
                                                        fontSize: 12,
                                                        fontWeight: "normal"
                                                    }
                                                }}
                                            />
                                            <TextInput
                                                w="100%"
                                                label="Имя"
                                                withAsterisk
                                                name="name"
                                                defaultValue={form.values.name}
                                                h={40}
                                                mb={30}
                                                onChange={(e: { target: { value: string; }; }) => form.setFieldValue("name", e.target.value)}
                                                styles={{
                                                    input: {
                                                        height: 42
                                                    },
                                                    label: {
                                                        fontSize: 12,
                                                        fontWeight: "normal"
                                                    }
                                                }}
                                            />
                                            <TextInput
                                                w="100%"
                                                label="Фамилия"
                                                withAsterisk
                                                name="surname"
                                                defaultValue={form.values.surname}
                                                h={40}
                                                mb={30}
                                                onChange={(e: { target: { value: string; }; }) => form.setFieldValue("surname", e.target.value)}
                                                styles={{
                                                    input: {
                                                        height: 42
                                                    },
                                                    label: {
                                                        fontSize: 12,
                                                        fontWeight: "normal"
                                                    }
                                                }}
                                            />
                                            <InputBase
                                                w="100%"
                                                label="Телефон"
                                                defaultValue={form.values.phone}
                                                component={IMaskInput}
                                                mask="+7 (000) 000-0000"
                                                h={40}
                                                name="phone"
                                                styles={{
                                                    input: {
                                                        height: 42
                                                    },
                                                    label: {
                                                        fontSize: 12,
                                                        fontWeight: "normal"
                                                    }
                                                }}
                                                onChange={e => form.setFieldValue("phone", (e.target as HTMLTextAreaElement).value)}
                                            />
                                            <Box w="100%" mt={50}>
                                                <Button type="submit" w="50%" h={50} color="teal" styles={{
                                                    label: {
                                                        textTransform: "uppercase",
                                                        fontWeight: 500,
                                                        fontSize: 14
                                                    }
                                                }}>
                                                    Сохранить
                                                </Button>
                                            </Box>
                                        </Flex>
                                    </form>
                                </Card>
                                <Card radius={0} bg="#dfe2eb" mt={30} pt={30} pb={50} px={45}>
                                    <Text fw={600} fz={24} mb={10}>
                                        Смена пароля
                                    </Text>
                                    <form onSubmit={form.onSubmit(() => handleChangePassword())}>
                                        <Flex direction="column" align="center">
                                            <PasswordInput
                                                w="100%"
                                                label="Новый пароль"
                                                withAsterisk
                                                name="password"
                                                h={40}
                                                mb={30}
                                                onChange={(e: { target: { value: string; }; }) => formPassword.setFieldValue("password", e.target.value)}
                                                styles={{
                                                    input: {
                                                        height: 42
                                                    },
                                                    label: {
                                                        fontSize: 12,
                                                        fontWeight: "normal"
                                                    }
                                                }}
                                            />
                                            <PasswordInput
                                                w="100%"
                                                label="Повторите новый пароль"
                                                withAsterisk
                                                name="passwordConfirm"
                                                h={40}
                                                mb={30}
                                                onChange={(e: { target: { value: string; }; }) => formPassword.setFieldValue("passwordConfirm", e.target.value)}
                                                styles={{
                                                    input: {
                                                        height: 42
                                                    },
                                                    label: {
                                                        fontSize: 12,
                                                        fontWeight: "normal"
                                                    }
                                                }}
                                            />
                                            <Box w="100%" mt={20}>
                                                <Button type="submit" w="50%" h={50} color="teal" styles={{
                                                    label: {
                                                        textTransform: "uppercase",
                                                        fontWeight: 500,
                                                        fontSize: 14
                                                    }
                                                }}>
                                                    Изменить
                                                </Button>
                                            </Box>
                                        </Flex>
                                    </form>
                                </Card>
                            </Grid.Col>
                            <Grid.Col span={3}>
                                {user?.tariff && (
                                    <>
                                        <Text ta="center" fz={24} fw="bold" mb={10} mt={70}>
                                            Ваш тариф
                                        </Text>
                                        <TariffItem item={user.tariff} />
                                    </>
                                )}
                            </Grid.Col>
                        </Grid>
                    </Container>
                    <FooterDesktop />
                </Box>
            </Layout>
        )
    }

export default LKDesctop