import {FC, useEffect, useLayoutEffect, useState} from "react";
import {ITask, Menu} from "../../../store/types";
import Layout from "../../../components/layout";
import HeaderMobile from "../../../components/layout/headerMobile";
import BunnerMobile from "../../../components/mobile/bunnerMobile";
import {Box, Button, Center, Container, Modal, Text} from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import Sidebar from "./components/calcSidebar";
import CalculationOfAudienceCoverageOfOneMediaRequest from "../calcs/calculationOfAudienceCoverageOfOneMediaRequest";
import MultipleMediaFudience from "../calcs/multipleMediaFudience";
import axios from "axios";
import {apiUrl} from "../../../constant/routs";
import {useUser} from "../../../store/useUser";
import moment from "moment";
import {openAuthMobile} from "../../../components/mobile/auth";
import {useAtom} from "jotai";
import {useNavigate} from "react-router-dom";
import CalculationOfFrequencyCoverageDistribution from "../calcs/calculationOfFrequencyCoverageDistribution";
import CalculationOfPrivateEffectiveAudienceReachAndEffectiveNumberOfContacts from "../calcs/calculationOfPrivateEffectiveAudienceReachAndEffectiveNumberOfContacts";
import MeasurementErrorCalculation from "../calcs/measurementErrorCalculation";
import EffectiveNumberOfPlacementsValues from "../calcs/effectiveNumberOfPlacementsValues";
import DurationOfAdvertisingSilence from "../calcs/durationOfAdvertisingSilence";
import CalculationOfTheRatioOfPercentagePointsOfGrowthAndRating from "../calcs/calculationOfTheRatioOfPercentagePointsOfGrowthAndRating";
import SampleSizeCalculation from "../calcs/sampleSizeCalculation";
import SampleSizeCalculationMin from "../calcs/sampleSizeCalculationMin";

interface Props {
    banner: string
    child?: Menu
    calcs: ITask[]
    url: string
    name: string,
    description: string
}

const CalcPageMobile: FC<Props> = ({
    banner, calcs, url, name, description
}) => {
    const [opened, {open, close}] = useDisclosure(false);
    const [calcId, setCalcId] = useState<number>()
    const [user, token] = useUser(state => [state.user, state.token])
    const [dataModal, setDataModal] = useState<string>()
    const [_, setOpened] = useAtom(openAuthMobile);
    const nav = useNavigate()


    useEffect(() => {
        getInfo()
    }, [calcId])
    useLayoutEffect(() => {
        close()
    }, [url])
    return (
        <>
            <Layout>
                <HeaderMobile />
                <BunnerMobile banner={banner} />
                <Container pos="relative" top={-25}>
                    <Center>
                        <Button h={50} w="100%" color="teal" fw={500} tt="uppercase" onClick={() => open()}>
                            ВСЕ КАЛЬКУЛЯТОРЫ
                        </Button>
                    </Center>
                    {token && moment(user?.subscribe_to).unix() >= moment().unix() ? (
                        <Box sx={{
                            "span": {
                                fontWeight: "normal",
                                fontStyle: "italic"
                            }
                        }}>
                            {url === "1" ? (
                                <>
                                    <Box fz={20} fw="bold" my={20} mt={0} ta="center">
                                        Охват медиа G(m)
                                    </Box>
                                    <CalculationOfAudienceCoverageOfOneMediaRequest setCalcId={setCalcId} />
                                </>
                            ) : url === "2" ? (
                                <>
                                    <Box fz={20} fw="bold" my={20} mt={0} ta="center">
                                        Охват группы медиа G
                                    </Box>
                                    <MultipleMediaFudience setCalcId={setCalcId} />
                                </>
                            ) : url === "3" ? (
                                <>
                                    <Box fz={20} fw="bold" my={20} mt={0} ta="center">
                                        Спектр охвата g(f)
                                    </Box>
                                    <CalculationOfFrequencyCoverageDistribution setCalcId={setCalcId} />
                                </>
                            ) : url === "4" ? (
                                <>
                                    <Box fz={20} fw="bold" my={20} mt={0} ta="center">
                                        Эффективный охват G(fэф+)
                                    </Box>
                                    <CalculationOfPrivateEffectiveAudienceReachAndEffectiveNumberOfContacts setCalcId={setCalcId} />
                                </>
                            ) : url === "9" ? (
                                <>
                                    <Box fz={20} fw="bold" my={20} mt={0} ta="center">
                                        Эффективное число размещений mэф
                                    </Box>
                                    <EffectiveNumberOfPlacementsValues setCalcId={setCalcId} />
                                </>
                            ) : url === "10" ? (
                                <>
                                    <Box fz={20} fw="bold" my={20} mt={0} ta="center">
                                        Длительность рекламного молчания T
                                    </Box>
                                    <DurationOfAdvertisingSilence setCalcId={setCalcId} />
                                </>
                            ) : url === "5" ? (
                                <>
                                    <Box fz={20} fw="bold" my={20} mt={0} ta="center">
                                        Стоимость охвата аудитории
                                    </Box>
                                    <CalculationOfTheRatioOfPercentagePointsOfGrowthAndRating setCalcId={setCalcId} />
                                </>
                            ) : url === "6" ? (
                                <>
                                    <Box fz={20} fw="bold" my={20} mt={0} ta="center">
                                        Объем выборки n(Δ, γ, R, N)
                                    </Box>
                                    <SampleSizeCalculation setCalcId={setCalcId} />
                                </>
                            ) : url === "7" ? (
                                <>
                                    <Box fz={20} fw="bold" my={20} mt={0} ta="center">
                                        Объем выборки n(Rmin, γ, N)
                                    </Box>
                                    <SampleSizeCalculationMin setCalcId={setCalcId} />
                                </>
                            ) : url === "8" ? (
                                <>
                                    <Box fz={20} fw="bold" my={20} mt={0} ta="center">
                                        Погрешность измерения Δ(n, N, γ, R), Rmin(n, N, γ)
                                    </Box>
                                    <MeasurementErrorCalculation setCalcId={setCalcId} />
                                </>
                            ) : url === "" && (
                                <Box component="h1" fz={20} fw="bold" mb={10} mt={0} ta="center">
                                    О калькуляторах
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Center mt={20} mb={20} w="100%" py={40} px={40} bg="#dfe2eb" sx={{
                            boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                            flexDirection: "column",
                            borderRadius: 10,
                            "span": {
                                fontStyle: "normal"
                            }
                        }}>
                            {token === null? (
                                <>
                                    <Text fw="bold" fz={18} mb={20}>
                                        Для работы с калькулятором нужно авторизоваться
                                    </Text>
                                    <Button color="teal" size="md" radius="xs" onClick={() => setOpened(true)}>
                                        Авторизоваться
                                    </Button>
                                </>
                            ): undefined}
                            {user?.subscribe_to === null || moment(user?.subscribe_to).unix() <= moment().unix() ? (
                                <>
                                    <Text fw="bold" fz={18} mb={20}>
                                        Работа с калькулятором доступна по подписке
                                    </Text>
                                    <Button color="teal" size="md" radius="xs" onClick={() => nav("/lk")}>
                                        Оформить подписку
                                    </Button>
                                </>
                            ): undefined}
                        </Center>
                    )}
                    {description && (
                        <Box w="calc(100% - 50px)" p={15} mx={25} bg="#fff" sx={{
                            boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                            borderRadius: 10,
                            position: "relative",
                            top: token && moment(user?.subscribe_to).unix() >= moment().unix() ? -100 : 0
                        }}>
                            <Box sx={{
                                "*": {
                                    fontSize: 14
                                },
                                "img": {
                                    height: "auto !important",
                                    width: "100% !important"
                                },
                                "a": {
                                    color: "#12b886"
                                }
                            }} dangerouslySetInnerHTML={{
                                __html: description
                            }} />
                        </Box>
                    )}
                </Container>
            </Layout>
            <Modal.Root
                fullScreen
                opened={opened}
                size={555}
                onClose={close}>
                <Modal.Overlay bg="rgba(0, 0, 0, 0.5)" blur="10px" />
                <Modal.Content bg="#00af80">
                    <Box px={30} pt={18}>
                        <Modal.CloseButton left={-10} c="#fff" size="xl" />
                        <Text fw="bold" mt={15} mb={15} fz={20} c="#fff">
                            Калькуляторы
                        </Text>
                        <Box pb={15} style={{
                            borderTop: "solid 1px #4ad295",
                        }} />
                    </Box>
                    <Modal.Body mt={10} pos="relative">
                        <Sidebar calcs={calcs} url={url} />
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
            <Modal fullScreen opened={calcId !== undefined} onClose={() => setCalcId(undefined)} title="Справка">
                <Box dangerouslySetInnerHTML={dataModal ? {__html: dataModal} : undefined} />
            </Modal>
        </>
    )

    async function getInfo() {
        try {
            const {data} = await axios.get(`${apiUrl}api/description/${calcId}`, token ? {
                headers: {"Authorization": `Bearer ${token}`}
            } : undefined)
            setDataModal(data.text.help)
        } catch (error) {

        }
    }
}

export default CalcPageMobile