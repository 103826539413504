import { FC, useLayoutEffect } from "react"
import HeaderMobile from "../../../components/layout/headerMobile"
import { ITask, Menu } from "../../../store/types"
import BunnerMobile from "../../../components/mobile/bunnerMobile"
import { Box, Button, Center, Container, Modal, Text } from "@mantine/core"
import Layout from "../../../components/layout"
import { useDisclosure } from "@mantine/hooks"
import Sidebar from "./components/calcSidebar"

interface Props {
    banner: string
    child?: Menu
    calcs: ITask[]
    url: string
    name: string,
    description: string
}

const CalcHomeMobile: FC<Props> = ({ banner, child, calcs, url, name, description }) => {
    const [opened, { open, close }] = useDisclosure(false);
    useLayoutEffect(() => {
        close()
    }, [url])
    return (
        <>
            <Layout>
                <HeaderMobile />
                <BunnerMobile banner={banner} />
                <Container pos="relative" top={-25}>
                    <Center>
                        <Button h={50} w="100%" color="teal" fw={500} tt="uppercase" onClick={() => open()}>
                            ВСЕ КАЛЬКУЛЯТОРЫ
                        </Button>
                    </Center>
                    <Text component="h1" fz={20} fw="bold" mb={10} mt={30} tt="uppercase" ta="center">
                        {name}
                    </Text>
                    {description && (
                        <Box w="100%" p={15} bg="#fff" sx={{
                            boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                            borderRadius: 10
                        }}>
                            <Box sx={{
                                "*": {
                                    fontSize: 14
                                },
                                "img": {
                                    height: "auto !important",
                                    width: "100% !important"
                                },
                                "a": {
                                    color: "#12b886"
                                }
                            }} dangerouslySetInnerHTML={{
                                __html: description
                            }} />
                        </Box>
                    )}
                </Container>
            </Layout>
            <Modal.Root
                fullScreen
                opened={opened}
                size={555}
                onClose={close}>
                <Modal.Overlay bg="rgba(0, 0, 0, 0.5)" blur="10px" />
                <Modal.Content bg="#00af80">
                    <Box px={30} pt={18}>
                        <Modal.CloseButton left={-10} c="#fff" size="xl" />
                        <Text fw="bold" mt={15} mb={15} fz={20} c="#fff">
                            Калькуляторы
                        </Text>
                        <Box pb={15} style={{
                            borderTop: "solid 1px #4ad295",
                        }} />
                    </Box>
                    <Modal.Body mt={10} pos="relative">
                        <Sidebar calcs={calcs} url={url} />
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
        </>
    )
}

export default CalcHomeMobile