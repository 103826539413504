import axios from "axios";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import { Container } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../@library/interaction";
import { useToken } from "../@store/useAuth";
import { useUser } from "../@store/useUser";
import About from "../components/base/about";
import Banner from "../components/base/banner";
import EditUser from "../components/lk/editUser";
import LayoutDefault from "../components/layouts/layout";
import MySubs from "../components/lk/mySubs";
import NewSub from "../components/lk/newSub";

export type TSub = {
    tarifName: string
    available: string
    description: string
}


const Lk: FC<{}> = ({ }) => {
    const [title] = useState<string | null>();
    const [subTitle] = useState<string | null>();
    const [bunner, setBunner] = useState<string>('');

    const navigate = useNavigate()
    const [user] = useUser(state => [state.user])
    const [token] = useToken(state => [state.token])
    const [subs, setSubs] = useState<TSub>()

    const onGetData = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: "/api/personal/getUserSubs",
                headers: { 'Authorization': `Bearer ${token}`, 'Cache-Control': `no-cache` },
            });
            
            if(!response.data.notSubs) setSubs(response.data)
        } catch (error: any) {
            const { response } = error;
            try {
                console.log(response.data);

            } catch (error: any) {
                ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
            }
        }
    }

    useEffect(() => {
        if (token && user) onGetData();
        else navigate('/')        
    }, [])

    return (
        <LayoutDefault>
            <Banner title={title} subtitle={subTitle} backgound={bunner}>
            </Banner>
            <Container>
                <About title={'Личный кабинет'} subtitle={''} />
                <div style={{ marginTop: 30 }}>
                    {user && <EditUser initialValues={user} token={token} />}
                    {user && subs != undefined ? <MySubs sub={subs} />: <NewSub token={token} />}
                </div>
            </Container>
        </LayoutDefault>
    )
}

export default Lk