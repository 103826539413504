import { IconButton, Link } from "@fluentui/react";
import { FC } from "react"
import { Container } from "react-bootstrap";
import { Col, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useToken } from "../../@store/useAuth";
import { useGlobal } from "../../@store/useGlobal";
import { useUser } from "../../@store/useUser";


const HeaderTag = styled.header`
    position: absolute;
    top: 0;
    left: 0;
    height: 55px;
    width: 100%;
    color: #fff;
    border-bottom: 1px solid #dfe4e9;

    .header-row{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
`

const Head = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .title {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 24px;
        font-weight: 600;
        color: rgb(50, 49, 48);
    }

    .header-links {
        width: 40%;
        min-width: 850px;
        display: flex;
        justify-content: space-around;

        button {
            height: 100%;
            padding: 7px;
            border-radius: 10px;
            color: rgb(50, 49, 48);
            font-weight: 700;
            
            &.active {
                background-color: rgba(0, 121, 214, 0.2);
                color: rgb(0, 121, 214);
            }
            &:hover {
                text-decoration: none;
                color: rgb(0, 121, 214);
            }
        }
    }

    .logout {
        display: flex;
        justify-content: end;
    }
`

const Logo = styled.div<{
    url: string
}>`
    background-image: url(${props => props.url});
    height: 60px;
    width: 220px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`

const Header: FC<{
    children: React.ReactNode
    logo: string
}> = ({ children, logo }) => {
    const [token, setToken] = useToken(state => [state.token, state.setToken])
    const [user, setUser] = useUser(state => [state.user, state.setUser])
    const [showAuth, setShowAuth] = useGlobal(state => [state.showAuth, state.setShowAuth])

    const navigate = useNavigate()
    const logOut = () => {
        localStorage.removeItem("token");
        setToken(null)
        setUser(undefined)
        window.location.href = window.location.href;
    }

    return (
        <HeaderTag>
            <div className="header-row">
                {/* <Col xs={12}> */}
                    <Head>
                        <div className="title">
                            <Link onClick={() => navigate('/')}>
                                <Logo url={logo}></Logo>
                            </Link>
                        </div>
                        <div className="header-links">
                            {children}
                        </div>
                        <div className="logout">
                            {!user ? <IconButton iconProps={{ iconName: 'AccountBrowser' }} title="Вход" ariaLabel="Вход" onClick={() => setShowAuth(true)} /> :
                                <Link onClick = {() => navigate('/lk')}>
                                    {user.name + ' ' + user.surname.slice(0, 1)+'.'}
                                </Link>}
                            {user && <IconButton iconProps={{ iconName: 'SignOut' }} title="Выход" ariaLabel="Выход" onClick={logOut} />}
                        </div>
                    </Head>
                {/* </Col> */}
            </div>
        </HeaderTag>
    )
}

export default Header;