import { create } from "zustand"

export interface User {
    name: string,
    surname: string,
    phone: string,
    email: string,
    subscribe_to: string,
    tariff: Tariff|null
}

export interface Tariff {
    id: number,
    name: string,
    description: string,
    books: 1 | 0,
    mediaClac: 1 | 0,
    reaserchCalc: 1 | 0
}

interface UserStore {
    user: User | null,
    token: string | null
}

const userLocal = localStorage.getItem("token");

export const useUser = create<UserStore>(() => ({
    user: null,
    token: userLocal
}))