import { Box, Stack } from "@mantine/core"
import { FC } from "react"
import { ITask } from "../../../../store/types"
import { Link } from "react-router-dom"

const Sidebar: FC<{
    calcs: ITask[]
    url: string
}> = ({ calcs, url }) => {
    return (
        <Stack ml={15} spacing={0}>
            <Box>
                <Link to="/calc">
                    <Box p={10} fz={16} c="#fff" style={{
                        backgroundColor: url === "" ? "#4ad295" : "",
                        fontWeight: url === "" ? "700" : "normal",
                        borderRadius: 8
                    }}>
                        О КАЛЬКУЛЯТОРАХ
                    </Box>
                </Link>
            </Box>
            <Box>
                <Link to="/calc/media">
                    <Box p={10} fz={16} c="#fff" style={{
                        backgroundColor: url === "media" ? "#4ad295" : "",
                        fontWeight: url === "media" ? "700" : "normal",
                        borderRadius: 8
                    }}>
                        МЕДИА КАЛЬКУЛЯТОРЫ
                    </Box>
                </Link>
                <Stack ml={15} spacing={0}>
                    {calcs.map((item, idx) => {
                        if (item.type === 0) return (
                            <Box key={idx}>
                                <Link to={"/calc/media/" + item.id}>
                                    <Box p={10} fz={16} c="#fff" style={{
                                        backgroundColor: url === `${item.id}` ? "#4ad295" : "",
                                        fontWeight: url === `${item.id}` ? "700" : "normal",
                                        borderRadius: 8
                                    }}>
                                        {item.short_name}
                                    </Box>
                                </Link>
                            </Box>
                        )
                    })}
                </Stack>
            </Box>
            <Box>
                <Link to="/calc/research">
                    <Box p={10} fz={16} c="#fff" style={{
                        backgroundColor: url === "research" ? "#4ad295" : "",
                        fontWeight: url === "research" ? "700" : "normal",
                        borderRadius: 8
                    }}>
                        КАЛЬКУЛЯТОРЫ для социологических, маркетинговых и рекламных исследований
                    </Box>
                </Link>
                <Stack ml={15} spacing={0}>
                    {calcs.map((item, idx) => {
                        if (item.type === 1) return (
                            <Box key={idx}>
                                <Link to={"/calc/research/" + item.id}>
                                    <Box p={10} fz={16} c="#fff" style={{
                                        backgroundColor: url === `${item.id}` ? "#4ad295" : "",
                                        fontWeight: url === `${item.id}` ? "700" : "normal",
                                        borderRadius: 8
                                    }}>
                                        {item.short_name}
                                    </Box>
                                </Link>
                            </Box>
                        )
                    })}
                </Stack>
            </Box>
        </Stack>
    )
}

export default Sidebar