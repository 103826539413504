import { FC } from "react";
import { DocData } from "..";
import { Box, Button, Container, Grid, Stack, Text } from "@mantine/core";
import BunnerDeskt from "../../../components/desktop/bunner";
import Layout from "../../../components/layout";
import { IDoc, Menu } from "../../../store/types";
import BreadcrumbsMod from "../../../components/desktop/breadcrumpsMod";
import { Link, useNavigate } from "react-router-dom";
import PDF from "./pdf";
import FooterDesktop from "../../../components/layout/footer";
import { useUser } from "../../../store/useUser";
import moment from "moment";
import { useAtom } from "jotai";
import { openAuth } from "../../../components/desktop/auth";

interface Props {
    data: DocData,
    child?: Menu
    parent: Menu,
    pdf: string | null
}

const DocDesktop: FC<Props> = ({ data, child, parent, pdf }) => {
    const [token, user] = useUser(state => [state.token, state.user])
    const nav = useNavigate()
    const [_, setOpened] = useAtom(openAuth);

    return (
        <Layout>
            <Box pos="relative">
                <BunnerDeskt banner={data.banner} />
                <Container size="80rem" pt={30}>
                    <BreadcrumbsMod parent={parent} child={child} />
                </Container>
                {data.dataAll && (
                    <Container size="80rem" pt={40} pb={70}>
                        <Grid>
                            <Grid.Col span={3}>
                                {token && moment(user?.subscribe_to).unix() >= moment().unix() && user?.tariff?.books === 1 ? (
                                    <Sidebar items={data.dataAll.books} url={child ? child.url : ""} />
                                ) : token && (user?.subscribe_to === null || moment(user?.subscribe_to).unix() <= moment().unix()) ? (
                                    <Box w="100%">
                                        <Button w="100%" h={50} mt={20} color="teal" styles={{
                                            label: {
                                                textTransform: "uppercase",
                                                fontWeight: 500,
                                                fontSize: 14
                                            }
                                        }} onClick={() => {
                                            nav('/lk')
                                        }}>
                                            оформить подписку
                                        </Button>
                                        <Text mt={20}>
                                            Для доступа к тексту книги необходимо оформить подписку
                                        </Text>
                                    </Box>
                                ) : token && user?.tariff?.books === 0 ? (
                                    <Box w="100%">
                                        <Button w="100%" h={50} mt={20} color="teal" styles={{
                                            label: {
                                                textTransform: "uppercase",
                                                fontWeight: 500,
                                                fontSize: 14
                                            }
                                        }} onClick={() => {
                                            nav('/tariff')
                                        }}>
                                            Сменить тариф
                                        </Button>
                                        <Text mt={20}>
                                            Ваш тариф {user?.tariff.name}. В этом тарифе книга О методах точного мышления не доступна
                                        </Text>
                                    </Box>
                                ) : (
                                    <Box w="100%">
                                        <Button w="100%" h={50} mt={20} color="teal" styles={{
                                            label: {
                                                textTransform: "uppercase",
                                                fontWeight: 500,
                                                fontSize: 14
                                            }
                                        }} onClick={() => {
                                            setOpened(true)
                                        }}>
                                            ЧИТАТЬ КНИГУ
                                        </Button>
                                        <Text mt={20}>
                                            Для доступа к тексту книги необходимо авторизоваться
                                        </Text>
                                    </Box>
                                )}
                            </Grid.Col>
                            <Grid.Col span={9}>
                                <Text component="h1" fz={30} fw="bold" mb={30} mt={0}>
                                    {pdf ? data.dataCurrent?.name : data.dataAll?.name}
                                </Text>
                                {pdf && token && user?.tariff?.books === 1 ? (
                                    <>
                                        <PDF pdf={pdf} />
                                    </>
                                ) : (
                                    <Box w="100%" p={50} bg="#fff" sx={{
                                        boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)"
                                    }}>
                                        <Box dangerouslySetInnerHTML={{
                                            __html: data.dataAll.description
                                        }} />
                                    </Box>
                                )}
                            </Grid.Col>
                        </Grid>
                    </Container>
                )}
                <FooterDesktop />
            </Box>
        </Layout>
    )
}

const Sidebar: FC<{
    items: IDoc[]
    url: string
}> = ({ items, url }) => {
    return (
        <Stack ml={15} spacing={0}>
            {items.map((item, idx) => {
                return (
                    <Box key={idx} sx={{
                        "a": {
                            textDecoration: "none",
                            color: "#000"
                        }
                    }}>
                        <Link to={"/doc/book/" + item.url}>
                            <Box p={10} fz={14} sx={{
                                backgroundColor: url.replace("book/", "") === item.url ? "#dfe2eb" : "",
                                fontWeight: url.replace("book/", "") === item.url ? "bold" : "normal",
                                borderRadius: 8,
                                ":hover": {
                                    backgroundColor: "#dfe2eb",
                                }
                            }}>
                                {item.name}
                            </Box>
                        </Link>
                    </Box>
                )
            })}
        </Stack>
    )
}

export default DocDesktop