import create from "zustand";

interface TokenStore {
    token: string | null;
    setToken: (title: string | null) => void;
}

export const useToken = create<TokenStore>((set, get) => ({
    token: localStorage.getItem('token'),
    setToken: token => set({token}),
}))