import { Box, Container, Flex, SimpleGrid, Text } from "@mantine/core";
import { FC } from "react";
import { useMain } from "../../store/useMain";

const FooterDesktop: FC = () => {
    const [legal, contacts, company_details] = useMain(state => [state.legal, state.contacts, state.company_details])
    return (
        <Box bg="#00916a" className="footer" py={50} sx={{
            "a": {
                color: "#fff",
                textDecoration: "none"
            }
        }}>
            <Container size="70rem">
                <SimpleGrid cols={3}>
                    <Box>
                        <Text c="#fff" fw="bold" fz={14} mb={10} tt="uppercase">Правовые документы</Text>
                        <Text dangerouslySetInnerHTML={{ __html: legal }} />
                    </Box>
                    <Box>
                        <Text c="#fff" fw="bold" fz={14} mb={10} tt="uppercase">КОНТАКТЫ ТЕХПОДДЕРЖКИ</Text>
                        <Text dangerouslySetInnerHTML={{ __html: contacts }} c="#fff" fz={12} />
                    </Box>
                    <Box>
                        <Text c="#fff" fw="bold" fz={14} mb={10} tt="uppercase">РЕКВИЗИТЫ</Text>
                        <Text dangerouslySetInnerHTML={{ __html: company_details }} c="#fff" fz={12} />
                    </Box>
                </SimpleGrid>
                <Flex mt={45} pt={25} align="center" justify="space-between" style={{
                    borderTop: "solid 1px #4ad295"
                }}>
                    <Text fz={14} c="#fff" fw={300}>
                        © c-mix.ru Все права защищены 2022 год
                    </Text>
                    <Text fz={14} c="#fff" fw={300}>
                        Сайт разработан ISYSTEMLAB
                    </Text>
                </Flex>
            </Container>
        </Box>
    )
}

export default FooterDesktop