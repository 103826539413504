import { ComboBox, DefaultButton, IComboBox, IComboBoxOption, MessageBar, MessageBarType, PrimaryButton, TextField } from "@fluentui/react";
import axios from "axios";
import React from "react";
import { FC, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../@library/interaction";
import { useToken } from "../../@store/useAuth";
import { useUser } from "../../@store/useUser";
import { Card } from "react-bootstrap";

const SamplesizeCalculationMin: FC<{
    setTaskID: (tastId: number | undefined) => void
}> = ({
    setTaskID
}) => {
        const navigate = useNavigate()
        type dataObjectTypeLeft = {
            n: number,
            r: number,
            delta: number,
            gamma: number | string
        }

        type dataObjectTypeRight = {
            n: number,
            rMin: number,
            gamma: number | string
        }

        const [serverMessage, setServerMessge] = useState<string>();
        const [subscribe, setSubscribe] = useState<boolean>(true)

        const [token, setToken] = useToken(state => [state.token, state.setToken])
        const [user, setUser] = useUser(state => [state.user, state.setUser])

        const [n, setN] = useState<number>(1000000)
        const [r, setR] = useState<number>(50)
        const [delta, setDelta] = useState<number>(3)

        const [rMin, setRMin] = useState<number>(3)
        const [gamma, setGamma] = useState<number | string>('95.0')

        const [requstDataLeft, setRequstDataLeft] = useState<dataObjectTypeLeft | null>(null);
        const [requstDataRight, setRequstDataRight] = useState<dataObjectTypeRight | null>(null);

        const [answerLeft, setAnswerLeft] = useState<number | null>(null)
        const [answerRight, setAnswerRight] = useState<number | null>(null)

        function onClickHelp(taskId: number) {
            setTaskID(taskId)
            console.log('Task', taskId);
        }

        function checkingValuesLeft() {
            if (n < 2 || n > 1000000000) return false;
            if (r < 0.1 || r > 100) return false
            if (delta < 0.1 || delta > 100) return false
            return true;
        }

        function checkingValuesRight() {
            if (rMin < 0.1 || r > 100) return false;
            return true;
        }

        const onChangeN = (e: any) => {
            const value = +e.target.value;
            setN(+value.toFixed(1))
        }

        const onChangeR = (e: any) => {
            const value = +e.target.value;
            setR(+value.toFixed(1))
        }

        const onChangeDelta = (e: any) => {
            const value = +e.target.value;
            setDelta(+value.toFixed(1))
        }

        const onChangeRMin = (e: any) => {
            const value = +e.target.value;
            setRMin(+value.toFixed(1))
        }

        const onChangeGamma = React.useCallback(
            (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
                const val = option?.key;
                if (val != undefined) setGamma(val)
            },
            [],
        );

        const createOptions = () => {
            const options: IComboBoxOption[] = [];
            for (let i = 99.5; i >= 50; i -= 0.5) {
                options.push({ key: Number.isInteger(i) ? `${i}.0` : `${i}`, text: Number.isInteger(i) ? `${i},0` : `${i}`.replace('.', ',') })
            }
            return options
        }

        const onSubmitData = async () => {
            if (checkingValuesLeft() && user && token) {
                try {
                    if (!token) {
                        navigate('/authorization')
                        return null
                    }
                    const response = await axios({
                        headers: { 'Authorization': `Bearer ${token}` },
                        method: "POST",
                        url: "/api/second_samplesizeCalculation",
                        data: requstDataLeft
                    });

                    setAnswerLeft(response.data);

                    console.log('requstDataLeft', requstDataLeft);


                } catch (error: any) {
                    const { response } = error;
                    try {
                        if (response.status == 422) {
                            console.log(response.data);
                            setServerMessge(response.data?.danger);
                            setSubscribe(false)
                        } else {
                            console.log(response, 'error2');
                            setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                        }

                    } catch (error: any) {
                        setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                    }
                }
            }

            if (checkingValuesLeft() && user && token) {
                try {
                    const token = localStorage.getItem('token')
                    if (!token) {
                        navigate('/authorization')
                        return null
                    }
                    const response = await axios({
                        headers: { 'Authorization': `Bearer ${token}` },
                        method: "POST",
                        url: "/api/samplesizeCalculation",
                        data: requstDataRight
                    });

                    setAnswerRight(response.data);

                    console.log('requstDataRight', requstDataRight);


                } catch (error: any) {
                    const { response } = error;
                   try {
                        if (response.status == 422) {
                            console.log(response.data);
                            setServerMessge(response.data?.danger);
                            setSubscribe(false)
                        } else {
                            console.log(response, 'error2');
                            setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                        }

                    } catch (error: any) {
                        setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                    }
                }
            }
        }

        useLayoutEffect(() => {
            if (checkingValuesLeft()) {
                setRequstDataLeft({
                    n,
                    r: r / 100,
                    delta: delta / 100,
                    gamma
                });
            }
        }, [n, r, delta, gamma])

        useLayoutEffect(() => {
            if (checkingValuesRight()) {
                setRequstDataRight({
                    n,
                    rMin: rMin / 100,
                    gamma
                })
            }
        }, [rMin, gamma, n])

        useLayoutEffect(() => {
            if (requstDataLeft) onSubmitData();
        }, [requstDataLeft])

        useLayoutEffect(() => {
            if (requstDataRight) onSubmitData();
        }, [requstDataRight])

        if (serverMessage) {
            return (
                <div className="task">
                    <div className="block-wrapper" style={{padding: 30}}>
                        <Card>
                            <b>{serverMessage}</b> <br /> <br />
                            {!subscribe && (
                                <PrimaryButton text="Офромить подписку" onClick={() => navigate("/lk")} allowDisabledFocus />
                            )}
                        </Card>
                    </div>
                </div>
            )
        }

        return (
            <div className="task">
                <div className="block-wrapper">
                    <div className="title" style={{ marginBottom: 10 }}>
                        <div className="title-content">
                        Объем выборки <i>n</i> в зависимости от <span>R<sub>min</sub>,</span> <span>γ</span> и <span>N</span>
                        </div>
                        <DefaultButton
                            title="Help"
                            ariaLabel="Help"
                            onClick={() => onClickHelp(7)}
                            text={'Помощь'}
                        />
                    </div>
                    <div className="input-body">
                        <div className="table" >
                            <div className="table-row" style={{ width: '100%', borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}>
                                <div className="name">
                                    Минимально значимая доля признака <i>R<sub>min</sub>, %</i>
                                </div>
                                <div className="value">
                                    <TextField suffix="%" type="number" min={0.1} max={100} step={0.1} value={String(rMin)} onChange={onChangeRMin} />
                                </div>
                            </div>
                            {(rMin <= 0 || rMin > 100) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    R<sub>min</sub> не модет быть больше 100% или меньше чем 0,1%
                                </MessageBar>
                            ) : null}
                            <div className="table-row" style={{ width: '100%' }}>
                                <div className="name">
                                    Уровень надежности измерений <strong>γ, </strong> <i>%</i>
                                </div>
                                <div className="value">
                                    <ComboBox
                                        allowFreeform={false}
                                        autoComplete={'on'}
                                        options={createOptions()}
                                        defaultSelectedKey={'95.0'}
                                        onChange={onChangeGamma}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="table">
                            <div className="table-row">
                                <div className="name">
                                    Объем генеральной совокупности  <i>N</i>
                                </div>
                                <div className="value">
                                    <TextField type="number" min={2} max={1000000000} step={1} value={String(n)} onChange={onChangeN} />
                                </div>
                            </div>
                            {(n <= 0 || n > 1000000000) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Предельный охват группы медиа не может быть больше 100% или меньше чем 0,1%
                                </MessageBar>
                            ) : null}
                        </div>
                    </div>

                    {checkingValuesRight() ? (
                        <div className="input-body answer" style={{ marginRight: 0 }}>
                            <div style={{ marginRight: 0 }} className="subtitle">Результат: </div>
                            {answerRight != null ? <div className="subtitle" style={{ marginRight: 0 }}><i>n</i> = {String(answerRight.toFixed(0))}</div> : null}
                        </div>
                    ) : null}

                </div>
            </div>
        )
    }

export default SamplesizeCalculationMin;

function useBoolean(arg0: boolean): [any, { setTrue: any; setFalse: any; }] {
    throw new Error("Function not implemented.");
}
