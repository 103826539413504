import axios from "axios";
import { FC, useCallback, useEffect, useLayoutEffect, useState, } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ErrorAlert } from "../@library/interaction";
import LayoutDefault from "../components/layouts/layout";
import SideBarBooks from "../components/pdf/sidebarBooks";

import { pdfjs } from 'react-pdf';
import Card from "../components/base/card";
import { useToken } from "../@store/useAuth";
import { useGlobal } from "../@store/useGlobal";
import { ComboBox, IComboBox, IComboBoxOption, IconButton, PrimaryButton, TextField } from "@fluentui/react";
import Banner from "../components/base/banner";
import About from "../components/base/about";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PageFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
`

const PageCounter = styled.div`
    margin: 0 10px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Select = styled(ComboBox)`
width: 70px;
`

const Pdf: FC<{

}> = ({

}) => {

        const [numPages, setNumPages] = useState<number>(0);
        const [pageNumber, setPageNumber] = useState(1);
        const [file, setFile] = useState<any>(null);
        const [arrayNum, setArrayNum] = useState<number[]>([])
        const [catList, setCatList] = useState<any[]>([]);
        const [token, setToken] = useToken(state => [state.token, state.setToken])
        const [currentCat, setCurrntCat] = useState<any>()
        const [showAuth, setShowAuth] = useGlobal(state => [state.showAuth, state.setShowAuth])
        const [title, setTitle] = useState<string | null>();
        const [subTitle, setSubtitle] = useState<string | null>();
        const [bunner, setBunner] = useState<string>('');
        const [subscribe, setSubscribe] = useState<boolean>(true)
        const [serverMessage, setServerMessge] = useState<string>();
        const [download, setDownload] = useState<boolean>(true)

        const { url } = useParams();
        let location = useLocation();
        const navigate = useNavigate()

        const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
            setNumPages(numPages);
        }

        const createOptions = () => {
            const options: IComboBoxOption[] = [];
            for (let i = 1; i <= numPages; i += 1) {
                options.push({ key: i, text: i + '' })
            }
            return options
        }

        const onChangePageNumber = useCallback(
            (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
                let val = option?.key;
                if (val) {
                    val = +val;
                    setPageNumber(val)
                }
            }, []);

        const onGetDataLinks = async () => {
            try {
                const response = await axios({
                    method: "GET",
                    headers: { 'Authorization': `Bearer ${token}`, 'Cache-Control': `no-cache` },
                    url: "/api/cat_all_extra",
                });
                setCatList(response.data);
            } catch (error: any) {
                const { response } = error;
                try {
                    console.log(error);
                } catch (error: any) {
                    ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
                }
            }
        }

        const onGetBanner = async () => {
            try {
                const response = await axios({
                    method: "GET",
                    headers: { 'Cache-Control': `no-cache` },
                    url: "/api/home/tasks",
                });

                setTitle(response.data.title);
                setSubtitle(response.data.subtitle);
                setBunner(response.data.banner);
            } catch (error: any) {
                const { response } = error;
                try {
                    console.log(response.data, 'error');
                    setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                } catch (error: any) {
                    setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                }
            }
        }

        const onGetData = async () => {
            setDownload(false)
            try {
                const response = await axios({
                    method: "GET",
                    headers: { 'Authorization': `Bearer ${token}`, 'Cache-Control': `no-cache` },
                    url: "/api/get_books_extra/" + url,
                });
                setServerMessge(undefined)
                try {
                    const { data } = await axios({
                        method: "GET",
                        url: response.data.pdf,
                        responseType: 'blob',
                    });

                    const reader = new FileReader();
                    reader.readAsDataURL(data);
                    reader.onloadend = function () {
                        const base64data = reader.result;
                        setFile(base64data);
                        setDownload(true)
                        setPageNumber(1)
                    }
                } catch (error: any) {
                    try {
                        console.log(error, 'error');
                        setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                    } catch (error: any) {
                        setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                    }
                }
            } catch (error: any) {
                const { response } = error;
                try {
                    if (response.status == 422) {
                        setSubscribe(false)
                        console.log(response.data);
                        setServerMessge(response.data?.danger);
                    } else {
                        console.log(response, 'error2');
                        setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                    }
                } catch (error: any) {
                    setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                }
            }
        }

        const getCurrentCatList = () => {
            catList.map((item, idx) => {
                setCurrntCat(item)
            })
        }

        const setFirstCat = () => {
            if ('/pdf' == location.pathname) {
                catList.map(item => {
                    if (item.pid === 0) {
                        navigate('/pdf/' + item.url)
                    }
                })
            }
        }

        useEffect(() => {
            onGetBanner()
            onGetDataLinks()
            if (location.pathname.indexOf('/book/') != -1) onGetData()
            else getCurrentCatList()
        }, [url])

        useLayoutEffect(() => {
            setFirstCat()
            getCurrentCatList()
        }, [url, catList])

        useLayoutEffect(() => {
            onGetDataLinks()
            let arr = []
            for (let i = 2; i <= numPages; ++i) {
                arr.push(i)
            }
            setArrayNum(arr)
        }, [numPages])

        return (
            <LayoutDefault>
                <Banner title={title} subtitle={subTitle} backgound={bunner}> </Banner>
                <Container>
                    <About title={currentCat?.name ? currentCat?.name : ''} subtitle={''} />
                    <div style={{ marginTop: 40 }}>
                        <Row style={{ margin: 0, overflowX: 'hidden' }}>
                            <Col xs={3} style={{ paddingRight: 0 }}>
                                <SideBarBooks section={'pdf'} url={url} links={catList} />
                            </Col>
                            <Col xs={9} style={{ paddingLeft: 0, paddingRight: 0 }}>
                                <Row>
                                    {!token ? (
                                        <div style={{ margin: "0 auto" }}>
                                            <Card>
                                                <div>
                                                    Для того чтобы получить книгу - нужно авторизоваться <br /> <br />
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <PrimaryButton text="Авторизоваться" onClick={() => setShowAuth(true)} allowDisabledFocus />
                                                </div>
                                            </Card>
                                        </div>
                                    ) : serverMessage ? (
                                            <div style={{ margin: "0 auto" }}>
                                                <Card>
                                                    <div>
                                                        {serverMessage} <br /> <br />
                                                    </div>
                                                    {!subscribe && (
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            <PrimaryButton text="Оформить подписку" onClick={()=> navigate("/lk")} allowDisabledFocus />
                                                        </div>
                                                    )}
                                                </Card>
                                            </div>
                                        ) : !download ? (
                                            <div style={{ width: '100%' }}>
                                                Загрузка...
                                            </div>
                                        ) : (
                                            location.pathname.indexOf('/book/') != -1 && file !== null ? (
                                                <Document renderMode={'svg'} file={file} onLoadSuccess={onDocumentLoadSuccess} onLoadError={error => console.log(error)}>
                                                    <Card>
                                                        <Page className={'pdf'} pageNumber={pageNumber} renderTextLayer={false} />
                                                    </Card>
                                                    {numPages > 0 && (
                                                        <PageFooter>
                                                            <IconButton iconProps={{ iconName: 'ChevronLeft' }} aria-label="Emoji" disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)} />
                                                            <PageCounter>
                                                                <div>Страница </div>
                                                                <Select
                                                                    allowFreeform={false}
                                                                    autoComplete={'on'}
                                                                    options={createOptions()}
                                                                    defaultSelectedKey={pageNumber}
                                                                    onChange={onChangePageNumber}
                                                                    selectedKey={pageNumber}
                                                                />
                                                                <div>из {numPages}</div>
                                                            </PageCounter>
                                                            <IconButton iconProps={{ iconName: 'ChevronRight' }} aria-label="Emoji" disabled={numPages === pageNumber} onClick={() => setPageNumber(pageNumber + 1)} />
                                                        </PageFooter>
                                                    )}
                                                </Document>
                                            ) : (
                                                currentCat && token && (
                                                    <>
                                                        <Card>
                                                            <div dangerouslySetInnerHTML={currentCat ? { __html: currentCat.description } : undefined} />
                                                        </Card>
                                                    </>
                                                )
                                            )
                                        )}
                                    {!token && (
                                        <Card>
                                            <b>Книга доступна только авторизованым пользователям с оформленной подпиской</b><br /> <br />
                                            <PrimaryButton text="Авторизоваться" onClick={() => setShowAuth(true)} allowDisabledFocus />
                                        </Card>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </LayoutDefault >
        )
    }

export default Pdf