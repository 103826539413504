import { FC, useEffect, useState } from "react";
import { IDoc } from "../../store/types";
import axios from "axios";
import { apiUrl } from "../../constant/routs";
import { isMobile } from "react-device-detect";
import DocDesktop from "./desktop";
import DocMobile from "./mobile";

export interface DocData {
    banner: string,
    dataAll: IDoc | null
    dataCurrent?: IDoc | null
}

const Doc: FC = () => {
    const [data, setData] = useState<DocData>({
        banner: "",
        dataAll: null
    })
    useEffect(() => {
        getData()
    }, [])
    if (data) {
        if (!isMobile) {
            return (
                <>
                    <DocDesktop data={data} pdf={null} parent={{
                        name: data.dataAll ? data.dataAll.name : "",
                        url: "/doc"
                    }} />
                </>
            )
        } else {
            return (
                <>
                    <DocMobile data={data} pdf={null} parent={{
                        name: data.dataAll ? data.dataAll.name : "",
                        url: "/doc"
                    }}/>
                </>
            )
        }
    }
    return (
        <>
            что-то пошло не так....
        </>
    )

    async function getData() {
        try {
            const { data } = await axios.get(`${apiUrl}api/home/tasks/doc?v=${Math.random()}`)
            const responseAll = await axios.get(`${apiUrl}api/cat_all_extra?v=${Math.random()}`)
            setData({
                banner: data.banner,
                dataAll: responseAll.data[0]
            })
        } catch {

        }
    }
}

export default Doc