import React from "react";
import ReactDOM from "react-dom";
import "./styles/global.scss";
import { initializeIcons } from "@fluentui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import News from "./pages/News";
import Books from "./pages/Books";
import Calc from "./pages/Calc";
import Pdf from "./pages/pdf";
import Lk from "./pages/LK";
import Post from "./pages/Post";
import Partneer from "./pages/Partneer";


initializeIcons();

ReactDOM.render(
    <BrowserRouter>
        <React.StrictMode>
            <Routes>
                <Route index element={<Home />} />
                <Route path="/news"  element={<News />} />
                <Route path="/partneer"  element={<Partneer />} />
                <Route path="/lk"  element={<Lk />} />
                <Route path="/post/:url"  element={<Post />} />
                <Route path="/pdf"  element={<Pdf />} />
                <Route path="/pdf/:url"  element={<Pdf />} />
                <Route path="/pdf/book/:url"  element={<Pdf />} />
                <Route path="/articles-and-books"  element={<Books />} />
                <Route path="/articles-and-books/:url"  element={<Books />} />
                <Route path="/calc"  element={<Calc />} />
                <Route path="/calc/media"  element={<Calc />} />
                <Route path="/calc/media/:id"  element={<Calc />} />
                <Route path="/calc/research"  element={<Calc />} />
                <Route path="/calc/research/:id"  element={<Calc />} />
                <Route path="/:empty" element={<Home />} />
            </Routes>
        </React.StrictMode>
    </BrowserRouter>,
    document.getElementById("root")
);
