import { Box, Button, Container, Flex, Grid, Stack, Text, Image, Modal, Center } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { BookCat, Menu } from "../../store/types";
import BunnerDeskt from "../../components/desktop/bunner";
import BreadcrumbsMod from "../../components/desktop/breadcrumpsMod";
import FooterDesktop from "../../components/layout/footer";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../components/layout";
import axios from "axios";
import { apiUrl } from "../../constant/routs";
import { useDisclosure } from "@mantine/hooks";
import PDF from "../Doc/desktop/pdf";
import { useUser } from "../../store/useUser";
import moment from "moment";
import { useAtom } from "jotai";
import { openAuth } from "../../components/desktop/auth";

interface Props {
    banner: string
    parent: Menu
    child?: Menu,
    catArr: BookCat[]
    categoryes: BookCat[]
    name: string,
    currentCat: BookCat | undefined
}

const ArticlesAndBooksDesc: FC<Props> = ({
    banner, parent, child, name, categoryes, currentCat
}) => {
    const [bookCat, setBookCat] = useState<BookCat[]>([])
    const [opened, { open, close }] = useDisclosure(false);
    const [pdf, setPdf] = useState<string | null>(null)
    const [user, token] = useUser(state => [state.user, state.token])
    const [_, setOpened] = useAtom(openAuth);
    const nav = useNavigate()

    useEffect(() => {
        getData()
    }, [currentCat?.url])
    return (
        <>
            <Layout>
                <Box pos="relative">
                    <BunnerDeskt banner={banner} />
                    <Container size="80rem" pt={30}>
                        <BreadcrumbsMod parent={parent} child={child} />
                    </Container>
                    <Container size="80rem" pt={40} pb={70}>
                        <Grid>
                            <Grid.Col span={3}>
                                <Sidebar items={categoryes} url={child ? child.url : ""} />
                            </Grid.Col>
                            <Grid.Col span={9}>
                                <Text component="h1" fz={30} fw="bold" mb={30} mt={0}>
                                    {name}
                                </Text>
                                <Stack>
                                    {currentCat?.description && (
                                        <>
                                            <Box w="100%" p={40} bg="#fff" sx={{
                                                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)"
                                            }}>
                                                <Box dangerouslySetInnerHTML={{
                                                    __html: currentCat.description
                                                }} />
                                            </Box>
                                        </>
                                    )}
                                    <Stack>
                                        {bookCat.map((book, idx) => {
                                            return (
                                                <Flex justify="space-between" p={50} bg="#dfe2eb" sx={{
                                                    boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)"
                                                }} key={idx}>
                                                    <Stack w="calc(100% - 275px)" spacing={10}>
                                                        <Text weight="bold" fz={16}>
                                                            {book.name}
                                                        </Text>
                                                        <Box dangerouslySetInnerHTML={{
                                                            __html: book.description
                                                        }} />
                                                    </Stack>
                                                    <Stack w={225} spacing={30}>
                                                        <Button radius="xs" color="teal" onClick={() => {
                                                            setPdf(book.pdf ? book.pdf : null)
                                                            open()
                                                        }} sx={{
                                                            width: 225,
                                                            height: 50,
                                                            textTransform: "uppercase",
                                                            fontSize: 16,
                                                            fontWeight: 600
                                                        }}>
                                                            Читать
                                                        </Button>
                                                        {book.image && (
                                                            <Image src={`${apiUrl}${book.image}`} radius="md" />
                                                        )}
                                                    </Stack>
                                                </Flex>
                                            )
                                        })}
                                    </Stack>
                                </Stack>
                            </Grid.Col>
                        </Grid>
                    </Container>
                    <FooterDesktop />
                </Box>
            </Layout>
            <Modal
                opened={opened}
                onClose={() => {
                    close()
                    setPdf(null)
                }}
                size="36%"
                transitionProps={{ transition: 'fade', duration: 200 }}
            >
                {token && moment(user?.subscribe_to).unix() <= moment().unix() ? (
                    <Box w="100%" p={50}>
                        <Text mt={20}>
                            Для доступа к тексту книги необходимо оформить подписку
                        </Text>
                        <Button w="100%" h={50} mt={20} color="teal" styles={{
                            label: {
                                textTransform: "uppercase",
                                fontWeight: 500,
                                fontSize: 14
                            }
                        }} onClick={() => {
                            nav('/lk')
                        }}>
                            оформить подписку
                        </Button>
                    </Box>
                ) : !token ? (
                    <Box w="100%" p={50}>
                        <Text mt={20}>
                            Для доступа к тексту книги необходимо авторизоваться
                        </Text>
                        <Button w="100%" h={50} mt={20} color="teal" styles={{
                            label: {
                                textTransform: "uppercase",
                                fontWeight: 500,
                                fontSize: 14
                            }
                        }} onClick={() => {
                            setOpened(true)
                        }}>
                            ЧИТАТЬ КНИГУ
                        </Button>
                    </Box>
                ) : token && user?.tariff?.books === 0? (
                    <Box w="100%" p={50}>
                        <Text mt={20}>
                            Ваш тариф {user?.tariff.name}. В этом тарифе книги и статьи не доступны
                        </Text>
                        <Button w="100%" h={50} mt={20} color="teal" styles={{
                            label: {
                                textTransform: "uppercase",
                                fontWeight: 500,
                                fontSize: 14
                            }
                        }} onClick={() => {
                            nav('/tariff')
                        }}>
                            Выбрать тариф
                        </Button>
                    </Box>
                ) : (
                    pdf && opened && token && user?.tariff?.books === 1 && (
                        <PDF pdf={pdf} />
                    )
                )}
            </Modal>
        </>
    )
    async function getData() {
        if (currentCat?.url) {
            try {
                const { data } = await axios.get(`${apiUrl}api/book_by_cat/${currentCat.url}?v=${Math.random()}`)
                setBookCat(data)
            } catch {

            }
        } else {

        }
    }
}

const Sidebar: FC<{
    items: BookCat[]
    url: string
}> = ({ items, url }) => {
    return (
        <Stack ml={15} spacing={0}>
            {items.map((item, idx) => {
                return (
                    <Box key={idx} sx={{
                        "a": {
                            textDecoration: "none",
                            color: "#000"
                        }
                    }}>
                        <Link to={"/articles-and-books/" + item.url}>
                            <Box p={10} fz={14} sx={{
                                backgroundColor: url.replace("boarticles-and-booksok/", "") === item.url ? "#dfe2eb" : "",
                                fontWeight: url.replace("articles-and-books/", "") === item.url ? "bold" : "normal",
                                borderRadius: 8,
                                ":hover": {
                                    backgroundColor: "#dfe2eb",
                                }
                            }}>
                                {item.name}
                            </Box>
                            {item.children.length > 0 && <Sidebar items={item.children} url={url} />}
                        </Link>
                    </Box>
                )
            })}
        </Stack>
    )
}

export default ArticlesAndBooksDesc