import {isArray, isObject, isString} from "lodash";

interface AnyObject {
    [key: string]: any;
}

export const toGetData = (data: AnyObject | string): string[] => {
    let content: string[] = [];
    if (isObject(data)) {
        Object.keys(data).forEach((key: string) => {
            let item: string[] = toGetData(data[key] as AnyObject);
            content = content.concat(item)
        })
    } else if (isArray(data)) {
        content.forEach((row: any) => {
            let item = toGetData(row);
            content = content.concat(item)
        });
    } else if (isString(data)) {
        content.push(data.trim())
    }
    return content;
}

export const getError = (dataSource: any): string => {
    let content = "Что-то пошло не так. Попробуйте еще раз",
        errors = dataSource?.errors ? toGetData(dataSource.errors) : toGetData(dataSource);

    if (errors.length > 0) {
        content = "";
        errors.forEach((row: string, idx: number) => {
            if (errors.length > 1) {
                content += `${(idx + 1)}) ${row}`;
            } else content += row;
            if ((idx + 1) !== errors.length) content += "\n";
        });
    }

    return content;
}

export const Confirm = (message: string): boolean => {
    return window.confirm(message);
}

export const Alert = (message: string): void => {
    window.alert(message);
}

export const ErrorAlert = (dataSource: any) => {
    Alert(getError(dataSource));
}