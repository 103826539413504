import { Box, Stack } from "@mantine/core";
import { FC } from "react";
import { ITask } from "../../../../store/types";
import { Link } from "react-router-dom";

interface Props {
    calcs: ITask[]
    url: string
}

const CalcSideBar: FC<Props> = ({
    calcs, url
}) => {
    return (
        <Stack ml={15} spacing={0} sx={{
            "a": {
                textDecoration: "none",
                color: "#000"
            }
        }}>
            <Box>
                <Link to="/calc">
                    <Box p={10} fz={14} sx={{
                        backgroundColor: url === "" ? "#dfe2eb" : "",
                        borderRadius: 8
                    }}>
                        О КАЛЬКУЛЯТОРАХ
                    </Box>
                </Link>
            </Box>
            <Box>
                <Link to="/calc/media">
                    <Box p={10} fz={14} sx={{
                        backgroundColor: url === "media" ? "#dfe2eb" : "",
                        borderRadius: 8
                    }}>
                        МЕДИА КАЛЬКУЛЯТОРЫ
                    </Box>
                </Link>
                <Stack ml={15} spacing={0}>
                    {calcs.map((item, idx) => {
                        if (item.type === 0) return (
                            <Box key={idx}>
                                <Link to={"/calc/media/" + item.id}>
                                    <Box p={10} fz={14} sx={{
                                        backgroundColor: url === `${item.id}` ? "#dfe2eb" : "",
                                        borderRadius: 8
                                    }}>
                                        {item.short_name}
                                    </Box>
                                </Link>
                            </Box>
                        )
                    })}
                </Stack>
            </Box>
            <Box>
                <Link to="/calc/research">
                    <Box p={10} fz={14} sx={{
                        backgroundColor: url === "research" ? "#dfe2eb" : "",
                        borderRadius: 8
                    }}>
                        КАЛЬКУЛЯТОРЫ для социологических, маркетинговых и рекламных исследований
                    </Box>
                </Link>
                <Stack ml={15} spacing={0}>
                    {calcs.map((item, idx) => {
                        if (item.type === 1) return (
                            <Box key={idx}>
                                <Link to={"/calc/research/" + item.id}>
                                    <Box p={10} fz={14} sx={{
                                        backgroundColor:  url === `${item.id}` ? "#dfe2eb" : "",
                                        borderRadius: 8
                                    }}>
                                        {item.short_name}
                                    </Box>
                                </Link>
                            </Box>
                        )
                    })}
                </Stack>
            </Box>
        </Stack>
    )
}

export default CalcSideBar