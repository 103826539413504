import { FontWeights, getTheme, IButtonStyles, IconButton, IIconProps, IStackProps, mergeStyleSets, Modal } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import axios from "axios";
import { FC, useLayoutEffect, useState } from "react";

const ModalTask: FC<{
    token: string
    taskId: number|undefined,
    setTaskID: (tastId: number|undefined) => void
}> = ({
    token, taskId, setTaskID
}) => {
        const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
        const [data, setData] = useState<string|undefined>(undefined)

        function hideModalFunktion() {
            setTaskID(undefined);
            setData(undefined);
            hideModal()
        }

        async function getInfo() {
            try {
                const response = await axios({
                    headers: { 'Authorization': `Bearer ${token}` },
                    method: "GET",
                    url: `/api/description/${taskId}`,
                });

                setData(response.data.text.help);

            } catch (error: any) {
                const { response } = error;
                try {
                    alert(response.data);
                } catch (error: any) {
                    alert("Что-то пошло не так. Попробуйте еще раз");
                }
            }
        }

        useLayoutEffect(() => {
            console.log('ModalTask',taskId, isModalOpen);
            if(taskId != undefined){
                getInfo()
                showModal()
            }
            else hideModal()

        }, [taskId])

        console.log(data, 'data')

        return (
            <>
                <Modal
                    titleAriaId={'title'}
                    isOpen={isModalOpen}
                    onDismiss={hideModalFunktion}
                    isBlocking={false}
                    containerClassName={contentStyles.container}
                >
                    <div className={contentStyles.header}>
                        <span id={'title'}>Справка</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={hideModalFunktion}
                        />
                    </div>
                    <div className={contentStyles.body} dangerouslySetInnerHTML={data? {__html: data}: undefined}>
                    </div>
                </Modal>
            </>
        )
    }

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'stretch',
        },
        header: {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
          },
        body: {
        //   flex: '4 4 auto',
          padding: '0 24px 24px 24px',
        //   overflowY: 'hidden',
          selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
          },
        },
      });
      const stackProps: Partial<IStackProps> = {
        horizontal: true,
        tokens: { childrenGap: 40 },
        styles: { root: { marginBottom: 20 } },
      };
      const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
          color: theme.palette.neutralPrimary,
          marginLeft: 'auto',
          marginTop: '4px',
          marginRight: '2px',
        },
        rootHovered: {
          color: theme.palette.neutralDark,
        },
      };

export default ModalTask;