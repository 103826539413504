import { ComboBox, FontWeights, getTheme, IButtonStyles, IComboBox, IComboBoxOption, IconButton, IIconProps, IStackProps, mergeStyleSets, Modal, PrimaryButton } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import axios from "axios";
import { FC, useCallback, useLayoutEffect, useState } from "react";
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import styled from "styled-components";
import { ErrorAlert } from "../../@library/interaction";
import { useToken } from "../../@store/useAuth";
import { useGlobal } from "../../@store/useGlobal";
import { useNavigate } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PageFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
`

const PageCounter = styled.div`
margin: 0 10px;
width: 200px;
display: flex;
align-items: center;
justify-content: space-between;
`

const Select = styled(ComboBox)`
width: 70px;
`

const ModalBook: FC<{
  url: string | null | undefined
  setUrl: (url: string | null) => void
}> = ({
  url, setUrl
}) => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const navigate = useNavigate()
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState<any>(null);
    const [name, setName] = useState<string>('')
    const [arrayNum, setArrayNum] = useState<number[]>([])
    const [subscribe, setSubscribe] = useState<boolean>(true)
    const [download, setDownload] = useState<boolean>(true)
    const [serverMessage, setServerMessge] = useState<string>();
    const [showAuth, setShowAuth] = useGlobal(state => [state.showAuth, state.setShowAuth])


    const [token, setToken] = useToken(state => [state.token, state.setToken])

    function hideModalFunktion() {
      setUrl(null)
      setFile(null)
      setArrayNum([])
      hideModal()
    }

    const createOptions = () => {
      const options: IComboBoxOption[] = [];
      for (let i = 1; i <= numPages; i += 1) {
        options.push({ key: i, text: i + '' })
      }
      return options
    }

    const onChangePageNumber = useCallback(
      (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        let val = option?.key;

        if (val) {
          val = +val;
          setPageNumber(val)
        }
      }, []);

    useLayoutEffect(() => {
      if (url) {
        showModal()
      }
    }, [url])

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
    }

    const onGetData = async () => {
      setDownload(false)
      try {
        const response = await axios({
          method: "GET",
          headers: { 'Authorization': `Bearer ${token}`, 'Cache-Control': `no-cache` },
          url: "/api/get_books_extra/" + url,
        });

        setName(response.data.name)

        try {

          const { data } = await axios({
            method: "GET",
            url: response.data.pdf,
            responseType: 'blob',
          });

          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = function () {
            const base64data = reader.result;
            setFile(base64data);
            setDownload(true)
          }

        } catch (error: any) {

          const { responseFile } = error;
          try {
            console.log(responseFile.data, 'error');
            setServerMessge("Что-то пошло не так. Попробуйте еще раз");
          } catch (error: any) {
            setServerMessge("Что-то пошло не так. Попробуйте еще раз");
          }
        }
      } catch (error: any) {
        const { response } = error;
        try {
          if (response.status == 422) {
            setSubscribe(false)
            console.log(response.data);
            setServerMessge(response.data?.danger);
          } else {
            console.log(response, 'error2');
            setServerMessge("Что-то пошло не так. Попробуйте еще раз");
          }

        } catch (error: any) {
          setServerMessge("Что-то пошло не так. Попробуйте еще раз");
        }
      }
    }

    useLayoutEffect(() => {
      let arr = []
      for (let i = 2; i <= numPages; ++i) {
        arr.push(i)
      }
      setArrayNum(arr)
    }, [numPages])

    useLayoutEffect(() => {
      onGetData()
    }, [])

    return (
      <>
        <Modal
          titleAriaId={'title'}
          isOpen={isModalOpen}
          onDismiss={hideModalFunktion}
          isBlocking={false}
          containerClassName={contentStyles.container}
        >
          <div className={contentStyles.header}>
            <span id={'title'}>{name}</span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={hideModalFunktion}
            />
          </div>
          <div className={contentStyles.body}>
            {!token ? (
              <div style={{ width: '100%' }}>
                <b>Для того чтобы получить книгу - нужно авторизоваться</b><br /> <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <PrimaryButton text="Авторизоваться" onClick={() => setShowAuth(true)} allowDisabledFocus />
                </div>
              </div>
            ) : serverMessage ? (
              <div style={{ width: '100%' }}>
                <b>{serverMessage}</b><br /> <br />
                {!subscribe && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <PrimaryButton text="Оформить подписку" onClick={() => navigate("/lk")} allowDisabledFocus />
                  </div>
                )}
              </div>
            ) : !download && (
              <div style={{ width: '100%' }}>
                <b> Загрузка...</b><br /> <br />
              </div>
            )}
            {file && subscribe && !serverMessage && (
              <Document renderMode={'svg'} file={file} onLoadSuccess={onDocumentLoadSuccess} onLoadError={error => console.log(error)}>
                <Page className={'pdf'} pageNumber={pageNumber} renderTextLayer={false} />
                {numPages > 0 && (
                  <PageFooter>
                    <IconButton iconProps={{ iconName: 'ChevronLeft' }} aria-label="Emoji" disabled={pageNumber === 1} onClick={() => setPageNumber(pageNumber - 1)} />
                    <PageCounter>
                      <div>Страница </div>
                      <Select
                        allowFreeform={false}
                        autoComplete={'on'}
                        options={createOptions()}
                        defaultSelectedKey={pageNumber}
                        onChange={onChangePageNumber}
                        selectedKey={pageNumber}
                      />
                      <div>из {numPages}</div>
                    </PageCounter>
                    <IconButton iconProps={{ iconName: 'ChevronRight' }} aria-label="Emoji" disabled={numPages === pageNumber} onClick={() => setPageNumber(pageNumber + 1)} />
                  </PageFooter>
                )}
              </Document>
            )}

          </div>
        </Modal>
      </>
    )
  }

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: {
    flex: '1 1 auto',
    borderTop: `4px solid ${theme.palette.themePrimary}`,
    color: theme.palette.neutralPrimary,
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '12px 12px 14px 24px',
  },
  body: {
    //   flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    //   overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
const stackProps: Partial<IStackProps> = {
  horizontal: true,
  tokens: { childrenGap: 40 },
  styles: { root: { marginBottom: 20 } },
};
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export default ModalBook;