import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, TextField } from "@fluentui/react";
import axios from "axios";
import { FC, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../@library/interaction";
import { useToken } from "../../@store/useAuth";
import { useUser } from "../../@store/useUser";
import { Card } from "react-bootstrap";

const MultipleMediaFudience: FC<{
    setTaskID: (tastId: number | undefined) => void
}> = ({
    setTaskID
}) => {

        const [serverMessage, setServerMessge] = useState<string>();
        const [subscribe, setSubscribe] = useState<boolean>(true)

        const [token, setToken] = useToken(state => [state.token, state.setToken])
        const [user, setUser] = useUser(state => [state.user, state.setUser])

        const navigate = useNavigate()
        const [mediaGroupReach, setMediaGroupReach] = useState<number>(90)
        const [countOfMedia, setCountOfMedia] = useState<number>(1)
        const [coverageOfMedia, setCoverageOfMedia] = useState<number[]>([])
        const [coverageOfMediaItems, setCoverageOfMediaItems] = useState<number[]>([10])
        const [answer, setAnswer] = useState<number | null>(null)

        function onClickHelp(taskId: number) {
            setTaskID(taskId)
            console.log('Task', taskId);
        }

        let dataObject: {
            coverageOfMediaItems: number[],
            mediaGroupReach: number
        }

        function checkingValues() {
            if (mediaGroupReach <= 0 || mediaGroupReach > 100) return false;
            if (countOfMedia <= 0 || countOfMedia > 20) return false;
            for (const item of coverageOfMediaItems) {
                if (item > mediaGroupReach) return false;
            }
            return true;
        }


        const onChangeMediaGroupReach = (e: any) => {
            const value = +e.target.value;
            setMediaGroupReach(+value.toFixed(1))
        }

        const onChangeCountOfMedia = (e: any) => {
            const value = +e.target.value;
            // if (e.target.value > 0 && e.target.value <= 20) {
            let items = Array.from(coverageOfMediaItems);
            if (countOfMedia >= items.length && items.length < 20) {
                items.push(0);
            }
            items.push(0);
            setCoverageOfMediaItems(items.slice(0, value));
            setCountOfMedia(+value.toFixed(0));
            // }
        }

        const onChamgeItems = (e: any) => {
            const value = +e.target.value;
            let items = Array.from(coverageOfMediaItems);
            items[e.target.tabIndex] = +value.toFixed(1);
            setCoverageOfMediaItems(items);
        }

        const onSubmitData = async () => {
            if (checkingValues() && user && token) {
                try {
                    const token = localStorage.getItem('token')
                    if (!token) {
                        navigate('/authorization')
                        return null
                    }
                    const response = await axios({
                        headers: { 'Authorization': `Bearer ${token}` },
                        method: "POST",
                        url: "/api/multipleMediaAudience",
                        data: dataObject
                    });

                    setAnswer(response.data);

                } catch (error: any) {
                    const { response } = error;
                    try {
                        if (response.status == 422) {
                            console.log(response.data);
                            setServerMessge(response.data?.danger);
                            setSubscribe(false)
                        } else {
                            console.log(response, 'error2');
                            setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                        }

                    } catch (error: any) {
                        setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                    }
                }
            }
        }

        useLayoutEffect(() => {
            let column = [];
            let items = coverageOfMediaItems;
            for (let i = 1; i <= countOfMedia; i++) {
                column.push(i);
            }

            setCoverageOfMedia(column);
            setCoverageOfMediaItems(items);


        }, [countOfMedia])

        useLayoutEffect(() => {
            checkingValues();

            dataObject = {
                coverageOfMediaItems,
                mediaGroupReach
            }
            onSubmitData();
        }, [mediaGroupReach, countOfMedia, coverageOfMediaItems])

        if (serverMessage) {
            return (
                <div className="task">
                    <div className="block-wrapper" style={{padding: 30}}>
                        <Card>
                            <b>{serverMessage}</b> <br /> <br />
                            {!subscribe && (
                                <PrimaryButton text="Офромить подписку" onClick={() => navigate("/lk")} allowDisabledFocus />
                            )}
                        </Card>
                    </div>
                </div>
            )
        }


        return (
            <div className="task">
                <div className="block-wrapper">
                    <div className="title">
                        <div className="title-content">
                            Вычисление охвата аудитории группы медиа <span>G</span> <br />в зависимости от охватов медианосителей <span>G<sub>j</sub>,</span> <span>L,</span> <span>G<sup>∞</sup></span>
                        </div>
                        <DefaultButton
                            title="Help"
                            ariaLabel="Help"
                            text={'Помощь'}
                            onClick={() => onClickHelp(2)}
                        />
                    </div>
                    <div className="input-body">
                        <div className="table">
                            <div className="table-row">
                                <div className="name">
                                    Предельный охват группы медиа <i>G<sup>∞</sup>, %</i>
                                </div>
                                <div className="value">
                                    <TextField suffix="%" type="number" min={0.1} max={100} step={0.1} value={String(mediaGroupReach)} onChange={onChangeMediaGroupReach} />
                                </div>
                            </div>
                            {(mediaGroupReach <= 0 || mediaGroupReach > 100) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Предельный охват группы медиа не может быть больше 100% или меньше чем 0,1%
                                </MessageBar>
                            ) : null}

                            <div className="table-row">
                                <div className="name">
                                    Количество медианосителей <i>L</i>
                                </div>
                                <div className="value">
                                    <TextField type="number" min={1} max={20} step={1} value={String(countOfMedia)} onChange={onChangeCountOfMedia} />
                                </div>
                            </div>
                            {(countOfMedia <= 0 || countOfMedia > 20) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Количество медианосителей <i>L</i> не может быть больше 20 и меньше 0
                                </MessageBar>
                            ) : null}
                        </div>
                    </div>

                    {(countOfMedia > 0 && countOfMedia <= 20) ? (
                        <div className="input-body">
                            <div className="subtitle"> Охват медианосителей <strong><i>G<sub>j</sub>, %</i></strong></div>
                            {countOfMedia ? (
                                <div className="table-answer">
                                    {coverageOfMedia.map((column, idx) => {
                                        return (
                                            <div className="column-table" key={idx} style={{ width: `10%` }}>
                                                <div className="thead">
                                                    {column}
                                                </div>
                                                <div className="trow">
                                                    <TextField type="number" tabIndex={idx} min={0} step={0.1} value={String(coverageOfMediaItems[idx])} onChange={onChamgeItems} />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    {answer != null && checkingValues() ? (
                        <div className="input-body answer">
                            <div style={{ marginRight: 0 }} className="subtitle">Результат: </div>
                            <div className="subtitle"><i>G</i> = {String(answer.toFixed(1))} %</div>
                        </div>
                    ) : null}

                </div>
            </div>
        )
    }

export default MultipleMediaFudience;