import { FC } from "react";
import { DataPage } from ".";
import Layout from "../../components/layout";
import HeaderMobile from "../../components/layout/headerMobile";
import BunnerMobile from "../../components/mobile/bunnerMobile";
import { Box, Container, Text } from "@mantine/core";

const PageMobile: FC<{ data: DataPage }> = ({ data }) => {
    return (
        <Layout>
            <HeaderMobile />
            <BunnerMobile banner={data.banner} />
            <Container pos="relative">
                <Text component="h1" fz={20} fw="bold" mb={10} mt={30} tt="uppercase" ta="center">
                    {data.post.name}
                </Text>
                {data.post.description && (
                    <Box w="100%" p={15} mb={30} bg="#fff" style={{
                        boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                        borderRadius: 10
                    }}>
                        <Box dangerouslySetInnerHTML={{
                            __html: data.post.description
                        }} />
                    </Box>
                )}
            </Container>
        </Layout>
    )
}

export default PageMobile