import {HomeData} from "./intex";
import {Anchor, Box, Card, Container, Flex, Grid, ScrollArea, Text, rem} from "@mantine/core";
import {apiUrl} from "../../constant/routs";
import Layout from "../../components/layout";
import {FC} from "react";
import HeaderDesctop from "../../components/layout/hearer";
import FooterDesktop from "../../components/layout/footer";

interface Props {
    data: HomeData
}

const HomeDesktop: FC<Props> = ({data}) => {
    return (
        <Layout>
            <Box pos="relative">
                <Box h={615} w="100%" display="relative" pos="absolute" sx={{
                    backgroundColor: "#007455",
                    zIndex: 0
                }}>
                    <Box h="100%" w="100%" display="block" pos="absolute" sx={{
                        opacity: 0.7,
                        zIndex: 0,
                        top: 0,
                        right: 0,
                        backgroundPosition: "left",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundImage: `url(${apiUrl}${data.banner})`,
                    }} />
                </Box>
                <Box pos="relative" sx={{
                    zIndex: 1
                }}>
                    <HeaderDesctop />
                    <Container size="80rem">
                        <Flex justify="flex-end" mt={60}>
                            <Box>
                                <Text component="h1" c="#fff" size={rem(80)} fw="bold" lh={0.88} ta="right" w={460}>
                                    {data.title}
                                </Text>
                                <Text c="#fff" mt={15} size={rem(23)} ta="right">
                                    {data.subtitle}
                                </Text>
                            </Box>
                        </Flex>
                    </Container>
                    <Container size="80rem" pb={80}>
                        <Grid mt={25} mx={5} align="flex-end" grow gutter="xl">
                            <Grid.Col span={8}>
                                <Card radius={6} shadow="0 4px 10px 0 rgba(0, 0, 0, 0.06)">
                                    <Box px={30} py={20}>
                                        <Text component="h2" lh={1} my={5} fz={30} fw="bold">О проекте</Text>
                                        <ScrollArea h={410} pt={10} pr={10}>
                                            <div style={{

                                            }}
                                                dangerouslySetInnerHTML={{__html: data.decription}} />
                                        </ScrollArea>
                                    </Box>
                                </Card>
                            </Grid.Col>
                            {data.section.length > 0 && (
                                <Grid.Col span={4}>
                                    <Anchor component="a" href={data.section[0].link}>
                                        <Card radius={6}>
                                            <Card.Section>
                                                <Flex h={343} py={50} px={45}
                                                    justify="flex-end"
                                                    direction="column"
                                                    style={{
                                                        backgroundImage: `url(${apiUrl}${data.section[0].image})`,
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: 'cover'
                                                    }}>
                                                    <Text fw="bold" fz={24} c="#fff" lh="normal" mb={15}>
                                                        {data.section[0].name}
                                                    </Text>
                                                    <Text fz={15} c="#fff" lh="normal">
                                                        {data.section[0].description}
                                                    </Text>
                                                </Flex>
                                            </Card.Section>
                                        </Card>
                                    </Anchor>
                                </Grid.Col>
                            )}
                        </Grid>
                        <Grid mt="xl" mx={5} grow gutter="xl">
                            {data.section.map((item, idx) => {
                                if (idx !== 0) return (
                                    <Grid.Col span={idx % 2 !== 0 ? 8 : 4} key={idx}>
                                        <Anchor component="a" href={item.link}>
                                            <Card radius={6}>
                                                <Card.Section>
                                                    <Flex h={343} py={50} px={45}
                                                        direction="column"
                                                        style={{
                                                            backgroundColor: `#00916a`,
                                                        }}>
                                                        <Box h={75} w={75} mb={15} style={{
                                                            backgroundImage: `url(${apiUrl}${item.image})`,
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundSize: 'contain',
                                                            backgroundPosition: 'center'
                                                        }} />
                                                        <Text fw="bold" fz={24} c="#fff" lh="normal" mb={15}>
                                                            {item.name}
                                                        </Text>
                                                        <Text fz={15} c="#fff" lh="normal">
                                                            {item.description}
                                                        </Text>
                                                    </Flex>
                                                </Card.Section>
                                            </Card>
                                        </Anchor>
                                    </Grid.Col>
                                )
                            })}
                        </Grid>
                    </Container>
                    <FooterDesktop />
                </Box>
            </Box>
        </Layout>
    )
}

export default HomeDesktop