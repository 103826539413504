import { Box, Card, Flex, Text } from "@mantine/core";
import { FC } from "react";
import { Tariff, useUser } from "../../store/useUser";
import moment from "moment";

const TariffItem: FC<{
    item: Tariff
}> = ({ item }) => {

    const user = useUser(state => state.user)
    return (
        <Card shadow="md" radius={10} p={0}>
            <Box pt={20} pb={10} px={30} sx={{
                borderBottom: "solid 1px #dfe2eb"
            }}>
                <Text fz={20} fw="normal" tt="uppercase" ta="center">
                    {item.name}
                </Text>
            </Box>
            {user && (
                <>
                    <Flex py={30} px={30} align="center" justify="center" sx={{
                        borderBottom: "solid 1px #dfe2eb",
                        minHeight: "30%"
                    }}>
                        <Text ta="center" fz={16} lh={1} dangerouslySetInnerHTML={{
                            __html: item.description
                        }} />
                    </Flex>
                    <Box py={30} px={30} sx={{
                        borderBottom: "solid 1px #dfe2eb"
                    }}>
                        <Text fz={18} fw={600} ta="center" lh={1}>
                            Доступен до {moment(user.subscribe_to).format("DD.MM.YYYY")}
                        </Text>
                    </Box>
                </>
            )}
        </Card>
    )
}

export default TariffItem