import axios from "axios";
import { FC, useLayoutEffect, useState } from "react";
import { Container, Row } from "react-grid-system";
import styled from "styled-components";
import { NewsType } from "../@entities/type";
import { ErrorAlert } from "../@library/interaction";
import About from "../components/base/about";
import Banner from "../components/base/banner";
import Card from "../components/base/card";
import LayoutDefault from "../components/layouts/layout";
import { useParams } from "react-router-dom";

const NewsContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;

    .news-image {
        height: 350px;
        width: 40%;

        background-image: url(../imgs/home.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 20px;
    }

    .news-text {
        width: 100%;
        font-size: 20px;
        line-height: 1.5;

        .news-title {
            line-height: 1.5;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;

            display: flex;
            align-items: center;
        }

        .news-more {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }

        .news-date {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }
    }
`

const Post: FC<{

}> = ({

}) => {

        const [title, setTitle] = useState<string>('');
        const [subTitle, setSubtitle] = useState<string | null>();
        const [bunner, setBunner] = useState<string>('');
        const [titlePage, setTitlePage] = useState<string>('');
        const [post, setPost] = useState<string>('');


        const { url } = useParams();

        const onGetData = async () => {
            try {
                const response = await axios({
                    method: "GET",
                    url: "/api/custom-text/" + url,
                });

                console.log(response);

                setTitle(response.data.title);
                setSubtitle(response.data.subtitle);
                setBunner(response.data.banner);
                setTitlePage(response.data.post.name);
                setPost(response.data.post.description);

            } catch (error: any) {
                const { response } = error;
                try {
                    ErrorAlert("Что-то пошло не так. Попробуйте еще раз");

                } catch (error: any) {
                    ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
                }
            }
        }

        useLayoutEffect(() => {
            onGetData();
        }, [])

        return (
            <LayoutDefault>
                <Banner title={title} subtitle={subTitle} backgound={bunner}>
                </Banner>
                <Container>
                    <About title={titlePage} subtitle={''} />
                    <div>
                        <Row direction="column" style={{ paddingTop: 30 }}>
                            <Card >
                                <NewsContainer>
                                    <div className="news-text" dangerouslySetInnerHTML={post? {__html: post}: undefined}>
                                    </div>
                                </NewsContainer>
                            </Card>

                        </Row>
                    </div>
                </Container>
            </LayoutDefault>
        )
    }

export default Post