import { FC, useState } from "react";
import { DataTariff } from ".";
import Layout from "../../components/layout";
import { Box, Button, Container, SimpleGrid, Stack, Text } from "@mantine/core";
import BunnerDeskt from "../../components/desktop/bunner";
import BreadcrumbsMod from "../../components/desktop/breadcrumpsMod";
import FooterDesktop from "../../components/layout/footer";
import TariffItem from "./components/tariffItem";

const TariffDesktop: FC<{
    data: DataTariff[]
}> = ({
    data
}) => {
        const [isIndividual, setIndividual] = useState<boolean>(true)

        return (
            <Layout>
                <Box pos="relative">
                    <BunnerDeskt banner="public/files/settings/banner_lk_1.png" />
                    <Container size="80rem" pt={30}>
                        <BreadcrumbsMod parent={{
                            name: "Тарифы",
                            url: "/tariff"
                        }} child={undefined} />
                    </Container>
                    <Container size="80rem" pt={40} pb={70}>
                        <Text component="h1" tt="uppercase" fz={30} fw="bold" mb={20} mt={0}>
                            Тарифы
                        </Text>
                        <Stack justify="center" w="100%" mb={30} sx={{
                            flexDirection: "row"
                        }}>
                            <Button variant={isIndividual ? "filled" : "outline"} radius={25} w={270} h={50} color="teal" styles={{
                                label: {
                                    textTransform: "uppercase",
                                    fontWeight: 500,
                                    fontSize: 14
                                }
                            }} onClick={() => {
                                if (!isIndividual) setIndividual(!isIndividual)
                            }}>
                                Индивидуальные
                            </Button>
                            {/* <Button variant={!isIndividual ? "filled" : "outline"} color="teal" radius={25} w={270} h={50} disabled styles={{
                                label: {
                                    textTransform: "uppercase",
                                    fontWeight: 500,
                                    fontSize: 14
                                }
                            }} onClick={() => {
                                setIndividual(!isIndividual)
                            }}>
                                Групповые
                            </Button> */}
                        </Stack>
                        <SimpleGrid cols={4} spacing="xs" verticalSpacing="lg">
                            {data.map((item, idx) => {
                                return <TariffItem key={idx} item={item}/>
                            })}
                        </SimpleGrid>
                    </Container>
                    <FooterDesktop />
                </Box>
            </Layout>
        )
    }

export default TariffDesktop