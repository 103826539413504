import axios from "axios";
import { FC, useEffect, useState } from "react";
import { apiUrl } from "../../constant/routs";
import { useUser } from "../../store/useUser";
import { CalcData } from ".";
import { isMobile } from "react-device-detect";
import CalcPageDesk from "./desktop/calcPageDesk";
import { ITask } from "../../store/types";
import CalcPageMobile from "./mobile/calcPageMobile";

interface Props {
    calcId: number
    type: "media" | "research"
}

const CalcPage: FC<Props> = ({ type, calcId }) => {
    const [data, setData] = useState<CalcData>({
        banner: "",
        subscribe: false,
        description: "",
        calcs: []
    })
    const [currentCalc, setCurrentCalc] = useState<ITask>()
    useEffect(() => {
        getData()
    }, [type, calcId])
    useEffect(() => {
        data.calcs.forEach(item => {
            if (calcId === item.id) setCurrentCalc(item)
        })
    }, [calcId])
    const token = useUser(state => state.token)
    if (!isMobile) {
        return (
            <CalcPageDesk banner={data.banner} parent={{
                name: "МЕДИА КАЛЬКУЛЯТОРЫ",
                url: "/calc/media"
            }} child={currentCalc ? {
                name: currentCalc.short_name,
                url: `${currentCalc.id}`
            } : undefined} calcs={data.calcs} url={`${calcId}`} description={data.description} />
        )
    }
    return (
        <>
            <CalcPageMobile banner={data.banner} calcs={data.calcs} url={`${calcId}`} name={""} description={data.description} />
        </>
    )

    async function getData() {
        try {
            const responseAll = await axios.get(`${apiUrl}api/description/${calcId}?v=${Math.random()}`, token ? {
                headers: { "Authorization": `Bearer ${token}` }
            } : undefined)
            const { data } = await axios.get(`${apiUrl}api/home/tasks/calc?v=${Math.random()}`)
            setData({
                banner: data.banner,
                description: responseAll.data.text.description,
                calcs: data.tasks,
                subscribe: responseAll.data.subscribe
            })
        } catch (error) {

        }
    }
}

export default CalcPage