import axios from "axios";
import { FC, useEffect, useState } from "react";
import { apiUrl } from "../../constant/routs";
import { isMobile } from "react-device-detect";
import PageDesktop from "./desktop";
import PageMobile from "./mobile";
import { useParams } from "react-router-dom";

export interface DataPage {
    banner: string,
    post: {
        name: string,
        url: string,
        description: string,
        created_at: string
    }
}

const Page: FC = () => {
    const [data, setData] = useState<DataPage>()
    const { url } = useParams();

    useEffect(() => {
        getData()
    }, []);

    if (data) {
        if (!isMobile) {
            return (
                <>
                    <PageDesktop data={data} />
                </>
            )
        } else {
            return (
                <>
                    <PageMobile data={data} />
                </>
            )
        }
    }
    return (
        <>

        </>
    )
    async function getData() {
        try {
            const { data } = await axios.get(`${apiUrl}api/custom-text/${url}?v=${Math.random()}`)
            console.log(data);
            
            setData({
                banner: data.banner,
                post: data.post
            })
        } catch {

        }
    }
}
export default Page