import {useForm} from "@mantine/form";
import axios, {AxiosError} from "axios";
import {atom, useAtom} from "jotai";
import {FC, useCallback, useState} from "react";
import {useUser} from "../../store/useUser";
import {apiUrl} from "../../constant/routs";
import {Box, Text, Modal, Flex, TextInput, Anchor, PasswordInput, Button, InputBase, Checkbox} from "@mantine/core";
import {IMaskInput} from "react-imask";

export const openAuthMobile = atom(false)

const Auth: FC = () => {
    const [openedMob, setOpenedMob] = useAtom(openAuthMobile);
    const [visible, setVisible] = useState<boolean>(false)
    const [iConfirm, setiConfirm] = useState<boolean>(true)
    const [isAuth, setAuth] = useState<boolean>(true)
    const form = useForm({
        initialValues: {
            email: "",
            password: ""
        }
    })

    const formReg = useForm({
        initialValues: {
            email: "",
            name: "",
            surname: "",
            phone: "",
            password: "",
            password_confirmation: ""
        }
    })

    const handleAuth = useCallback(async () => {
        setVisible(true)
        try {
            const {data} = await axios.post(`${apiUrl}api/auth/login`, form.values)
            useUser.setState({
                user: data,
                token: data.token
            })
            localStorage.setItem("token", data.token)
            setOpenedMob(false)
        } catch (error) {
            const err = error as AxiosError
            if (err.response?.status === 422) {
                alert("Неправильный логин или пароль")
            } else {
                alert(JSON.stringify(err.response?.data))
                console.error(error)
            }
        }
        setVisible(false)
    }, [form.values, visible])

    const handleReg = useCallback(async () => {
        setVisible(true)
        try {
            const {data} = await axios.post(`${apiUrl}api/auth/registered`, formReg.values)
            useUser.setState({
                user: data,
                token: data.token
            })
            setOpenedMob(false)
            localStorage.setItem("token", data.token)
        } catch (error) {
            const err = error as AxiosError
            alert(JSON.stringify(err.response?.data))
            console.log(err.response?.status === 422, err.response?.status);
        }
        setVisible(false)
    }, [formReg.values, visible])
    return (
        <Modal.Root
            fullScreen
            opened={openedMob}
            size={555}
            onClose={() => setOpenedMob(false)}>
            <Modal.Overlay bg="rgba(0, 0, 0, 0.5)" blur="10px" />
            <Modal.Content bg="#dfe2eb" px={30}>
                <Modal.CloseButton pos="absolute" top={10} right={10} size="xl" />
                <Modal.Body mt={10} pos="relative">
                    <Box mb={20}>
                        {isAuth ? (
                            <Text fz={30} fw="bold">
                                Вход
                            </Text>
                        ) : (
                            <Text fz={30} fw="bold">
                                Регистрация
                            </Text>
                        )}
                    </Box>
                    {isAuth ? (
                        <form onSubmit={form.onSubmit(() => handleAuth())}>
                            <Flex direction="column" align="center" >
                                <TextInput
                                    w="100%"
                                    label="Адрес электронной почты"
                                    withAsterisk
                                    name="email"
                                    h={40}
                                    mb={30}
                                    onChange={e => form.setFieldValue("email", e.target.value)}
                                    styles={{
                                        input: {
                                            height: 42
                                        },
                                        label: {
                                            fontSize: 12,
                                            fontWeight: "normal"
                                        }
                                    }}
                                />
                                <Box pos="relative" w="100%">
                                    <Anchor onClick={() => { }} pos="absolute" style={{
                                        color: "#00af80",
                                        fontSize: 15,
                                        top: 3,
                                        right: 0
                                    }}>
                                        Забыли пароль?
                                    </Anchor>
                                    <PasswordInput
                                        w="100%"
                                        label="Пароль"
                                        withAsterisk
                                        name="password"
                                        mb={20}
                                        onChange={e => form.setFieldValue("password", e.target.value)}
                                        styles={{
                                            input: {
                                                height: 42
                                            },
                                            label: {
                                                fontSize: 15,
                                                fontWeight: "normal"
                                            }
                                        }}
                                    />
                                </Box>
                                <Box w="100%">
                                    <Button type="submit" w="100%" h={50} color="teal" styles={{
                                        label: {
                                            textTransform: "uppercase",
                                            fontWeight: 500,
                                            fontSize: 14
                                        }
                                    }}>
                                        Войти
                                    </Button>
                                    <Flex w="100%" justify="center" direction="column" align="center" mt={10}>
                                        <Text fz={15}>
                                            Нет учётной записи?
                                        </Text>
                                        <Anchor component="button" onClick={() => setAuth(false)} style={{
                                            color: "#00af80",
                                            fontSize: 15,
                                        }}>
                                            Зарегистрироваться
                                        </Anchor>
                                    </Flex>
                                </Box>
                            </Flex>
                        </form>
                    ) : (
                        <form onSubmit={form.onSubmit(() => handleReg())}>
                            <Flex direction="column" align="center" >
                                <TextInput
                                    w="100%"
                                    label="Адрес электронной почты"
                                    withAsterisk
                                    name="email"
                                    h={40}
                                    mb={30}
                                    onChange={e => formReg.setFieldValue("email", e.target.value)}
                                    styles={{
                                        input: {
                                            height: 42
                                        },
                                        label: {
                                            fontSize: 12,
                                            fontWeight: "normal"
                                        }
                                    }}
                                />
                                <TextInput
                                    w="100%"
                                    label="Имя"
                                    withAsterisk
                                    name="name"
                                    h={40}
                                    mb={30}
                                    onChange={e => formReg.setFieldValue("name", e.target.value)}
                                    styles={{
                                        input: {
                                            height: 42
                                        },
                                        label: {
                                            fontSize: 12,
                                            fontWeight: "normal"
                                        }
                                    }}
                                />
                                <TextInput
                                    w="100%"
                                    label="Фамилия"
                                    withAsterisk
                                    name="surname"
                                    h={40}
                                    mb={30}
                                    onChange={e => formReg.setFieldValue("surname", e.target.value)}
                                    styles={{
                                        input: {
                                            height: 42
                                        },
                                        label: {
                                            fontSize: 12,
                                            fontWeight: "normal"
                                        }
                                    }}
                                />
                                <InputBase
                                    w="100%"
                                    label="Телефон"
                                    component={IMaskInput}
                                    mask="+7 (000) 000-0000"
                                    h={40}
                                    name="phone"
                                    styles={{
                                        input: {
                                            height: 42
                                        },
                                        label: {
                                            fontSize: 12,
                                            fontWeight: "normal"
                                        }
                                    }}
                                    onChange={e => formReg.setFieldValue("phone", (e.target as HTMLTextAreaElement).value)}
                                />
                                <PasswordInput
                                    mt={30}
                                    w="100%"
                                    label="Пароль"
                                    withAsterisk
                                    name="password"
                                    onChange={e => formReg.setFieldValue("password", e.target.value)}
                                    styles={{
                                        input: {
                                            height: 42
                                        },
                                        label: {
                                            fontSize: 12,
                                            fontWeight: "normal"
                                        }
                                    }}
                                />
                                <PasswordInput
                                    w="100%"
                                    label="Повторить пароль"
                                    withAsterisk
                                    name="password_confirmation"
                                    mb={20}
                                    onChange={e => formReg.setFieldValue("password_confirmation", e.target.value)}
                                    styles={{
                                        input: {
                                            height: 42
                                        },
                                        label: {
                                            fontSize: 12,
                                            fontWeight: "normal"
                                        }
                                    }}
                                />
                                <Checkbox
                                    color="teal"
                                    checked={iConfirm}
                                    onChange={() => setiConfirm(!iConfirm)}
                                    mb={20}
                                    styles={{
                                        label: {
                                            fontSize: 15
                                        },
                                    }}
                                    label={
                                        <>
                                            Я принимаю условия{' '}
                                            <Anchor href="#" target="_blank" inherit c={"#00af80"}>
                                                Пользовательского соглашения
                                            </Anchor>
                                        </>
                                    }
                                />
                                <Box w="100%">
                                    <Button type="submit" w="100%" h={50} color="teal" styles={{
                                        label: {
                                            textTransform: "uppercase",
                                            fontWeight: 500,
                                            fontSize: 14
                                        }
                                    }}>
                                        Зарегистрироваться
                                    </Button>
                                    <Flex w="100%" justify="center" direction="column" align="center" mt={10}>
                                        <Text fz={15}>
                                            У меня уже есть учётная запись
                                        </Text>
                                        <Anchor component="button" onClick={() => setAuth(true)} style={{
                                            color: "#00af80",
                                            fontSize: 15,
                                        }}>
                                            Войти
                                        </Anchor>
                                    </Flex>
                                </Box>
                            </Flex>
                        </form>
                    )}
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    )
}

export default Auth