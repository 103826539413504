import { FC, useCallback } from "react";
import Layout from "../../components/layout";
import { Box, Button, Card, Container, Flex, Grid, PasswordInput, Text, TextInput } from "@mantine/core";
import BunnerDeskt from "../../components/desktop/bunner";
import { useForm } from "@mantine/form";
import axios, { AxiosError } from "axios";
import { apiUrl } from "../../constant/routs";
import FooterDesktop from "../../components/layout/footer";

const ResetDesctop: FC<{
    token?: string
}> = ({
    token
}) => {
        const form = useForm({
            initialValues: {
                email: "",
            }
        })

        const formPassword = useForm({
            initialValues: {
                token: token,
                password: "",
                passwordConfirm: ""
            }
        })

        const handleEdit = useCallback(async () => {
            try {
                const { data } = await axios.post(`${apiUrl}api/personal/resetPassword`, form.values, undefined)
                alert(data.message)
            } catch (error) {
                alert((error as AxiosError).response?.data)
                console.error(error)
            }
        }, [form.values])

        const handleChangePassword = useCallback(async () => {
            try {
                const { data } = await axios.post(`${apiUrl}api/personal/changes/password`, formPassword.values, token ? {
                    headers: { "Authorization": `Bearer ${token}` }
                } : undefined)
                formPassword.reset()
                alert(data.message)
            } catch (error) {
                if (axios.isAxiosError(error) && error.response?.data) {
                    console.error(error.response.data.message);
                    alert(error.response.data.message)
                }
            }
        }, [formPassword.values])

        return (
            <Layout>
                <Box pos="relative">
                    <BunnerDeskt banner={""} />
                    <Container size="80rem" pt={40} pb={70}>
                        <Grid>
                            <Grid.Col span={9}>
                                <Card radius={0} bg="#dfe2eb" pt={30} pb={50} px={45}>
                                    <Text fw={600} fz={24} mb={10}>
                                        Сброс пароля
                                    </Text>
                                    {!token ? (
                                        <form onSubmit={form.onSubmit(() => handleEdit())}>
                                            <Flex direction="column" align="center">
                                                <TextInput
                                                    w="100%"
                                                    label="Адрес электронной почты"
                                                    withAsterisk
                                                    defaultValue={form.values.email}
                                                    name="email"
                                                    h={40}
                                                    mb={30}
                                                    onChange={(e: { target: { value: string; }; }) => form.setFieldValue("email", e.target.value)}
                                                    styles={{
                                                        input: {
                                                            height: 42
                                                        },
                                                        label: {
                                                            fontSize: 12,
                                                            fontWeight: "normal"
                                                        }
                                                    }}
                                                />
                                                <Box w="100%" mt={50}>
                                                    <Button type="submit" w="50%" h={50} color="teal" styles={{
                                                        label: {
                                                            textTransform: "uppercase",
                                                            fontWeight: 500,
                                                            fontSize: 14
                                                        }
                                                    }}>
                                                        Отправить
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        </form>
                                    ) : (
                                        <form onSubmit={form.onSubmit(() => handleChangePassword())}>
                                            <Flex direction="column" align="center">
                                                <PasswordInput
                                                    w="100%"
                                                    label="Новый пароль"
                                                    withAsterisk
                                                    name="password"
                                                    h={40}
                                                    mb={30}
                                                    onChange={(e: { target: { value: string; }; }) => formPassword.setFieldValue("password", e.target.value)}
                                                    styles={{
                                                        input: {
                                                            height: 42
                                                        },
                                                        label: {
                                                            fontSize: 12,
                                                            fontWeight: "normal"
                                                        }
                                                    }}
                                                />
                                                <PasswordInput
                                                    w="100%"
                                                    label="Повторите новый пароль"
                                                    withAsterisk
                                                    name="passwordConfirm"
                                                    h={40}
                                                    mb={30}
                                                    onChange={(e: { target: { value: string; }; }) => formPassword.setFieldValue("passwordConfirm", e.target.value)}
                                                    styles={{
                                                        input: {
                                                            height: 42
                                                        },
                                                        label: {
                                                            fontSize: 12,
                                                            fontWeight: "normal"
                                                        }
                                                    }}
                                                />
                                                <Box w="100%" mt={20}>
                                                    <Button type="submit" w="50%" h={50} color="teal" styles={{
                                                        label: {
                                                            textTransform: "uppercase",
                                                            fontWeight: 500,
                                                            fontSize: 14
                                                        }
                                                    }}>
                                                        Изменить
                                                    </Button>
                                                </Box>
                                            </Flex>
                                        </form>
                                    )}
                                </Card>
                            </Grid.Col>
                            <Grid.Col span={3}>

                            </Grid.Col>
                        </Grid>
                    </Container>
                    <FooterDesktop />
                </Box>
            </Layout>
        )
    }

export default ResetDesctop