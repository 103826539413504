import { PrimaryButton } from "@fluentui/react";
import axios from "axios";
import { FC, useLayoutEffect, useState } from "react";
import { Col, Row } from "react-grid-system";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useToken } from "../@store/useAuth";
import { useGlobal } from "../@store/useGlobal";
import Card from "../components/base/card";
import CalculationOfAudienceCoverageOfOneMediaRequest from "../components/calc/calculationOfAudienceCoverageOfOneMediaRequest";
import CalculationOfFrequencyCoverageDistribution from "../components/calc/calculationOfFrequencyCoverageDistribution";
import CalculationOfPrivateEffectiveAudienceReachAndEffectiveNumberOfContacts from "../components/calc/calculationOfPrivateEffectiveAudienceReachAndEffectiveNumberOfContacts";
import CalculationOfTheRatioOfPercentagePointsOfGrowthAndRating from "../components/calc/calculationOfTheRatioOfPercentagePointsOfGrowthAndRating";
import DurationOfAdvertisingSilence from "../components/calc/durationOfAdvertisingSilence";
import EffectiveNumberOfPlacementsValues from "../components/calc/effectiveNumberOfPlacementsValues";
import MeasurementErrorCalculation from "../components/calc/measurementErrorCalculation";
import MultipleMediaAudience from "../components/calc/multipleMediaAudience";
import ModalTask from "../components/calc/partitials/modal";
import SideBarDashboard from "../components/calc/partitials/sidebarDashboard";
import SamplesizeCalculation from "../components/calc/sampleSizeCalculation";
import SamplesizeCalculationMin from "../components/calc/sampleSizeCalculationMin";
import LayoutDefault from "../components/layouts/layout";



const CardTitle = styled.div`
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    color: #323130;
    font-weight: 600;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
    outline: 0;
    margin-bottom: 30px;
    display: flex;
`

const CardBody = styled.div`

`

const CalcDashbord = styled.div`
    padding: 40px 40px 1px;
    background-color: #c7cac7;
`

const TaskCard = styled.div`


input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    .task {
        .block-wrapper {
            background: #f4f4f4;
            padding: 30px 30px 0 30px;
            margin: 0 10px 30px 10px;
            border-radius: 2px;
            background-clip: padding-box;
            box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
            position: relative;

            input {
                border: none;
                background-color: rgba(#fff, 0);
                font-size: 20px;
            }

            .title {
                width: 100%;
                margin-bottom: 20px;
                justify-content: space-between;
                align-items: center;
                color: #323130;
                font-weight: 600;
                line-height: 1;
                margin-top: 0;
                margin-bottom: 0;
                font-size: 24px;
                outline: 0;
                margin-bottom: 30px;
                display: flex;

                .title-content {
                   
                }

                sub,
                sup {
                    font-weight: normal;
                    font-style: italic;
                }

                span {
                    font-weight: normal;
                    font-style: italic;
                }
            }

            .input-body {
                padding-bottom: 30px;
                font-size: 20px;
                .table {
                    width: 100%;
                    border: 1px solid rgba(0, 0, 0, 0.1);

                    .table-row {
                        display: flex;
                        justify-content: space-between;
                        background: rgba(0, 0, 0, 0.1);

                        .name,
                        .value {
                            padding: 5px;

                            i {
                                font-weight: bold;
                            }
                        }
                        .name {
                            width: calc(80% - 5px);
                        }
                        .value {
                            display: flex;
                            align-items: center;
                            border-left: 1px solid rgba(0, 0, 0, 0.1);
                            width: calc(20% - 5px);

                            .ms-TextField,
                            .ms-ComboBox-container 
                            {
                                width: 100%;
                            }
                            input {
                                width: calc(100% - 4px);
                            }
                        }
                        &:nth-child(2n) {
                            background: #fff;
                        }
                    }
                }

                .table-answer {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;

                    .column-table {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                        .thead {
                            text-align: center;
                            background: rgba(0, 0, 0, 0.1);
                            border-left: 0.5px solid rgba(0, 0, 0, 0.1);
                            border-right: 0.5px solid rgba(0, 0, 0, 0.1);
                        }
                        .trow {
                            width: auto;
                            border-left: 0.5px solid rgba(0, 0, 0, 0.1);
                            border-right: 0.5px solid rgba(0, 0, 0, 0.1);

                            .ms-TextField-fieldGroup {
                                border: none !important;
                            }
                            input {
                                width: calc(100% - 4px);
                                text-align: center;
                            }
                        }
                    }
                }

                .double {
                    width: calc(100%);
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    .half {
                        width: calc(50% - 10px);
                        border: 1px solid rgba(0, 0, 0, 0.2);
                    }
                }

                &.answer {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    .subtitle {
                        padding: 10px 15px;
                        font-size: 30px !important;
                        margin-right: 30px;
                        min-height: 45px;
                    }

                    .answer-field {
                        width: 300px;
                        margin-left: 5px;

                        input {
                            text-align: end;
                        }
                    }
                }
            }
        }
    }
`


const Calc: FC = () => {
    const [taskId, setTaskId] = useState<number | undefined>(undefined)
    const [data, setData] = useState<any | undefined>(undefined)
    const token = useToken(state => state.token)
    const [subscribe, setSubscribe] = useState<boolean>(false)
    const [showAuth, setShowAuth] = useGlobal(state => [state.showAuth, state.setShowAuth])
    const [serverMessage, setServerMessge] = useState<string>();

    const navigate = useNavigate()

    const { id } = useParams();
    let location = useLocation();

    async function getInfo(type?: string) {
        try {
            let buildUrl = '';
            if (type) {
                buildUrl = `/api/description/${type}`

            }

            const response = await axios(
                token ? {
                    method: "GET",
                    headers: { 'Authorization': `Bearer ${token}`, 'Cache-Control': `no-cache` },
                    url: buildUrl,
                } : {
                    method: "GET",
                    headers: { 'Cache-Control': `no-cache` },
                    url: buildUrl,
                });

            setData(response.data.text);
            setSubscribe(response.data.subscribe)

        } catch (error: any) {
            const { response } = error;
            try {
                if (response.status == 422) {
                    setSubscribe(false)
                    console.log(response.data);
                    setServerMessge(response.data?.danger);
                } else {
                    console.log(response, 'error2');
                    setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                }

            } catch (error: any) {
                setServerMessge("Что-то пошло не так. Попробуйте еще раз");
            }
        }
    }

    useLayoutEffect(() => {
        if (location.pathname === '/calc') {
            getInfo('summary');
        } else if (location.pathname === '/calc/media') {
            getInfo('media');
        } else if (location.pathname === '/calc/research') {
            getInfo('research');
        } else {
            getInfo(id);
        }
    }, [location.pathname, id])

    return (
        <LayoutDefault>
            <Row>
                <Col xs={3} style={{ paddingRight: 0 }}>
                    <SideBarDashboard />
                </Col>
                <Col xs={9} style={{ paddingLeft: 0 }}>
                    <CalcDashbord>
                        {!token ? (
                            <Card>
                                <b>Работа с калькулятором доступна по подписке</b> <br /> <br />
                                <PrimaryButton text="Авторизоваться" onClick={() => setShowAuth(true)} allowDisabledFocus />
                            </Card>
                        ) : serverMessage ? (
                            <Card>
                                <b>{serverMessage}</b> <br /> <br />
                                {!subscribe && (
                                    <PrimaryButton text="" onClick={() => navigate("/lk")} allowDisabledFocus />
                                )}
                            </Card>
                        ) : (
                            <TaskCard>
                                {id === '1' ?
                                    <CalculationOfAudienceCoverageOfOneMediaRequest setTaskID={setTaskId} /> :
                                    id === '2' ?
                                        <MultipleMediaAudience setTaskID={setTaskId} /> :
                                        id === '3' ?
                                            <CalculationOfFrequencyCoverageDistribution setTaskID={setTaskId} /> :
                                            id === '4' ?
                                                <CalculationOfPrivateEffectiveAudienceReachAndEffectiveNumberOfContacts setTaskID={setTaskId} /> :
                                                id === '5' ?
                                                    <CalculationOfTheRatioOfPercentagePointsOfGrowthAndRating setTaskID={setTaskId} /> :
                                                    id === '6' ?
                                                        <SamplesizeCalculation setTaskID={setTaskId} /> :
                                                        id === '7' ?
                                                            <SamplesizeCalculationMin setTaskID={setTaskId} /> :
                                                            id === '8' ?
                                                                <MeasurementErrorCalculation setTaskID={setTaskId} /> :
                                                                id === '9' ?
                                                                    <EffectiveNumberOfPlacementsValues setTaskID={setTaskId} /> :
                                                                    id === '10' ?
                                                                        <DurationOfAdvertisingSilence setTaskID={setTaskId} /> :
                                                                        null}
                            </TaskCard>
                        )}
                        <Card>
                            {location.pathname === '/calc' ? (
                                <CardTitle>
                                    О калькуляторах
                                </CardTitle>
                            ) : location.pathname === '/calc/media' ? (
                                <CardTitle>
                                    О медиа калькуляторах
                                </CardTitle>
                            ) : location.pathname === '/calc/research' ? (
                                <CardTitle>
                                    О калькуляторах для социологических, маркетинговых и рекламных исследований
                                </CardTitle>
                            ) : (
                                <CardTitle>
                                    {data && data.short_name}
                                </CardTitle>
                            )}
                            <CardBody>
                                <div dangerouslySetInnerHTML={data ? { __html: data.description } : undefined} />
                            </CardBody>
                        </Card>
                    </CalcDashbord>
                </Col>
            </Row>
            {taskId && token ? <ModalTask token={token} taskId={taskId} setTaskID={setTaskId} /> : null}
        </LayoutDefault>
    )
}

export default Calc