import { FC, useCallback, useEffect, useState } from "react";
import { useUser } from "../../../store/useUser";
import { useSetState } from "@mantine/hooks";
import moment from "moment";
import axios from "axios";
import { apiUrl } from "../../../constant/routs";
import { isMobile } from "react-device-detect";
import { Alert, Box, Button, Center, Flex, Group, Input, Stack, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

interface Props {
    setCalcId: (val: number) => void
}

interface Values {
    tau: number,
    g: number,
    g_unless: number,
    q: number
}

interface Resp {
    t: number,
    g_min: number,
    g_max: number
}

const DurationOfAdvertisingSilence: FC<Props> = ({
    setCalcId
}) => {
    const [user, token] = useUser(state => [state.user, state.token])
    const [stateVal, setStateVal] = useSetState<Values>({
        tau: 14,
        g: 50,
        g_unless: 90,
        q: 0.64
    });
    const [stateResp, setStateResp] = useState<Resp | null>(null)
    const nav = useNavigate()

    const checkingValues = useCallback(() => {
        if (stateVal.tau <= 0 || stateVal.tau > 1000) return false;
        if (stateVal.g <= 0.1 || stateVal.g > 100) return false;
        if (stateVal.g_unless <= stateVal.g || stateVal.g_unless > 100) return false;
        if (stateVal.q <= 0.01 || stateVal.q > (stateVal.g_unless / stateVal.g)) return false;

        return true;
    }, [stateVal])
    useEffect(() => {
        onSubmitData();
    }, [stateVal.tau, stateVal.g, stateVal.g_unless, stateVal.q, user])

    if (user?.tariff?.mediaClac === 0) {
        return (
            <Center mt={20} mb={isMobile? 120: 20} w="100%" py={40} px={40} bg="#dfe2eb" sx={{
                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                flexDirection: "column",
                borderRadius: 10,
                "span": {
                    fontStyle: "normal"
                }
            }}>
                <Text fw="bold" fz={18} mb={20}>
                    Медиа калькуляторы не доступны в вашем тарифе
                </Text>
                <Button color="teal" size="md" radius="xs" onClick={() => nav("/tariff")}>
                    Сменить тариф
                </Button>
            </Center>
        )
    }

    if (!isMobile) {
        return (
            <Box mt={0} mb={20} w="100%" py={30} px={45} bg="#dfe2eb" sx={{
                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
            }}>
                <Flex mb={20} justify="space-between">
                    <Box fz={18} lh={1.17} fw="bold">
                        Длительность рекламного молчания
                    </Box>
                    <Button color="teal" onClick={() => setCalcId(10)} compact styles={{
                        label: {
                            fontStyle: "normal !important"
                        }
                    }}>
                        Помощь
                    </Button>
                </Flex>
                <Box mb={25} sx={{
                    "i": {
                        fontStyle: "normal",
                        fontWeight: "bold"
                    }
                }}>
                    <Text c="teal" fw={600} fz={17} mb={15}>
                        Введите параметры:
                    </Text>
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Времени жизни рекламы <b>τ</b>
                        </Box>
                        <Input type="number" w={110} defaultValue={stateVal.tau} onChange={e => setStateVal({ tau: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.tau <= 0 || stateVal.tau > 1000) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Время жизни рекламы <i>τ</i> не может быть меньше 1000 и больше 0
                        </Alert>
                    )}
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Охват флайта <i>G</i>, %
                        </Box>
                        <Input rightSection={"%"} type="number" w={110} defaultValue={stateVal.g} onChange={e => setStateVal({ g: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.g <= 0.1 || stateVal.g > 100) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Охват флайта <i>G</i> не может быть меньше 0,1% и больше 100%
                        </Alert>
                    )}
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Предельный охват медиа <i>G<sup>∞</sup>, %</i>
                        </Box>
                        <Input rightSection={"%"} type="number" w={110} defaultValue={stateVal.g_unless} onChange={e => setStateVal({ g_unless: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.g_unless <= stateVal.g || stateVal.g_unless > 100) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Предельный охват медиа <i>G<sup>∞</sup></i> не может быть меньше {stateVal.g}% и больше 100%
                        </Alert>
                    )}
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Параметр эффективности рекламы <i>q</i>
                        </Box>
                        <Input type="number" w={110} defaultValue={stateVal.q} onChange={e => setStateVal({ q: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.q <= 0.01 || stateVal.q > (stateVal.g_unless / stateVal.g)) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            араметр эффективности рекламы <i>q</i> не может быть меньше 0,01 и больше {stateVal.g_unless / stateVal.g}
                        </Alert>
                    )}
                </Box>
                {stateResp && (
                    <Group spacing="xl">
                        <Text fw="bold" size={26}>Результат: </Text>
                        <Text size={26} fw="normal">
                            <i>T</i> = {String(stateResp.t.toFixed(1))}
                        </Text>
                        <Text size={26} fw="normal">
                            <i>G<sub>min</sub></i> = {String(stateResp.g_min.toFixed(1))} %
                        </Text>
                        <Text size={26} fw="normal">
                            <i>G<sub>max</sub></i> = {String(stateResp.g_max.toFixed(1))} %
                        </Text>
                    </Group>
                )}
            </Box>
        )
    } else {
        return (
            <Box mt={0} w="100%" pt={15} pb={120} px={15} bg="#dfe2eb" sx={{
                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                borderRadius: 10
            }}>
                <Flex mb={20}>
                    <Box fz={18} lh={1.17} fw="bold" >
                        Длительность рекламного молчания
                    </Box>
                </Flex>
                <Box mb={20} sx={{
                    "i": {
                        fontStyle: "normal",
                        fontWeight: "bold"
                    }
                }}>
                    <Text c="teal" fw={600} fz={17} mb={15}>
                        Введите параметры:
                    </Text>
                    <Flex direction="column" justify="space-between" bg="#f5f6fa" py={7} px={15} mb={5}>
                        <Box fz={16}>
                            Времени жизни рекламы <b>τ</b>
                        </Box>
                        <Input type="number" w="100%" defaultValue={stateVal.tau} onChange={e => setStateVal({ tau: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.tau <= 0 || stateVal.tau > 1000) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Время жизни рекламы <i>τ</i> не может быть меньше 1000 и больше 0
                        </Alert>
                    )}
                    <Flex direction="column" justify="space-between" bg="#f5f6fa" py={7} px={15} mb={5}>
                        <Box fz={16}>
                            Охват флайта <i>G</i>, %
                        </Box>
                        <Input rightSection={"%"} type="number" w="100%" defaultValue={stateVal.g} onChange={e => setStateVal({ g: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.g <= 0.1 || stateVal.g > 100) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Охват флайта <i>G</i> не может быть меньше 0,1% и больше 100%
                        </Alert>
                    )}
                    <Flex direction="column" justify="space-between" bg="#f5f6fa" py={7} px={15} mb={5}>
                        <Box fz={16}>
                            Предельный охват медиа <i>G<sup>∞</sup>, %</i>
                        </Box>
                        <Input rightSection={"%"} type="number" w="100%" defaultValue={stateVal.g_unless} onChange={e => setStateVal({ g_unless: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.g_unless <= stateVal.g || stateVal.g_unless > 100) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Предельный охват медиа <i>G<sup>∞</sup></i> не может быть меньше {stateVal.g}% и больше 100%
                        </Alert>
                    )}
                    <Flex direction="column" justify="space-between" bg="#f5f6fa" py={7} px={15} mb={5}>
                        <Box fz={16}>
                            Параметр эффективности рекламы <i>q</i>
                        </Box>
                        <Input type="number" w="100%" defaultValue={stateVal.q} onChange={e => setStateVal({ q: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.q <= 0.01 || stateVal.q > (stateVal.g_unless / stateVal.g)) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            араметр эффективности рекламы <i>q</i> не может быть меньше 0,01 и больше {stateVal.g_unless / stateVal.g}
                        </Alert>
                    )}
                </Box>
                {stateResp && (
                    <Stack spacing={0} mb={20}>
                        <Text fw="bold" size={26}>Результат: </Text>
                        <Text size={26} fw="normal">
                            <i>T</i> = {String(stateResp.t.toFixed(1))}
                        </Text>
                        <Text size={26} fw="normal">
                            <i>G<sub>min</sub></i> = {String(stateResp.g_min.toFixed(1))} %
                        </Text>
                        <Text size={26} fw="normal">
                            <i>G<sub>max</sub></i> = {String(stateResp.g_max.toFixed(1))} %
                        </Text>
                    </Stack>
                )}
                <Center>
                    <Button color="teal" onClick={() => setCalcId(10)} styles={{
                        label: {
                            fontStyle: "normal !important"
                        }
                    }}>
                        Помощь
                    </Button>
                </Center>
            </Box>
        )
    }

    async function onSubmitData() {
        if (token && moment(user?.subscribe_to).unix() >= moment().unix() && checkingValues() && user?.tariff?.mediaClac === 1) {
            try {
                const { data } = await axios.post<Resp>(`${apiUrl}api/durationOfAdvertisingSilence`, stateVal, token ? {
                    headers: { "Authorization": `Bearer ${token}` }
                } : undefined)
                setStateResp(data)
            } catch (error) {
                setStateResp(null)
            }
        }
    }
}

export default DurationOfAdvertisingSilence