import React, { FC } from "react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    ContextualMenu,
    Toggle,
    Modal,
    IDragOptions,
    IIconProps,
    Stack,
    IStackProps,
    TextField,
    IStackTokens,
} from '@fluentui/react';
import { DefaultButton, IconButton, IButtonStyles, PrimaryButton } from '@fluentui/react/lib/Button';
import { FormikHelpers, useFormik } from "formik";
import { SecurityLoginModel } from "../../@entities/security";
import axios from "axios";
import { ErrorAlert } from "../../@library/interaction";
import { useGlobal } from "../../@store/useGlobal";

export const AuthModal: FC = () => {
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const [keepInBounds, { toggle: toggleKeepInBounds }] = useBoolean(false);
    const [showAuth, setShowAuth] = useGlobal(state => [state.showAuth, state.setShowAuth])
    const [showReg, setShowReg] = useGlobal(state => [state.showReg, state.setShowReg])
    // Normally the drag options would be in a constant, but here the toggle can modify keepInBounds

    const initialValues: SecurityLoginModel = {
        email: "",
        password: ""
    }


    const dragOptions = React.useMemo(
        (): IDragOptions => ({
            moveMenuItemText: 'Move',
            closeMenuItemText: 'Close',
            menu: ContextualMenu,
            keepInBounds,
            dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
        }),
        [keepInBounds],
    );

    // Use useId() to ensure that the IDs are unique on the page.
    // (It's also okay to use plain strings and manually ensure uniqueness.)
    const titleId = useId('title');

    const formik = useFormik({
        initialValues,
        onSubmit: async (values: SecurityLoginModel, formikHelpers: FormikHelpers<SecurityLoginModel>) => {
            formikHelpers.setSubmitting(true);
            try {
                const response = await axios({
                    method: "POST",
                    url: "/api/auth/login",
                    data: values
                });

                localStorage.setItem('token', response.data.token);
                setShowAuth(false);
                window.location.href = window.location.href;

            } catch (error: any) {
                const { response } = error;
                try {
                    ErrorAlert(response.data);
                } catch (error: any) {
                    ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
                }
            }

            formikHelpers.setSubmitting(false);
        }
    });

    return (
        <Modal
            titleAriaId={titleId}
            isOpen={showAuth}
            onDismiss={() => setShowAuth(false)}
            isBlocking={false}
            containerClassName={contentStyles.container}
            dragOptions={isDraggable ? dragOptions : undefined}
        >
            <div className={contentStyles.header}>
                <span id={titleId}>Авторизация</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => setShowAuth(false)}
                />
            </div>
            <div className={contentStyles.body}>
                <TextField label="Email" required disabled={formik.isSubmitting} value={formik.values.email} onChange={event => formik.setFieldValue("email", event.currentTarget.value, false)} />
                <TextField label="Пароль" required type="password" canRevealPassword revealPasswordAriaLabel="Показать пароль" disabled={formik.isSubmitting} value={formik.values.password} onChange={event => formik.setFieldValue("password", event.currentTarget.value, false)} />
                <Stack horizontalAlign="space-between" horizontal style={{ marginTop: 20 }}>
                    <DefaultButton text="Регистрация" onClick={() => {setShowReg(true); setShowAuth(false)}} allowDisabledFocus />
                    <PrimaryButton text="Вход" allowDisabledFocus onClick={formik.submitForm} disabled={formik.isSubmitting} />
                </Stack>
            </div>
        </Modal>
    );
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const stackProps: Partial<IStackProps> = {
    horizontal: true,
    tokens: { childrenGap: 40 },
    styles: { root: { marginBottom: 20 } },
};
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
