import { FC, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IDoc } from "../../store/types";
import axios from "axios";
import { apiUrl } from "../../constant/routs";
import { useUser } from "../../store/useUser";
import DocDesktop from "./desktop";
import DocMobile from "./mobile";
import { isMobile } from "react-device-detect";

export interface DocBookData {
    banner: string,
    dataAll: IDoc | null
    dataCurrent: IDoc | null
}

const DocBook: FC = () => {
    const [token, user] = useUser(state => [state.token, state.user])
    const params = useParams()
    const nav = useNavigate()

    const [data, setData] = useState<DocBookData>({
        banner: "",
        dataAll: null,
        dataCurrent: null
    })
    useEffect(() => {
        if (token) {
            getData()
        }
    }, [params.url])

    useLayoutEffect(() => {
        if (!token) {
            nav('/')
        }
    }, [token])
    if (!isMobile) {
        return (
            <>
                <DocDesktop data={data} parent={{
                    name: data.dataAll ? data.dataAll.name : "",
                    url: "/doc"
                }} child={{
                    name: data.dataCurrent ? data.dataCurrent.name : "",
                    url: data.dataCurrent ? "book/" + data.dataCurrent.url : ""
                }} pdf={data.dataCurrent ? data.dataCurrent.pdf : ""} />
            </>
        )
    } else {
        return (
            <>
                <DocMobile data={data} parent={{
                    name: data.dataAll ? data.dataAll.name : "",
                    url: "/doc"
                }} child={{
                    name: data.dataCurrent ? data.dataCurrent.name : "",
                    url: data.dataCurrent ? "book/" + data.dataCurrent.url : ""
                }} pdf={data.dataCurrent ? data.dataCurrent.pdf : ""} />
            </>
        )
    }

    async function getData() {
        try {
            const { data } = await axios.get(`${apiUrl}api/home/tasks/doc?v=${Math.random()}`)
            const responseAll = await axios.get(`${apiUrl}api/cat_all_extra?v=${Math.random()}`)
            let dataCurrent = null
            if(user?.tariff?.books === 1) {
                dataCurrent = await axios.get(`${apiUrl}api/get_books_extra/${params.url}`, {
                    headers: { "Authorization": `Bearer ${token}` }
                })
            }
            setData({
                banner: data.banner,
                dataAll: responseAll.data[0],
                dataCurrent: dataCurrent?.data,
            })
        } catch {

        }
    }
}

export default DocBook