import { ComboBox, DefaultButton, IComboBox, IComboBoxOption, MessageBar, MessageBarType, PrimaryButton, TextField } from "@fluentui/react";
import axios from "axios";
import { isNumber, round } from "lodash";
import React from "react";
import { FC, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../@library/interaction";
import { useToken } from "../../@store/useAuth";
import { useUser } from "../../@store/useUser";
import { Card } from "react-bootstrap";

// 9

const EffectiveNumberOfPlacementsValues: FC<{
    setTaskID: (tastId: number | undefined) => void
}> = ({
    setTaskID
}) => {

        const navigate = useNavigate()
        type dataObjectType = {
            f_ef: number,
            g: number | string,
            r: number,
            g_unless: number,
            g_unless_projection: number | null | undefined
        }

        const [serverMessage, setServerMessge] = useState<string>();
        const [subscribe, setSubscribe] = useState<boolean>(true)

        const [token, setToken] = useToken(state => [state.token, state.setToken])
        const [user, setUser] = useUser(state => [state.user, state.setUser])

        const [f_ef, setF_ef] = useState<number>(6)
        const [g, setG] = useState<number | string>('80')
        const [r, setR] = useState<number>(8)
        const [g_unless, setG_unless] = useState<number>(32)
        const [g_unless_server, setG_unless_server] = useState<number>(32)
        const [g_unless_projection, setG_unless_projection] = useState<number | null | undefined>(0)


        const [requstData, setRequstData] = useState<dataObjectType | null>(null);
        const [answer, setAnswer] = useState<number | null>(null)

        function onClickHelp(taskId: number) {
            setTaskID(taskId)
        }

        //проверка всех значений
        function checkingValues() {
            if (f_ef < 1 || f_ef > 1000) return false;
            if (r < 1 || r > 100) return false;
            if (g_unless < 1 || g_unless < r) {
                if (g_unless_projection == 0) return false;
            }
            return true;
        }

        const onChangeG = React.useCallback(
            (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
                const val = option?.key;
                if (val != undefined) setG(val)
            },
            [],
        );

        const onChangeFEf = (e: any) => {
            const value = round(+e.target.value);
            setF_ef(value);
        }

        const onChangeR = (e: any) => {
            const value = +e.target.value;
            setR(+value.toFixed(2));
        }

        const onChangeGUnless = (e: any) => {
            const value = +e.target.value;
            setG_unless(+value.toFixed(2));
        }

        const createOptions = () => {
            const options: IComboBoxOption[] = [];

            options.push({ key: "99.99", text: "99,99%" })
            options.push({ key: "99.5", text: "99,5%" })
            for (let i = 99; i >= 1; i -= 1) {
                options.push({ key: `${i}`, text: Number.isInteger(i) ? `${i},0%` : `${i}%`.replace('.', ',') })
            }
            options.push({ key: "0.5", text: "0,5%" })
            return options
        }

        const options: IComboBoxOption[] = [
            { key: 0, text: "Введи G∞" },
            { key: 1, text: "Радио" },
            { key: 2, text: "ТВ" },
            { key: 3, text: "Пресса" },
            { key: 4, text: "Интернет" },
        ];

        const onChangeGMode = React.useCallback(
            (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
                const val = option?.key;
                if (val != undefined && val != null && isNumber(val)) setG_unless_projection(val)
            },
            [],
        );

        const onSubmitData = async () => {
            if (checkingValues() && user && token) {
                try {

                    const response = await axios({
                        headers: { 'Authorization': `Bearer ${token}` },
                        method: "POST",
                        url: "/api/effectiveNumberOfPlacementsValues",
                        data: requstData
                    });

                    setAnswer(response.data.result);
                    setG_unless_server(response.data.g_unless_server.toFixed(1))
                } catch (error: any) {
                    const { response } = error;
                    try {
                        if (response.status == 422) {
                            console.log(response.data);
                            setServerMessge(response.data?.danger);
                            setSubscribe(false)
                        } else {
                            console.log(response, 'error2');
                            setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                        }

                    } catch (error: any) {
                        setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                    }
                }
            } else {
                console.log(requstData);

            }
        }

        useLayoutEffect(() => {
            checkingValues();

            setRequstData({
                f_ef,
                g,
                r: r,
                g_unless,
                g_unless_projection
            });

        }, [f_ef, g, r, g_unless, g_unless_projection])

        useLayoutEffect(() => {
            if (requstData) onSubmitData();
        }, [requstData])

        if (serverMessage) {
            return (
                <div className="task">
                    <div className="block-wrapper" style={{padding: 30}}>
                        <Card>
                            <b>{serverMessage}</b> <br /> <br />
                            {!subscribe && (
                                <PrimaryButton text="Офромить подписку" onClick={() => navigate("/lk")} allowDisabledFocus />
                            )}
                        </Card>
                    </div>
                </div>
            )
        }

        return (
            <div className="task">
                <div className="block-wrapper">
                    <div className="title">
                        <div className="title-content">
                        Эффективное число размещений
                        </div>
                        <DefaultButton
                            title="Emoji"
                            ariaLabel="Emoji"
                            text={'Помощь'}
                            onClick={() => onClickHelp(9)}
                        />
                    </div>
                    <div className="input-body">
                        <div className="table">
                            <div className="table-row">
                                <div className="name">
                                    Эффективная частота <i>f</i><sub>эф</sub>
                                </div>
                                <div className="value">
                                    <TextField type="number" min={1} max={1000} step={1} value={String(f_ef)} onChange={onChangeFEf} />
                                </div>
                            </div>
                            {(f_ef < 1 || f_ef > 1000) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Эффективная частота <i>f</i><sub>эф</sub> не может быть меньше 1 и больше 1000
                                </MessageBar>
                            ) : null}

                            <div className="table-row">
                                <div className="name">
                                    Эффективный охват <i>G</i>, %
                                </div>
                                <div className="value">
                                    <ComboBox
                                        allowFreeform={false}
                                        autoComplete={'on'}
                                        options={createOptions()}
                                        defaultSelectedKey={'80'}
                                        onChange={onChangeG}
                                    />
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="name">
                                    Рейтинг медиа <i>R</i>, %
                                </div>
                                <div className="value">
                                    <TextField type="number" suffix="%" min={0.1} max={100} step={1} value={String(r)} onChange={onChangeR} />
                                </div>
                            </div>
                            {(r < 1 || r > 100) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Рейтинг медиа <i>R</i>, % не может быть меньше 1% и больше 100%
                                </MessageBar>
                            ) : null}

                            <div className="table-row">
                                <div className="name" style={{ width: 'calc(60% - 20px)' }}>
                                    Предельный охват медиа <i>G<sup>∞</sup>, %</i>
                                </div>

                                <div className="value" style={{ border: 'none' }}>
                                    <ComboBox
                                        allowFreeform={false}
                                        autoComplete={'off'}
                                        options={options}
                                        defaultSelectedKey={0}
                                        onChange={onChangeGMode}
                                    />
                                </div>
                                <div className="value">
                                    <TextField type="number" suffix="%" min={0.1} max={100} disabled={g_unless_projection != 0 ? true : false} step={1} value={g_unless_projection == 0 ? String(g_unless):String(g_unless_server)} onChange={onChangeGUnless} />
                                </div>
                            </div>
                            {((g_unless < 1 || g_unless < r) && g_unless_projection == 0) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Рейтинг медиа <i>R</i>, % не может быть меньше 1% и больше 100%
                                </MessageBar>
                            ) : null}


                        </div>
                    </div>

                    {answer != null && checkingValues() ? (
                        <div className="input-body answer">
                            <div style={{ marginRight: 0 }} className="subtitle">Результат: </div>
                            <div className="subtitle"><i>m</i><sub>эф</sub> = {String(answer.toFixed(1))}</div>
                        </div>
                    ) : null}

                </div>
            </div>
        )
    }

export default EffectiveNumberOfPlacementsValues;