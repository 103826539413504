import { FC } from "react";
import styled from "styled-components";

const SideBarWrapper = styled.div`
    width: 100%;

    .sidebar-items {
        text-decoration: none;
        display: block;
        padding: 10px;
        color: #000;
        width: 100%;

        &:hover {
            background-color: rgba(0, 133, 235, 0.2);
            color: rgb(0, 133, 235);
        }
    }

    .sidebar-task {
        padding: 10px 10px 10px 40px;
        text-decoration: none;
        display: block;
        color: #000;

        &:hover {
            background-color: rgba(0, 133, 235, 0.2);
            color: rgb(0, 133, 235);
        }
    }

    .active {
        background-color: rgba(0, 121, 214, 0.2);
        color: rgb(0, 121, 214);
    }

`


const SideBar: FC<{
    children: React.ReactNode
}> = ({
    children
}) => {
        return (
            <SideBarWrapper>
            {children}
            </SideBarWrapper>
        )
    }

export default SideBar