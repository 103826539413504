import { Box } from "@mantine/core";
import { FC } from "react";
import { apiUrl } from "../../constant/routs";

interface Props {
    banner: string
}

const BunnerMobile: FC<Props> = ({
    banner
}) => {
    return (
        <Box h={180} w="100%" style={{
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: `url(${apiUrl}${banner})`,
        }}>

        </Box>
    )
}

export default BunnerMobile