import axios from "axios";
import { FC, useEffect, useState } from "react";
import { apiUrl } from "../../constant/routs";
import { isMobile } from "react-device-detect";
import TariffDesktop from "./desktop";
import TariffMobile from "./mobile";
import { useLocation } from "react-router-dom";

export interface DataTariff {
    id: number,
    name: string,
    description: string,
    price: number,
    termOfValidity: number
}

const Tariff: FC = () => {
    const [data, setData] = useState<DataTariff[]>([])
    let location = useLocation();
    useEffect(() => {
        if(location.search === "?error=true"){
            alert("Оплата не прошла, попробуйте снова")
        }
        getData()
    }, []);

    if (data) {
        if (!isMobile) {
            return (
                <>
                    <TariffDesktop data={data} />
                </>
            )
        } else {
            return (
                <>
                    <TariffMobile data={data} />
                </>
            )
        }
    }
    return (
        <>

        </>
    )

    async function getData() {
        try {
            const { data } = await axios.get(`${apiUrl}api/personal/getTarif?v=${Math.random()}`)
            setData(data)
        } catch {

        }
    }

}

export default Tariff