import axios from "axios";
import React, { FC, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { TasksType } from "../../../@entities/type";
import { ErrorAlert } from "../../../@library/interaction";
import SideBar from "../../base/sidebar";

const Wrapper = styled.div`
    padding: 0;
`

const Block = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        a{
            text-decoration: none;
        }
    }
`

const SideBarLink = styled(Link)`
    text-decoration: none;
    display: block;
    padding: 10px 10px 10px 40px;
    color: #000;

    &:hover{
        background-color: rgba(0,133,235,.2);
        color: #0085eb;
    }
`
const SideBarLinkHead = styled(SideBarLink)`
    padding-left: 10px
`

const SideBarDashboard: FC = ({ }) => {

    const [calcList, setCalcList] = useState<TasksType[]>([]);


    const onGetData = async () => {
        try {
            const response = await axios({
                method: "GET",
                headers: { 'Cache-Control': `no-cache` },
                url: "/api/home/tasks",
            });

            setCalcList(response.data.tasks);

        } catch (error: any) {
            const { response } = error;
            try {
                console.log(response.data, 'error');

            } catch (error: any) {
                ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
            }
        }
    }

    useLayoutEffect(() => {
        onGetData()
    }, [])


    return (
        <Wrapper>
            <SideBar>
                <SideBarLinkHead to={'/calc'}>
                    О КАЛЬКУЛЯТОРАХ
                </SideBarLinkHead>
                <SideBarLinkHead to={'/calc/media'}>
                    МЕДИА КАЛЬКУЛЯТОРЫ
                </SideBarLinkHead>
                <Block>
                    {calcList.map((item, idx) => {
                        return (
                            item.type == 0 && (
                                <li key={idx}>
                                    <SideBarLink to={`/calc/media/${item.id}`}>
                                        {item.short_name}
                                    </SideBarLink>
                                </li>
                            )
                        )
                    })}
                </Block>
                <SideBarLinkHead to={'/calc/research'}>
                    КАЛЬКУЛЯТОРЫ для социологических, маркетинговых и рекламных исследований
                </SideBarLinkHead>
                <Block>
                {calcList.map((item, idx) => {
                        return (
                            item.type == 1 && (
                                <li key={idx}>
                                    <SideBarLink to={`/calc/media/${item.id}`}>
                                        {item.short_name}
                                    </SideBarLink>
                                </li>
                            )
                        )
                    })}
                </Block>
            </SideBar>
        </Wrapper>
    )
}

export default SideBarDashboard