import {FC} from "react";
import {HomeData} from "./intex";
import {Anchor, Box, Card, Center, Container, Flex, Grid, ScrollArea, Stack, Text, rem} from "@mantine/core";
import HeaderMobile from "../../components/layout/headerMobile";
import Layout from "../../components/layout";
import {apiUrl} from "../../constant/routs";

interface Props {
    data: HomeData
}

const HomeMobile: FC<Props> = ({data}) => {
    return (
        <Layout>
            <Box pos="relative">
                <HeaderMobile />
                <Box w="100%" pos="relative" sx={{
                    backgroundColor: "#007455",
                    height: "100vh",
                    borderRadius: 10,
                    boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                }}>
                    <Box h="100%" w="100%" display="block" pos="absolute" style={{
                        opacity: 0.7,
                        zIndex: 0,
                        top: 0,
                        right: 0,
                        backgroundPosition: "letf",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundImage: `url(${apiUrl}${data.banner})`,
                        borderRadius: 10,
                        boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                    }} />
                </Box>
                <Box pos="absolute" top={110}>
                    <Container>
                        <Flex justify="center" mt={40}>
                            <Box>
                                <Text component="h1" c="#fff" size={rem(50)} fw="bold" lh={0.88} ta="right">
                                    {data.title}
                                </Text>
                                <Text c="#fff" mt={15} size={rem(23)} ta="right">
                                    {data.subtitle}
                                </Text>
                            </Box>
                        </Flex>
                    </Container>
                    <Container size="70rem" pb={80}>
                        <Grid mt={45} align="flex-end" grow gutter="xl">
                            <Grid.Col span={8}>
                                <Card radius={6} shadow="0 4px 10px 0 rgba(0, 0, 0, 0.06)" p={20}>
                                    <Box>
                                        <Text component="h2" fz={25} fw="bold" my={5}>О проекте</Text>
                                        <ScrollArea fz={12} lh={rem(15)}>
                                            <div dangerouslySetInnerHTML={{__html: data.decription}} />
                                        </ScrollArea>
                                    </Box>
                                </Card>
                            </Grid.Col>
                            {data.section.length > 0 && (
                                <Grid.Col span={4}>
                                    <Anchor component="a" href={data.section[0].link}>
                                        <Card radius={6}>
                                            <Card.Section>
                                                <Flex py={20} px={20}
                                                    mih={343}
                                                    justify="flex-end"
                                                    direction="column"
                                                    lh={rem(15)}
                                                    sx={{
                                                        backgroundImage: `url(${apiUrl}${data.section[0].image})`,
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: 'cover'
                                                    }}>
                                                    <Text fw="bold" fz={25} c="#fff" lh="normal" mb={10}>
                                                        {data.section[0].name}
                                                    </Text>
                                                    <Text fz={12} c="#fff" lh="normal">
                                                        {data.section[0].description}
                                                    </Text>
                                                </Flex>
                                            </Card.Section>
                                        </Card>
                                    </Anchor>
                                </Grid.Col>
                            )}
                        </Grid>
                        <Stack mt="xl" mx={0} spacing={25}>
                            {data.section.map((item, idx) => {
                                if (idx !== 0) return (
                                    <Anchor component="a" href={item.link}>
                                        <Card radius={6} key={idx}>
                                            <Card.Section>
                                                <Flex py={20} px={20}
                                                    direction="column"
                                                    style={{
                                                        backgroundColor: `#00916a`,
                                                    }}>
                                                    <Box h={75} w={75} mb={10} sx={{
                                                        backgroundImage: `url(${apiUrl}${item.image})`,
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: 'contain',
                                                        backgroundPosition: 'center'
                                                    }} />
                                                    <Text fw="bold" fz={25} c="#fff" lh="normal" mb={15}>
                                                        {item.name}
                                                    </Text>
                                                    <Text fz={12} lh={rem(15)} c="#fff">
                                                        {item.description}
                                                    </Text>
                                                </Flex>
                                            </Card.Section>
                                        </Card>
                                    </Anchor>
                                )
                            })}
                        </Stack>
                    </Container>
                </Box>
            </Box>
        </Layout>
    )
}

export default HomeMobile