import axios from "axios";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import { apiUrl } from "../../constant/routs";
import { BookCat } from "../../store/types";
import ArticlesAndBooksDesc from "./desktop";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ArticlesAndBooksMobile from "./mobile";
import { useUser } from "../../store/useUser";

export interface BooksData {
    catArr: BookCat[]
    categoryes: BookCat[],
    banner: string,
}

const ArticlesAndBooks: FC = () => {
    const [data, setData] = useState<BooksData>({
        catArr: [],
        categoryes: [],
        banner: ""
    })
    const nav = useNavigate()
    const location = useLocation()
    const { url } = useParams();
    const [currentCat, setCurrntCat] = useState<BookCat>()
    const user = useUser(state => state.user)

    useLayoutEffect(() => {
        if(user?.email !== "intellectsystemlab@gmail.com" && user?.email !== "sga36@mail.ru") {
            nav('/')
        }
    }, [user?.email])

    const setFirstCat = () => {
        if ('/articles-and-books' === location.pathname) {
            data.categoryes.map(item => {
                if (item.pid == 0 && item.id === 10) {
                    nav('/articles-and-books/' + item.url)
                }
            })
        }
    }

    const getCurrentCatList = (arr = data.catArr) => {
        let i = 0;
        arr.map((item, idx) => {
            if ('/articles-and-books/' + item.url == location.pathname) {
                setCurrntCat(item)
                ++i;
            }
            if (i <= 0) {
                setCurrntCat(undefined)
            }
        })
    }

    useEffect(() => {
        getData()
    }, [url])

    useEffect(() => {
        setFirstCat()
        getCurrentCatList()
    }, [url, data.catArr, data.categoryes])


    if (data) {
        if (!isMobile) {
            return (
                <>
                    <ArticlesAndBooksDesc banner={data.banner} parent={{
                        name: "Книги и статьи",
                        url: "/articles-and-books"
                    }} catArr={data.catArr} categoryes={data.categoryes} name={currentCat ? currentCat.name : ""} child={{
                        name: currentCat ? currentCat.name : "",
                        url: currentCat ? currentCat.url : ""
                    }} currentCat={currentCat} />
                </>
            )
        } else {
            return (
                <>
                    <ArticlesAndBooksMobile banner={data.banner} parent={{
                        name: "Книги и статьи",
                        url: "/articles-and-books"
                    }} catArr={data.catArr} categoryes={data.categoryes} name={currentCat ? currentCat.name : ""} child={{
                        name: currentCat ? currentCat.name : "",
                        url: currentCat ? currentCat.url : ""
                    }} currentCat={currentCat} />
                </>
            )
        }
    } else {
        return (
            <>
                что-то пошло не так....
            </>
        )
    }

    async function getData() {
        try {
            const { data } = await axios.get(`${apiUrl}api/home/books?v=${Math.random()}`)
            setData({
                catArr: data.catArr,
                categoryes: data.categoryes,
                banner: data.banner,
            })
        } catch {

        }
    }
}

export default ArticlesAndBooks