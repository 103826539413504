import ReactDOM from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import { isMobile } from 'react-device-detect';
import { ModalsProvider } from '@mantine/modals';
import Routes from './routes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MantineProvider withGlobalStyles withNormalizeCSS theme={{
    fontFamily: `"Montserrat",sans-serif`,
    colorScheme: 'light',
    globalStyles: () => ({
      minWidth: 1440,
      body: {
        minWidth: isMobile ? "auto" : 1440,
        "a": {
          textDecoration: "none!important"
        },
        "input:focus, input:focus-within": {
          outline: "none!important",
          border: "1px solid #ced4da!important"
        }
      },
    })
  }}>
    <ModalsProvider>
      <Routes />
    </ModalsProvider>
  </MantineProvider>
);
