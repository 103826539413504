import { FC, useCallback, useEffect, useState } from "react";
import { useUser } from "../../../store/useUser";
import { useSetState } from "@mantine/hooks";
import moment from "moment";
import axios from "axios";
import { apiUrl } from "../../../constant/routs";
import { isMobile } from "react-device-detect";
import { Alert, Box, Button, Center, Flex, Group, Input, Select, Stack, Text } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

interface Props {
    setCalcId: (val: number) => void
}

interface Values {
    mediaGroupReach: number,
    mediaRating: number,
    numberOfMediaEvents: number,
    g_unless_projection: number,
}

interface Resp {
    result: number,
    g_unless_server: number
}

const CalculationOfTheRatioOfPercentagePointsOfGrowthAndRating: FC<Props> = ({
    setCalcId
}) => {
    const [user, token] = useUser(state => [state.user, state.token])
    const [stateVal, setStateVal] = useSetState<Values>({
        mediaGroupReach: 50,
        mediaRating: 10,
        numberOfMediaEvents: 6,
        g_unless_projection: 0
    });
    const [stateResp, setStateResp] = useState<Resp | null>(null)
    const nav = useNavigate()

    const checkingValues = useCallback(() => {
        if (stateVal.mediaGroupReach <= 0 || stateVal.mediaGroupReach > 100) return false;
        if (stateVal.mediaRating <= 0 || stateVal.mediaRating > stateVal.mediaGroupReach) return false;
        if (stateVal.numberOfMediaEvents <= 0 || stateVal.numberOfMediaEvents > 200) return false;
        return true
    }, [stateVal])

    useEffect(() => {
        onSubmitData();
    }, [stateVal.mediaGroupReach, stateVal.mediaRating, stateVal.numberOfMediaEvents, stateVal.g_unless_projection, user])

    if (user?.tariff?.mediaClac === 0) {
        return (
            <Center mt={20} mb={isMobile? 120: 20} w="100%" py={40} px={40} bg="#dfe2eb" sx={{
                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                flexDirection: "column",
                borderRadius: 10,
                "span": {
                    fontStyle: "normal"
                }
            }}>
                <Text fw="bold" fz={18} mb={20}>
                    Медиа калькуляторы не доступны в вашем тарифе
                </Text>
                <Button color="teal" size="md" radius="xs" onClick={() => nav("/tariff")}>
                    Сменить тариф
                </Button>
            </Center>
        )
    }

    if (!isMobile) {
        return (
            <Box mt={0} mb={20} w="100%" py={30} px={45} bg="#dfe2eb" sx={{
                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
            }}>
                <Flex mb={20}>
                    <Box fz={18} lh={1.17} fw="bold" >
                        Вычисление отношения процентных пунктов{" "} стоимости прироста охвата <span>CPP<sub>ΔG</sub></span> и стоимости рейтинга <span>CPP</span>
                    </Box>
                    <Button color="teal" onClick={() => setCalcId(5)} compact styles={{
                        label: {
                            fontStyle: "normal !important"
                        }
                    }}>
                        Помощь
                    </Button>
                </Flex>
                <Box mb={25} sx={{
                    "i": {
                        fontStyle: "normal",
                        fontWeight: "bold"
                    }
                }}>
                    <Text c="teal" fw={600} fz={17} mb={15}>
                        Введите параметры:
                    </Text>
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Рейтинг медиа <i>R, %</i>
                        </Box>
                        <Input type="number" rightSection={"%"} w={110} defaultValue={stateVal.mediaRating} onChange={e => setStateVal({ mediaRating: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.mediaRating < 0.1 || stateVal.mediaRating > stateVal.mediaGroupReach) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Рейтинг медиа <i>R, %</i> не может быть больше чем Предельный охват группы медиа <i>G<sup>∞</sup>, %</i> ({stateVal.mediaGroupReach}%)
                        </Alert>
                    )}
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Предельный охват медиа <i>G<sup>∞</sup>, %</i>
                        </Box>
                        <Flex>
                            <Select w={150} mr={5} defaultValue="0" onChange={e => setStateVal({ g_unless_projection: Number(e) })} searchable data={[
                                { value: "0", label: "Введи G∞" },
                                { value: "1", label: "Радио" },
                                { value: "2", label: "ТВ" },
                                { value: "3", label: "Пресса" },
                                { value: "4", label: "Интернет" },
                            ]}
                            />
                            <Input
                                rightSection={"%"} w={110}
                                defaultValue={stateVal.g_unless_projection === 0 ? stateVal.mediaGroupReach : stateResp?.g_unless_server.toFixed(1)}
                                value={stateVal.g_unless_projection === 0 ? stateVal.mediaGroupReach : stateResp?.g_unless_server.toFixed(1)}
                                onChange={e => setStateVal({ mediaGroupReach: Number(e.target.value) })} min={0.1} max={100}
                                disabled={stateVal.g_unless_projection != 0 ? true : false} step={1} />
                        </Flex>
                    </Flex>
                    {(stateVal.mediaGroupReach <= 0 || stateVal.mediaGroupReach > 100) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Предельный охват медиа не может быть больше 100% или меньше чем 0,1%
                        </Alert>
                    )}
                    <Flex align="center" justify="space-between" bg="#f5f6fa" py={5} pr={5} pl={17} mb={2}>
                        <Box fz={16}>
                            Число медианосителей <i>m</i>
                        </Box>
                        <Input w={110} type="number" defaultValue={stateVal.numberOfMediaEvents} onChange={e => setStateVal({ numberOfMediaEvents: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.numberOfMediaEvents <= 0 || stateVal.numberOfMediaEvents > 200) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Число медианосителей <i>m</i> не может быть меньше 1 и больше 200
                        </Alert>
                    )}
                </Box>
                {stateResp && (
                    <Group spacing="xl">
                        <Text fw="bold" size={26}>Результат: </Text>
                        <Text size={26} fw="normal">
                            <i>CPP<sub>ΔG</sub> /CPP</i> = {String(stateResp.result.toFixed(1))}
                        </Text>
                    </Group>
                )}
            </Box>
        )
    } else {
        return (
            <Box mt={0} w="100%" pt={15} pb={120} px={15} bg="#dfe2eb" sx={{
                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)",
                borderRadius: 10
            }}>
                <Flex mb={20}>
                    <Box fz={18} lh={1.17} fw="bold" >
                        Вычисление отношения процентных пунктов{" "} стоимости прироста охвата <span>CPP<sub>ΔG</sub></span> и стоимости рейтинга <span>CPP</span>
                    </Box>
                </Flex>
                <Box mb={20} sx={{
                    "i": {
                        fontStyle: "normal",
                        fontWeight: "bold"
                    }
                }}>
                    <Text c="teal" fw={600} fz={17} mb={15}>
                        Введите параметры:
                    </Text>
                    <Flex direction="column" justify="space-between" bg="#f5f6fa" py={7} px={15} mb={5}>
                        <Box fz={16}>
                            Рейтинг медиа <i>R, %</i>
                        </Box>
                        <Input type="number" rightSection={"%"} w="100%" defaultValue={stateVal.mediaRating} onChange={e => setStateVal({ mediaRating: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.mediaRating < 0.1 || stateVal.mediaRating > stateVal.mediaGroupReach) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Рейтинг медиа <i>R, %</i> не может быть больше чем Предельный охват группы медиа <i>G<sup>∞</sup>, %</i> ({stateVal.mediaGroupReach}%)
                        </Alert>
                    )}
                    <Flex direction="column" justify="space-between" bg="#f5f6fa" py={5} px={15} mb={5}>
                        <Box fz={16}>
                            Предельный охват медиа <i>G<sup>∞</sup>, %</i>
                        </Box>
                        <Flex>
                            <Select w="100%" mr={5} defaultValue="0" onChange={e => setStateVal({ g_unless_projection: Number(e) })} searchable data={[
                                { value: "0", label: "Введи G∞" },
                                { value: "1", label: "Радио" },
                                { value: "2", label: "ТВ" },
                                { value: "3", label: "Пресса" },
                                { value: "4", label: "Интернет" },
                            ]}
                            />
                            <Input
                                rightSection={"%"} w={150}
                                value={stateVal.g_unless_projection === 0 ? stateVal.mediaGroupReach : stateResp?.g_unless_server.toFixed(1)}
                                onChange={e => setStateVal({ mediaGroupReach: Number(e.target.value) })} min={0.1} max={100}
                                disabled={stateVal.g_unless_projection != 0 ? true : false} step={1} />
                        </Flex>
                    </Flex>
                    {(stateVal.mediaGroupReach <= 0 || stateVal.mediaGroupReach > 100) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Предельный охват медиа не может быть больше 100% или меньше чем 0,1%
                        </Alert>
                    )}
                    <Flex direction="column" justify="space-between" bg="#f5f6fa" py={7} px={15} mb={5}>
                        <Box fz={16}>
                            Число медианосителей <i>m</i>
                        </Box>
                        <Input w="100%" type="number" defaultValue={stateVal.numberOfMediaEvents} onChange={e => setStateVal({ numberOfMediaEvents: Number(e.target.value) })} />
                    </Flex>
                    {(stateVal.numberOfMediaEvents <= 0 || stateVal.numberOfMediaEvents > 200) && (
                        <Alert icon={<IconAlertCircle size="1rem" />} title="Ошибка!" color="red" radius="xs">
                            Число медианосителей <i>m</i> не может быть меньше 1 и больше 200
                        </Alert>
                    )}
                </Box>
                {stateResp && (
                    <Stack spacing={0} mb={20}>
                        <Text fw="bold" size={26}>Результат: </Text>
                        <Text size={26} fw="normal">
                            <i>CPP<sub>ΔG</sub> /CPP</i> = {String(stateResp.result.toFixed(1))} %
                        </Text>
                    </Stack>
                )}
                <Center>
                    <Button color="teal" onClick={() => setCalcId(5)} styles={{
                        label: {
                            fontStyle: "normal !important"
                        }
                    }}>
                        Помощь
                    </Button>
                </Center>
            </Box>
        )
    }

    async function onSubmitData() {
        if (token && moment(user?.subscribe_to).unix() >= moment().unix() && checkingValues() && user?.tariff?.mediaClac === 1) {
            try {
                const { data } = await axios.post<Resp>(`${apiUrl}api/calculationOfTheRatioOfPercentagePointsOfGrowthAndRating`, stateVal, token ? {
                    headers: { "Authorization": `Bearer ${token}` }
                } : undefined)
                setStateResp(data)
            } catch (error) {
                setStateResp(null)
            }
        }
    }
}

export default CalculationOfTheRatioOfPercentagePointsOfGrowthAndRating