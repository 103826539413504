import { FC } from "react"
import { Menu } from "../../store/types"
import { Breadcrumbs, Text } from "@mantine/core"
import { Link } from "react-router-dom"

interface Props {
    parent: Menu
    child?: Menu
}

const BreadcrumbsMod: FC<Props> = ({
    parent, child
}) => {
    return (
        <>
            <Breadcrumbs fz={12} sx={{
                "a": {
                    textDecoration: "none",
                    color: "#000"
                }
            }}>
                <Link to="/">
                    <Text fz={12}>
                        Главная
                    </Text>
                </Link>
                <Link to={parent.url}>
                    <Text fz={12} c={child ? "#000" : "#00af80"}>
                        {parent.name}
                    </Text>
                </Link>
                {child && (
                    <Link to={parent.url + "/" + child.url}>
                        <Text fz={12} c={"#00af80"}>
                            {child.name}
                        </Text>
                    </Link>
                )}
            </Breadcrumbs>
        </>
    )
}

export default BreadcrumbsMod