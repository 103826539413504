import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, TextField } from "@fluentui/react";
import axios from "axios";
import { FC, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../@library/interaction";
import { useToken } from "../../@store/useAuth";
import { useUser } from "../../@store/useUser";
import { Card } from "react-bootstrap";

// 10

const DurationOfAdvertisingSilence: FC<{
    setTaskID: (tastId: number | undefined) => void
}> = ({
    setTaskID
}) => {
        const navigate = useNavigate()
        type answerType = {
            t: number,
            g_min: number,
            g_max: number
        }

        type dataObjectType = {
            tau: number,
            g: number,
            g_unless: number,
            q: number
        }

        const [serverMessage, setServerMessge] = useState<string>();
        const [subscribe, setSubscribe] = useState<boolean>(true)

        const [token, setToken] = useToken(state => [state.token, state.setToken])
        const [user, setUser] = useUser(state => [state.user, state.setUser])

        const [tau, setTau] = useState<number>(14)
        const [g, setG] = useState<number>(50)
        const [g_unless, setG_unless] = useState<number>(90)
        const [q, setQ] = useState<number>(0.64)

        const [requstData, setRequstData] = useState<dataObjectType | null>(null);
        const [answer, setAnswer] = useState<answerType | null>(null)

        function onClickHelp(taskId: number) {
            setTaskID(taskId)
        }

        function checkingValues() {
            if (tau <= 0 || tau > 1000) return false;
            if (g <= 0.1 || g > 100) return false;
            if (g_unless <= g || g_unless > 100) return false;
            if (q <= 0.01 || q > (g_unless / g)) return false;

            return true;
        }

        const onChangeTau = (e: any) => {
            const value = +e.target.value;
            setTau(value);
        }

        const onChangeG = (e: any) => {
            const value = +e.target.value;
            setG(value);
        }

        const onChangeG_unless = (e: any) => {
            const value = +e.target.value;
            setG_unless(value);
        }

        const onChangeQ = (e: any) => {
            const value = +e.target.value;
            setQ(value);
        }

        const onSubmitData = async () => {

            if (checkingValues() && user && token) {

                try {
                    const response = await axios({
                        headers: { 'Authorization': `Bearer ${token}` },
                        method: "POST",
                        url: "/api/durationOfAdvertisingSilence",
                        data: requstData
                    });

                    setAnswer(response.data);

                } catch (error: any) {
                    const { response } = error;
                    try {
                        if (response.status == 422) {
                            console.log(response.data);
                            setServerMessge(response.data?.danger);
                            setSubscribe(false)
                        } else {
                            console.log(response, 'error2');
                            setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                        }

                    } catch (error: any) {
                        setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                    }
                }
            }
        }

        useLayoutEffect(() => {
            checkingValues();

            setRequstData({
                tau,
                g,
                g_unless,
                q,
            });

        }, [tau, g, g_unless, q])

        useLayoutEffect(() => {
            if (requstData) onSubmitData();
        }, [requstData])

        if (serverMessage) {
            return (
                <div className="task">
                    <div className="block-wrapper" style={{padding: 30}}>
                        <Card>
                            <b>{serverMessage}</b> <br /> <br />
                            {!subscribe && (
                                <PrimaryButton text="Офромить подписку" onClick={() => navigate("/lk")} allowDisabledFocus />
                            )}
                        </Card>
                    </div>
                </div>
            )
        }

        return (
            <div className="task">
                <div className="block-wrapper">
                    <div className="title">
                        <div className="title-content">
                            Длительность рекламного молчания
                        </div>
                        <DefaultButton
                            title="Help"
                            ariaLabel="Help"
                            text={'Помощь'}
                            onClick={() => onClickHelp(10)}
                        />
                    </div>
                    <div className="input-body">
                        <div className="table">

                            <div className="table-row">
                                <div className="name">
                                    Времени жизни рекламы <b>τ</b>
                                </div>
                                <div className="value">
                                    <TextField type="number" min={1} max={1000} step={1} value={String(tau)} onChange={onChangeTau} />
                                </div>
                            </div>
                            {(tau <= 0 || tau > 1000) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Время жизни рекламы <span>τ</span> не может быть меньше 1000 и больше 0
                                </MessageBar>
                            ) : null}

                            <div className="table-row">
                                <div className="name">
                                    Охват флайта <i>G</i>, %
                                </div>
                                <div className="value">
                                    <TextField suffix="%" type="number" min={0.1} max={100} step={0.1} value={String(g)} onChange={onChangeG} />
                                </div>
                            </div>
                            {(g <= 0.1 || g > 100) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Охват флайта <i>G</i> не может быть меньше 0,1% и больше 100%
                                </MessageBar>
                            ) : null}

                            <div className="table-row">
                                <div className="name">
                                    Предельный охват медиа <i>G<sup>∞</sup>, %</i>
                                </div>
                                <div className="value">
                                    <TextField suffix="%" type="number" min={0.1} max={100} step={0.1} value={String(g_unless)} onChange={onChangeG_unless} />
                                </div>
                            </div>
                            {(g_unless <= g || g_unless > 100) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Предельный охват медиа <i>G<sup>∞</sup></i> не может быть меньше {g}% и больше 100%
                                </MessageBar>
                            ) : null}

                            <div className="table-row">
                                <div className="name">
                                    Параметр эффективности рекламы <i>q</i>
                                </div>
                                <div className="value">
                                    <TextField type="number" min={0.1} max={100} step={0.1} value={String(q)} onChange={onChangeQ} />
                                </div>
                            </div>
                            {(q <= 0.01 || q > (g_unless / g)) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Параметр эффективности рекламы <i>q</i> не может быть меньше 0,01 и больше {g_unless / g}
                                </MessageBar>
                            ) : null}

                        </div>
                    </div>

                    {answer != null && checkingValues() ? (
                        <div className="input-body answer">
                            <div style={{ marginRight: 0 }} className="subtitle">Результат: </div>
                            {/* <div className="subtitle"><i>T</i> = {String(round(answer.t))} дней</div> */}
                            <div className="subtitle"><i>T</i> = {String(answer.t.toFixed(1))} дней</div>
                            <div className="subtitle"><i>G<sub>min</sub></i> = {String(answer.g_min.toFixed(1))} %</div>
                            <div className="subtitle"><i>G<sub>max</sub></i> = {String(answer.g_max.toFixed(1))} %</div> 
                        </div>
                    ) : null}

                </div>
            </div>
        )
    }

export default DurationOfAdvertisingSilence;