import axios from "axios";
import create from "zustand";
import { ErrorAlert } from "../@library/interaction";

interface GlobalStore {
    showAuth: boolean;
    showReg: boolean;
    setShowReg: (showReg: boolean) => void;
    setShowAuth: (showAuth: boolean) => void;
}


export const useGlobal = create<GlobalStore>((set, get) => ({
    showAuth: false,
    showReg: false,
    setShowReg: showReg => set({ showReg }),
    setShowAuth: showAuth => set({ showAuth }),
}))