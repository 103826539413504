import axios from "axios";
import { FC, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { ErrorAlert } from "../../@library/interaction";

const BannerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: relative;
    left: 0;

    width: 100vw;
    height: calc(100vw / 5);
    background-color: rgba(0, 0, 0, 0.3);

    .background {
        z-index: -1;
        display: flex;
        width: 100%;
        height: 100%;
        background-image: url();
        position: absolute;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }


    h1 {
        font-size: 75px;
        color: #fff;
        margin: 0;
    }

    h2 {
        font-size: 25px;
        color: #fff;
    }
`

const Banner: FC<{
    subtitle?: string | undefined | null
    title: string | undefined | null
    backgound: string
    children: React.ReactNode
}> = ({
    title, backgound, subtitle, children
}) => {

        const [img, setImg] = useState<string>('')

        const getBunner = async () => {
            try {
                const response = await axios({
                    method: "GET",
                    // headers: { 'Cache-Control': `no-cache` },
                    url: "/api/home/tasks",
                });

                setImg(response.data.banner);
            } catch (error: any) {
                const { response } = error;
                try {
                    console.log(response.data, 'error');

                } catch (error: any) {
                    ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
                }
            }

        }

        useLayoutEffect(() => {
            getBunner();
        }, [])

        return (
            <BannerWrapper>
                <div className="background" style={{ backgroundImage: `url(${img})` }}></div>
                <h1>{title}</h1>
                <h2>{subtitle}</h2>
                {children}
            </BannerWrapper>
        )
    }

export default Banner