import { FC } from "react";
import styled from "styled-components";

const CardWrapper = styled.div`
    background: #fff;
    padding: 30px;
    margin: 0 10px 30px 10px;
    border-radius: 2px;
    background-clip: padding-box;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
    position: relative;
`

const Card: FC<{
    children: React.ReactNode
}> = ({
    children
}) => {
        return (
            <CardWrapper>
                {children}
            </CardWrapper>
        )
    }

export default Card