import React, { FC } from "react";
import styled from "styled-components";
import SideBar from "../base/sidebar";
import SidebarUL from "../base/sidebarTab";

const Wrapper = styled.div`
    padding: 0;
    height: calc(100% - 70px);
`
const SideBarBooks: FC<{
    url: string | undefined
    links: any[]
    section: string
}> = ({
    url, links, section
}) => {



    return (
        <Wrapper>
            <SideBar>
                {links && <SidebarUL section={section} url={url} items={links} />}
            </SideBar>
        </Wrapper>
    )
}

export default SideBarBooks