import { FC } from "react";
import styled from "styled-components";

const AboutWrapper = styled.div`

    &::after {
        display: block;
        height: 5px;
        width: 300px;
        content: "";

        position: relative;
        background-color: rgb(0, 120, 212);
        top: 0;
        bottom: 0;
        z-index: 2;
        margin: 0 auto;
    }
`

const Title = styled.div`
    margin: 20px 0;
    line-height: 1.5;
    font-size: 40px;
    font-weight: bold;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
`

const Subtitle = styled.div`
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.3;
    font-size: 20px;
`

const About: FC<{
    subtitle?: string
    title: string
    children?: React.ReactNode
}> = ({
    title, subtitle, children
}) => {
        return (
            <AboutWrapper>
                <Title >{title}</Title>
                <Subtitle dangerouslySetInnerHTML={subtitle ? { __html: subtitle } : undefined}/>
                {children}
            </AboutWrapper>
        )
    }

export default About