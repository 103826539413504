import { FC } from "react";
import { DataPage } from ".";
import Layout from "../../components/layout";
import { Box, Container, Text } from "@mantine/core";
import BunnerDeskt from "../../components/desktop/bunner";
import BreadcrumbsMod from "../../components/desktop/breadcrumpsMod";
import FooterDesktop from "../../components/layout/footer";

const PageDesktop: FC<{ data: DataPage }> = ({ data }) => {
    return (
        <Layout>
            <Box pos="relative">
                <BunnerDeskt banner={data.banner} />
                <Container size="80rem" pt={30}>
                    <BreadcrumbsMod parent={{
                        name: data.post.name,
                        url: data.post.url
                    }} child={undefined} />
                </Container>
                <Container size="80rem" pt={40} pb={70}>
                    <Text component="h1" fz={30} fw="bold" mb={30} mt={0}>
                        {data.post.name}
                    </Text>
                    {data.post.description && (
                        <>
                            <Box w="100%" p={40} bg="#fff" sx={{
                                boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)"
                            }}>
                                <Box dangerouslySetInnerHTML={{
                                    __html: data.post.description
                                }} />
                            </Box>
                        </>
                    )}
                </Container>
                <FooterDesktop />
            </Box>
        </Layout>
    )
}

export default PageDesktop