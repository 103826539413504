import axios from "axios";
import { FC, useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from 'react-pdf';
import { ActionIcon, Box, Center, Flex, Loader, Select, SelectItem, Text } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const createOptions = (numPages: number) => {
    const options: SelectItem[] = [];
    for (let i = 1; i <= numPages; i += 1) {
        options.push({ value: `${i}`, label: i + '' })
    }
    return options
}


interface Props {
    pdf: string
}

const PDF: FC<Props> = ({ pdf }) => {
    const [file, setFile] = useState<string | ArrayBuffer | null>()
    const [download, setDownload] = useState<boolean>(false)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [numPages, setNumPages] = useState<number>(0);

    useEffect(() => {
        getData()
    }, [pdf])

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    }

    if (download) {
        return (
            <Box>
                <Box sx={{
                    ".react-pdf__Page__svg": {
                        height: "100%!important",
                        width: "100%!important"
                    },
                    ".react-pdf__Page__svg > svg": {
                        height: "100%!important",
                        width: "100%!important"
                    },
                    boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.06)"
                }}>
                    <Document renderMode={'svg'} file={file} onLoadSuccess={onDocumentLoadSuccess} loading={
                        <Center>
                            <Loader variant="dots" color="teal" size="xl" />
                        </Center>
                    } onLoadError={error => {
                        console.log(error)
                        setDownload(true)
                    }}>
                        <Page className={'pdf'} pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
                    </Document>
                </Box>
                <Flex w="100%" align="center" justify="center" mt={40
                }>
                    <ActionIcon size="xl" onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber === 1}>
                        <IconChevronLeft color="#00af80" />
                    </ActionIcon>
                    <Flex align="center" justify="center">
                        <Text>
                            Страница
                        </Text>
                        <Select
                            mx={10}
                            defaultValue={`${pageNumber}`}
                            value={`${pageNumber}`}
                            data={createOptions(numPages)}
                            onChange={val => {
                                if(val) setPageNumber(+val)
                            }}
                        />
                        <Text>
                            из {' '} {numPages}
                        </Text>
                    </Flex>
                    <ActionIcon size="xl"  onClick={() => setPageNumber(pageNumber + 1)} disabled={numPages === pageNumber}>
                        <IconChevronRight color="#00af80" />
                    </ActionIcon>
                </Flex>
            </Box>
        )
    }

    return (
        <Center>
            <Loader variant="dots" color="teal" size="xl" />
        </Center>
    )

    async function getData() {
        setDownload(false)
        try {
            const { data } = await axios({
                method: "GET",
                url: `${pdf}?v=${Math.random()}`,
                // url: `/proxy/${pdf}`,
                responseType: 'blob',
                onDownloadProgress(progressEvent) {
                    console.log(progressEvent);
                },
            });

            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function () {
                const base64data = reader.result;
                setFile(base64data);
                setPageNumber(1)
            }
        } catch {

        }
        setDownload(true)
    }
}

export default PDF