import { ComboBox, DefaultButton, IComboBox, IComboBoxOption, MessageBar, MessageBarType, PrimaryButton, TextField } from "@fluentui/react";
import axios from "axios";
import React from "react";
import { FC, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorAlert } from "../../@library/interaction";
import { useToken } from "../../@store/useAuth";
import { useUser } from "../../@store/useUser";
import { Card } from "react-bootstrap";

const MeasurementErrorCalculation: FC<{
    setTaskID: (tastId: number | undefined) => void
}> = ({
    setTaskID
}) => {
        const navigate = useNavigate()
        type dataObjectType = {
            gamma: number | string,
            n: number,
            r: number,
            nLittle: number,
        }

        type Answer = {
            delta: number,
            rMin: number
        }

        const [serverMessage, setServerMessge] = useState<string>();
        const [subscribe, setSubscribe] = useState<boolean>(true)

        const [token, setToken] = useToken(state => [state.token, state.setToken])
        const [user, setUser] = useUser(state => [state.user, state.setUser])

        const [gamma, setGamma] = useState<number | string>('95.0')
        const [n, setN] = useState<number>(1000000)
        const [r, setR] = useState<number>(50)
        const [nLittle, setNLittle] = useState<number>(500)


        const [requstData, setRequstData] = useState<dataObjectType | null>(null);
        const [answer, setAnswer] = useState<Answer | null>(null)

        function onClickHelp(taskId: number) {
            setTaskID(taskId)
            console.log('Task', taskId);
        }

        function checkingValues() {
            if (n <= 0 || n > 1000000000) return false
            if (nLittle <= 1 || nLittle > n) return false
            if (r < 0.1 || r > 100) return false
            return true;
        }

        const onChangeGamma = React.useCallback(
            (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
                const val = option?.key;
                if (val != undefined) setGamma(val)
            },
            [],
        );

        const onChangeR = (e: any) => {
            const value = +e.target.value;
            setR(+value.toFixed(1))
        }

        const onChangeN = (e: any) => {
            const value = +e.target.value;
            setN(+value.toFixed(1))
        }

        const onChangeNLittle = (e: any) => {
            const value = +e.target.value;
            setNLittle(+value.toFixed(1))
        }

        const createOptions = () => {
            const options: IComboBoxOption[] = [];
            for (let i = 99.5; i > 0; i -= 0.5) {
                options.push({ key: Number.isInteger(i) ? `${i}.0` : `${i}`, text: Number.isInteger(i) ? `${i},0` : `${i}`.replace('.', ',') })
            }
            return options
        }

        const onSubmitData = async () => {
            if (checkingValues() && user && token) {
                try {
                    const token = localStorage.getItem('token')
                    if (!token) {
                        navigate('/authorization')
                        return null
                    }
                    const response = await axios({
                        headers: { 'Authorization': `Bearer ${token}` },
                        method: "POST",
                        url: "/api/measurementErrorCalculation",
                        data: requstData
                    });

                    setAnswer(response.data);

                } catch (error: any) {
                    const { response } = error;
                    try {
                        if (response.status == 422) {
                            console.log(response.data);
                            setServerMessge(response.data?.danger);
                            setSubscribe(false)
                        } else {
                            console.log(response, 'error2');
                            setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                        }

                    } catch (error: any) {
                        setServerMessge("Что-то пошло не так. Попробуйте еще раз");
                    }
                }
            }
        }

        useLayoutEffect(() => {
            checkingValues();

            setRequstData({
                gamma: gamma,
                n,
                r: r / 100,
                nLittle,
            });

        }, [gamma, n, nLittle, r])

        useLayoutEffect(() => {
            if (requstData) onSubmitData();
        }, [requstData])

        if (serverMessage) {
            return (
                <div className="task">
                    <div className="block-wrapper" style={{padding: 30}}>
                        <Card>
                            <b>{serverMessage}</b> <br /> <br />
                            {!subscribe && (
                                <PrimaryButton text="Офромить подписку" onClick={() => navigate("/lk")} allowDisabledFocus />
                            )}
                        </Card>
                    </div>
                </div>
            )
        }


        return (
            <div className="task">
                <div className="block-wrapper">
                    <div className="title">
                        <div className="title-content">
                            Ошибка измерения <span style={{ fontStyle: "normal" }}>Δ</span><br /> и минимально значимая доля признака <span>R<sub>min</sub></span> <br /> в зависимости от <span>n,</span> <span>N,</span> <span>γ</span> и <span>R</span>
                        </div>
                        <DefaultButton
                            title="Help"
                            ariaLabel="Help"
                            text={'Помощь'}
                            onClick={() => onClickHelp(8)}
                        />
                    </div>
                    <div className="input-body">
                        <div className="table">
                            <div className="table-row">
                                <div className="name">
                                    Объем выборки <i>n</i>
                                </div>
                                <div className="value">
                                    <TextField type="number" min={1} step={1} onChange={onChangeNLittle} value={String(nLittle)} />
                                </div>
                            </div>
                            {(nLittle <= 1 || nLittle > n) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    n не мождет быть меньше 1 и больше N
                                </MessageBar>
                            ) : null}
                            <div className="table-row">
                                <div className="name">
                                    Объем генеральной совокупности <i>N</i>
                                </div>
                                <div className="value">
                                    <TextField type="number" min={0.1} max={100} step={0.1} value={String(n)} onChange={onChangeN} />
                                </div>
                            </div>
                            {(n <= 0 || n > 1000000000) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    Предельный охват группы медиа не может быть больше 100% или меньше чем 0,1%
                                </MessageBar>
                            ) : null}
                            <div className="table-row">
                                <div className="name">
                                    Уровень надежности измерений <strong>γ, </strong> <i>%</i>
                                </div>
                                <div className="value">
                                    <ComboBox
                                        allowFreeform={false}
                                        autoComplete={'on'}
                                        options={createOptions()}
                                        defaultSelectedKey={'95.0'}
                                        onChange={onChangeGamma}
                                    />
                                </div>
                            </div>
                            <div className="table-row">
                                <div className="name">
                                    Прогнозируемая доля признака <i>R, %</i>
                                </div>
                                <div className="value">
                                    <TextField suffix="%" type="number" min={0.1} max={100} step={0.1} value={String(r)} onChange={onChangeR} />
                                </div>
                            </div>
                            {(r <= 0 || r > 100) ? (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.error}
                                >
                                    R не модет быть больше 100% или меньше чем 0,1%
                                </MessageBar>
                            ) : null}
                        </div>
                    </div>

                    {answer != null && checkingValues() ? (
                        <div className="input-body answer">
                            <div style={{ marginRight: 0 }} className="subtitle">Результат: </div>
                            <div className="subtitle">Δ = {String(answer.delta.toFixed(1))}%</div>
                            <div className="subtitle"><i>R<sub>min</sub></i> = {String(answer.rMin.toFixed(1))}%</div>
                        </div>
                    ) : null}

                </div>
            </div>
        )
    }

export default MeasurementErrorCalculation;