import axios from "axios";
import { FC, useLayoutEffect, useState } from "react";
import { Container, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { NewsType } from "../@entities/type";
import { ErrorAlert } from "../@library/interaction";
import About from "../components/base/about";
import Banner from "../components/base/banner";
import Card from "../components/base/card";
import LayoutDefault from "../components/layouts/layout";

const NewsContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;

    .news-image {
        height: 350px;
        width: 40%;

        background-image: url(../imgs/home.webp);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 20px;
    }

    .news-text {
        width: calc(60% - 40px);
        font-size: 20px;
        line-height: 1.5;

        .news-title {
            line-height: 1.5;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;

            display: flex;
            align-items: center;
        }

        .news-more {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }

        .news-date {
            position: absolute;
            right: 40px;
            bottom: 40px;
        }
    }
`

const Partneer: FC<{

}> = ({

}) => {

        const [partneer, setPartneer] = useState<NewsType[]>([]);
        const [title, setTitle] = useState<string | null>();
        const [subTitle, setSubtitle] = useState<string | null>();
        const [bunner, setBunner] = useState<string>('');
        const [description, setDescription] = useState<string | null>();

        const onGetData = async () => {
            try {
                const response = await axios({
                    method: "GET",
                    url: "/api/partneer",
                });

                setPartneer(response.data.partneer);
                setTitle(response.data.title);
                setSubtitle(response.data.subtitle);
                setBunner(response.data.banner);
                setDescription(response.data.decription);


            } catch (error: any) {
                const { response } = error;
                try {
                    console.log(response.data);

                } catch (error: any) {
                    ErrorAlert("Что-то пошло не так. Попробуйте еще раз");
                }
            }
        }

        const dateNews = (date: string) => {
            const dateFormat = new Date(Date.parse(date))
            return dateFormat.getDate() + '.' + dateFormat.getMonth() + '.' + dateFormat.getFullYear()
        }

        useLayoutEffect(() => {
            onGetData();
        }, [])

        return (
            <LayoutDefault>
                <Banner title={title} subtitle={subTitle} backgound={bunner}>
                </Banner>
                <Container>
                    <About title={'Наши партнеры'} subtitle={''} />
                    <div>
                        <Row direction="column" style={{ paddingTop: 30 }}>
                            {partneer.map((item, idx) => {
                                return (
                                    <Card >
                                        <NewsContainer>
                                            <div className="news-image" style={{ backgroundImage: `url(${item.image})` }}></div>
                                            <div className="news-text">
                                                <div className="news-title">{item.name}</div>
                                                <div className="news-about" dangerouslySetInnerHTML={item.description ? { __html: item.description } : undefined}></div>
                                            </div>
                                        </NewsContainer>
                                    </Card>
                                )
                            })}
                        </Row>
                    </div>
                </Container>
            </LayoutDefault>
        )
    }

export default Partneer